import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Travel } from "../models/travel";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { catchError, Observable, of, Subscription } from "rxjs";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ETouristCodebookEntry } from "src/app/shared/models/etourist";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { ETouristStateService } from "../../sales/services/etourist-state.service";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { typeOrTransportCodeRequired } from "src/app/shared/validators/field.validator";
import { dateFormatValidator, endDateAfterStartDateValidator } from "src/app/shared/validators/date.validator";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";

@Component({
  selector: "app-travel-create",
  templateUrl: "./travel.create.component.html",
  animations: [toggleAnimation],
})
export class TravelCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  public id: number | undefined;
  travel: Travel | undefined;

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  protected transportSubscriber$!: Observable<ETouristCodebookEntry[]>;

  public form!: FormGroup;
  public isSubmitForm = false;

  public travelTypeSubscription: Subscription | undefined;
  @Input() showAvans = false;
  @Output() notify: EventEmitter<{ start: string; end: string; days: number; nights: number }> = new EventEmitter();
  @ViewChild("startInput") startInput!: ElementRef;

  constructor(
    public store: Store<AppState>,
    public translate: TranslateService,
    public fb: FormBuilder,
    public utilService: UtilService,
    public eTuristService: ETouristService,
    private etouristStateService: ETouristStateService
  ) {}
  ngOnInit(): void {
    this.initForm({} as Travel);

    this.etouristStateService.checked$.subscribe((status) => {
      if (status) {
        this.transportSubscriber$ = this.eTuristService.getTransport().pipe(
          catchError((error) => {
            showMessage(PopupType.Danger, error.error);
            return of([]);
          })
        );

        this.transportSubscriber$.subscribe((transportList) => {
          const selectedTransport = transportList?.find((t) => t.code === this.travel?.eTuristTransportCode) || null;

          if (this.form && selectedTransport) {
            this.form.patchValue({
              eTuristTransportCode: selectedTransport?.code,
            });
          }
        });
      }
    });
  }

  initForm(travel: Travel) {
    const { id = this.id, start, end, typeId = 5, type, days, nights, organizer, departure, plan, eTuristTransportCode, eTuristTransportLabel } = travel || ({} as Travel);
    const { avans } = plan || ({} as Travel);
    this.id = id;
    this.travel = travel;
    const country = departure?.country || null;

    this.form = this.fb.group(
      {
        start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        type: [type, { validators: [], updateOn: "blur" }],
        days: [days, { validators: [Validators.required], updateOn: "blur" }],
        nights: [nights, { validators: [Validators.required], updateOn: "blur" }],
        organizer: [organizer, Validators.required],
        departure: [departure],
        country: [country],
        avans: ["", this.showAvans ? Validators.required : []],
        eTuristTransportCode: [eTuristTransportCode, { updateOn: "blur" }],
        eTuristTransportLabel: [eTuristTransportLabel],
      },
      {
        validators: [endDateAfterStartDateValidator, typeOrTransportCodeRequired()],
      }
    );

    this.travelTypeSubscription = this.form.get("type")?.valueChanges.subscribe((value) => {
      const departControl = this.form.get("depart");
      if (departControl) {
        if (value.id !== 7) {
          departControl.clearValidators();
        } else {
          departControl.setValidators([Validators.required]);
        }
        departControl.updateValueAndValidity();
      }
    });
  }
  onTransportChange(event: ETouristCodebookEntry) {
    this.form.patchValue({
      eTuristTransportLabel: event.name,
    });
  }

  focusFirstField() {
    setTimeout(() => {
      this.startInput.nativeElement.focus();
    });
  }

  setDaysNights() {
    const startValue = this.form.controls["start"].value;
    if (startValue && !this.form.controls["start"].errors) {
      const formattedStart = moment(startValue.replace(/(\d{2})(\d{2})(\d{4})/, "$1.$2.$3"), "DD.MM.YYYY", true);
      this.form.controls["end"].setValue(formattedStart.add(this.form.controls["days"].value - 1, "days").format("DD.MM.YYYY"));
    }
  }

  submitForm() {
    this.isSubmitForm = true;
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      for (const controlName in this.form.controls) {
        const control = this.form.get(controlName);
        if (control?.invalid) {
          console.log(`Control ${controlName} is invalid`);
          console.log(`Control ${control.value} is invalid`);
        }
      }
    }
    return this.form.valid;
  }

  getTravel(): Travel {
    return { ...this.form.getRawValue(), id: this.id };
  }

  ngOnDestroy() {
    if (this.travelTypeSubscription) {
      this.travelTypeSubscription.unsubscribe();
    }
  }

  onEndBlur() {
    this.notify.emit({ start: this.form.controls["start"].value, end: this.form.controls["end"].value, days: this.form.controls["days"].value, nights: this.form.controls["nights"].value });
  }

  ngAfterViewInit() {
    this.startInput.nativeElement.focus();
  }
}
