import { TranslateService } from "@ngx-translate/core";
import { CustomFile, Office } from "../models/office-branch";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OfficeBranchService } from "../services/office-branch.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";

@Component({
  selector: "app-office-branch-form",
  templateUrl: "./office-branch.form.component.html",
  styleUrls: ["./office-branch.form.component.css"],
})
export class OfficeBranchFormComponent implements OnInit {
  @Output() submissionDone: EventEmitter<null> = new EventEmitter();

  public form!: FormGroup;
  protected existingLogo!: CustomFile | undefined;
  public imageUpload!: FileUploadWithPreview;
  private office!: Office;
  options = ["admin", "seller"];
  @ViewChild("emailConfigModal") emailConfigModal!: ModalComponent;
  isLoading = false;

  @Input() id!: number | undefined;
  constructor(public fb: FormBuilder, public officeBranchService: OfficeBranchService, public translate: TranslateService) {}

  ngOnInit(): void {
    if (this.id !== undefined) {
      this.officeBranchService.getOfficeById(this.id).subscribe((office) => {
        this.initForm(office);
      });
    } else {
      this.initForm({} as Office);
    }
  }

  removeImage(e: Event) {
    e.preventDefault();
    this.existingLogo = undefined;

    setTimeout(() => {
      this.initializeFileUploader();
    });
  }

  uploadClick(e: Event) {
    e.stopPropagation();
    const clickedElement = e.target as HTMLElement;
    if (clickedElement.className === "image-preview") {
      this.imageUpload.emulateInputSelection();
    }
  }

  initForm(office: Office): void {
    this.office = office;
    const { name, email, guid, phoneNumber, logoImage, fullAddress, siteURL: siteUrl, bankAccount, pib, senderEmail, senderName, adminEmail, password } = office || ({} as Office);
    this.existingLogo = logoImage;
    this.form = this.fb.group({
      name: [name, Validators.required],
      guid: [guid, Validators.required],
      bankAccount: [bankAccount, Validators.required],
      PIB: [pib, Validators.required],
      phoneNumber: [phoneNumber, Validators.required],
      email: [email, [Validators.email, Validators.required]],
      siteUrl: [siteUrl, Validators.required],
      senderEmail: [senderEmail, [Validators.email, Validators.required]],
      senderName: [senderName, Validators.required],
      adminEmail: [adminEmail, [Validators.email, Validators.required]],
      password: [password, Validators.required],
      fullAddress: [fullAddress, Validators.required],
    });

    if (!logoImage) {
      setTimeout(() => {
        this.initializeFileUploader();
      });
    }
  }

  initializeFileUploader() {
    this.imageUpload = new FileUploadWithPreview("office-branch-logo", {
      maxFileCount: 1,
      presetFiles: [],

      images: {
        baseImage: "/assets/images/file-preview.svg",
      },
      accept: "image/*",
      text: {
        browse: this.translate.instant("click_to_upload_logo"),
        chooseFile: this.translate.instant("click_to_choose_logo"),
        label: this.translate.instant("click_to_upload_your_logo"),
      },
      showDeleteButtonOnImages: true,
    });
  }

  getImageUrl(url: string): string[] {
    return url.includes("http://") ? [url] : ["http://" + url];
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    if (this.id) {
      this.fetchOffice();
      const updateSubscription = this.officeBranchService.updateOffice(this.id, this.fetchOffice()).subscribe({
        next: (response) => {
          this.isLoading = false;
          updateSubscription.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));

          if (this.imageUpload && this.imageUpload.cachedFileArray.length > 0) {
            const uploadImgSubscription = this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe({
              next: () => {
                uploadImgSubscription.unsubscribe();
                showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
                this.initForm({} as Office);
                this.submissionDone.emit(null);
              },
              error: () => {
                uploadImgSubscription.unsubscribe();
                showMessage(PopupType.Danger, this.translate.instant("popup.office_branch_image_updated_successfully"));
              },
            });
          } else {
            this.initForm({} as Office);
            this.submissionDone.emit(null);
          }
        },
        error: (error) => {
          this.isLoading = false;
          updateSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.error.toString());
        },
      });
    } else {
      const createSubscription = this.officeBranchService.createOffice(this.fetchOffice()).subscribe({
        next: (response) => {
          this.isLoading = false;
          createSubscription.unsubscribe();
          if (this.imageUpload.cachedFileArray.length > 0) {
            this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe(() => {
              showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
            });
          }
          this.initForm({} as Office);
          this.submissionDone.emit(null);
        },
        error: (error) => {
          this.isLoading = false;
          createSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.error.toString());
        },
      });
    }
  }

  fetchOffice(): Office {
    const { name, email, guid, phoneNumber, logoURL, bankAccount, PIB, siteUrl, senderEmail, senderName, adminEmail, password, fullAddress } = this.form.getRawValue();

    return {
      name,
      email,
      guid,
      phoneNumber,
      logoURL,
      bankAccount,
      pib: PIB,
      siteURL: siteUrl,
      senderEmail,
      senderName,
      adminEmail,
      password,
      fullAddress,
      cityId: this.office.cityId,
      municipalityId: this.office.municipalityId,
      streetId: this.office.streetId,
      city: this.office.city,
      municipality: this.office.municipality,
      street: this.office.street,
    } as Office;
  }

  openEmailModal(): void {
    this.emailConfigModal.open();
  }

  closeEmailModal() {
    this.emailConfigModal.close();
  }
}
