import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DocumentNavigationService {
  constructor(private router: Router) {}

  navigateToCreateVoucherPage(entityId: number, memorandumId?: string): void {
    if (memorandumId) {
      window.open(`https://docs.google.com/document/d/${memorandumId}`, "_blank");
    } else {
      this.router.navigate([`/dashboard/travels/${entityId}/voucher-list`]);
    }
  }
  navigateToContractPage(entityId: number, contactDriveId?: string): void {
    if (contactDriveId) {
      window.open(`https://docs.google.com/document/d/${contactDriveId}`, "_blank");
    } else {
      this.router.navigate([`/dashboard/travels/${entityId}/contract`]);
    }
  }
}
