import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TransferPlan } from "../models/transfer";
import { TranslateService } from "@ngx-translate/core";
import { TransfersService } from "../services/transfers.service.service";

@Component({
  selector: "app-transfers-plan-details",
  templateUrl: "./transfers-plan-details.component.html",
})
export class TransfersPlanDetailsComponent implements OnInit {
  transfersPlanData!: TransferPlan;
  id!: number;
  entityDetails: string | undefined;

  constructor(private transfersPassengersService: TransfersService, private route: ActivatedRoute, private translate: TranslateService) {}

  ngOnInit(): void {
    this.id = +this.route.snapshot.params["id"];
    this.loadTransfersDetails();
  }
  loadTransfersDetails() {
    const subscription = this.transfersPassengersService.getTransferPlan(this.id).subscribe((response) => {
      subscription.unsubscribe();
      this.transfersPlanData = response;
      this.entityDetails =
        this.translate.instant("paymentsEntities.transfer") + ": " + this.transfersPlanData.transfer.destination.description + ", " + moment(this.transfersPlanData.transfer.departureDate).format("DD.MM.YYYY");
    });
  }
  refetch() {
    this.loadTransfersDetails();
  }
}
