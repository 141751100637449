<div>
  <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
    <h5 class="text-lg font-semibold dark:text-white-light">{{ "office-branch.title" | translate }}</h5>
    <div class="flex items-center gap-5 md:justify-end ltr:ml-auto rtl:mr-auto">
      <button type="button" class="btn btn-outline-primary flex" (click)="openModalForCreating()">
        <icon-plus class="ltr:mr-2 rtl:ml-2" />
        {{ "office-branch.add_office-branch" | translate }}
      </button>
      <div>
        <modal #addOfficeLocationModal class="modal-center overflow-allowed" [closeOnOutsideClick]="false">
          <ng-template #modalHeader>
            <div class="!py-5" *ngIf="!isEdit">
              {{ "office-branch.eturist_official_location" | translate }}
            </div>
          </ng-template>
          <ng-template #modalBody>
            <div class="grid grid-cols-1 gap-5 md:grid-cols-1" *ngIf="branchId">
              <app-office-branch-location-form [id]="branchId" (submissionDone)="closeLocationsModal()"></app-office-branch-location-form>
            </div>
          </ng-template>
          <ng-template #modalFooter></ng-template>
        </modal>
        <modal #createOfficeModal class="modal-center wider-modal" [closeOnOutsideClick]="false">
          <ng-template #modalHeader>
            <div class="!py-5" *ngIf="!isEdit">
              {{ "office-branch.add_office-branch" | translate }}
            </div>
            <div class="!py-5" *ngIf="isEdit">
              {{ "office-branch.edit_office-branch" | translate }}
            </div>
          </ng-template>
          <ng-template #modalBody>
            <div class="grid grid-cols-1 gap-5 md:grid-cols-1">
              <app-office-branch-form [id]="branchId" (submissionDone)="closeModal()" #officeBranchForm></app-office-branch-form>
            </div>
          </ng-template>
          <ng-template #modalFooter></ng-template>
        </modal>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
    <div class="rounded-lg border border-[#e0e6ed] bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none" *ngFor="let office of offices; index as i">
      <div class="flex h-full flex-col p-6 sm:flex-row">
        <!-- Left Section: Logo & Buttons -->
        <div class="flex h-full flex-1 flex-col items-center justify-between sm:items-start">
          <!-- Workers Button -->
          <div>
            <button type="button" [ngxTippy]="getUsersNameList(office.users)" class="btn btn-outline-primary btn-sm">{{ office.users.length }} {{ "workers" | translate }}</button>
          </div>

          <!-- Logo Image -->
          <div class="h-32 w-32 rounded-lg bg-gray-200 p-2">
            <img [src]="office.logoImage ? office.logoImage.url : '/assets/images/logo-placeholder.png'" alt="" class="h-full w-full rounded-lg object-cover" />
          </div>

          <!-- Add Location Button -->
          <div>
            <button
              [ngxTippy]="tooltipHTMLTemplate"
              [tippyProps]="{
                trigger: 'click',
                duration: 1000,
                interactive: true
              }"
              class="btn btn-outline-primary btn-sm min-h-8 min-w-16 bg-contain bg-center bg-no-repeat"
              (click)="openModalAddingOfficeLocation(office.id)"
              [style.background-image]="'url(assets/images/eturista-logo.png)'"
              [ngStyle]="{ filter: office.street && office.city ? 'none' : 'grayscale(100%)' }"
            ></button>
            <template #tooltipHTMLTemplate>
              <span [innerHtml]="message"></span>
            </template>
          </div>
        </div>

        <!-- Right Section: Office Details -->
        <div class="flex-2 flex h-full flex-col justify-between text-center sm:text-left ltr:sm:pl-6 rtl:sm:pr-6">
          <!-- Office Information -->
          <div>
            <div class="mb-3 flex flex-wrap justify-end" *ngIf="office.street && office.city">
              <span class="mr-2">{{ office.street }}, {{ office.city }}</span>
              <icon-home />
            </div>
            <div class="mb-3 flex flex-wrap justify-end" *ngIf="office.fullAddress">
              <span class="mr-2">{{ office.fullAddress }}</span>
              <icon-home />
            </div>
            <div class="mb-3 flex flex-wrap justify-end" *ngIf="office.phoneNumber">
              <span class="mr-2">{{ office.phoneNumber }}</span>
              <icon-phone />
            </div>
            <div class="mb-3 flex flex-wrap justify-end" *ngIf="office.email">
              <span class="mr-2">{{ office.email }}</span>
              <icon-mail />
            </div>
            <div class="mb-3 flex flex-wrap justify-end" *ngIf="office.siteURL">
              <span class="mr-2">{{ office.siteURL }}</span>
              <icon-chrome />
            </div>
            <div class="mb-3 flex flex-wrap justify-end">
              <span class="mr-2">{{ "giro-account" | translate }}: {{ office.bankAccount }}</span>
              <icon-zip-file />
            </div>
            <div class="mb-3 flex flex-wrap justify-end">
              <span class="mr-2">{{ "PIB" | translate }}: {{ office.pib }}</span>
              <icon-zip-file />
            </div>
          </div>

          <!-- Edit and Delete Buttons -->
          <div class="mt-4 flex justify-end space-x-4">
            <a type="button" [ngxTippy]="'office-branch.edit' | translate" class="btn btn-primary btn-sm pointer" (click)="openModalForEditing(office.id)"> <icon-pencil /> {{ "edit" | translate }} </a>
            <a type="button" [ngxTippy]="'office-branch.delete' | translate" class="btn btn-danger btn-sm pointer" (click)="deleteOfficeBranch(office.id)">
              <icon-trash-lines />{{ "office-branch.delete" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
