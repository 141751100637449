<div class="panel relative rounded-md border-white-dark/20 bg-slate-100 dark:bg-gray-900" *ngIf="overallStatistics">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-3">
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.income' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.income" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.brutoTotal | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.expense' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.expense" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.netoTotal | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.currentProfit' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.currentProfit" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.profit | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.totalDebt' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.totalDebt" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.totalDept | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.remainingDebt' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.remainingDebt" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.globalDepth | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div class="flex items-center">
          <div class="d-flex align-items-center" [ngxTippy]="'descriptions.pendingProfit' | translate">
            <strong class="cursor-pointer text-dark">{{ "overallstatistics.profitOnWait" | translate }}:</strong>
            <strong class="ms-1 cursor-pointer text-danger">*</strong>
          </div>
        </div>
        <div>{{ overallStatistics.profitOnWait | currency : "EUR" }}</div>
      </div>
    </div>
  </div>
</div>

