import { createReducer, on } from "@ngrx/store";
import { UserBase, User } from "src/app/auth/model/user";
import { setUser, resetUser, updateUser, updateSettings, updateUserProfileImage } from "./auth.actions";

export interface AuthState {
  user: User | undefined;
}

export const initialState: AuthState = { user: undefined };

export const authReducer = createReducer(
  initialState,
  on(setUser, (state: AuthState, { user }: { user: User }) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    return {
      ...state,
      user,
    };
  }),
  on(resetUser, (_) => {
    localStorage.removeItem("user");
    return initialState;
  }),

  on(updateUser, (state, { user }: { user: UserBase }): any => {
    if (state.user) {
      const { uniqueMasterCitizenNumber, name, surname } = user;
      const updatedUser = { ...state.user, uniqueMasterCitizenNumber, name, surname };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return { ...state, user: updatedUser };
    }
    return state;
  }),

  on(updateSettings, (state: AuthState, { settings }: { settings: string }): any => {
    if (state.user) {
      const updatedUser = { ...state.user, settings: settings };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return { ...state, user: updatedUser };
    }
    return state;
  }),
  on(updateUserProfileImage, (state: AuthState, { imageUrl }: { imageUrl: string }) => {
    if (state.user) {
      const updatedUser = { ...state.user, imageUrl: imageUrl };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return { ...state, user: updatedUser };
    }
    return state;
  })
);
