import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

// Custom validator function to validate date format (DD.MM.YYYY) using Day.js
export function dateFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === "" || control.value === null || control.value === undefined) return null;
    const date = moment(control.value, "DD.MM.YYYY");
    // Check if the value matches the expected date format (DD.MM.YYYY)
    if (!date.isValid()) {
      return { invalidDateFormat: { value: control.value } };
    }

    // Date format is valid
    return null;
  };
}

export function dateTimeFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date = moment(control.value, "DD.MM.YYYY HH:mm", true);

    const dateWithoutDots = moment(control.value, "DDMMYYYYHHmm", true);
    if (!date.isValid() && !dateWithoutDots.isValid()) {
      return { invalidDateFormat: { value: control.value } };
    }

    return null;
  };
}

export function endDateTimeAfterStartDateTimeValidator(formGroup: FormGroup) {
  const departureDate = formGroup.get("departureDate")?.value;
  const arrivalDate = formGroup.get("arrivalDate")?.value;

  if (!departureDate || !arrivalDate) {
    return null;
  }

  const departureMoment = moment(departureDate, "DD.MM.YYYY HH:mm");
  const arrivalMoment = moment(arrivalDate, "DD.MM.YYYY HH:mm");

  return arrivalMoment.isBefore(departureMoment) ? { endDateBeforeStartDate: true } : null;
}

export function endDateAfterStartDateValidator(formGroup: FormGroup) {
  const start = formGroup.get("start")?.value;
  const end = formGroup.get("end")?.value;

  if (!start || !end) {
    return null;
  }

  const startDate = moment(start, "DD.MM.YYYY");
  const endDate = moment(end, "DD.MM.YYYY");

  return endDate.isBefore(startDate) ? { endDateBeforeStartDate: true } : null;
}
export function transformDateForFetch(date: string): string {
  if (!date || typeof date !== "string") return "";

  if (date.includes("T")) {
    return moment(date).format("DD.MM.YYYY");
  }

  const validDate = moment(date, "DD.MM.YYYY", true);
  if (!validDate.isValid()) {
    throw new Error("Invalid date format");
  }

  return date;
}
