import { IconMenuDocumentationComponent } from "./menu/icon-menu-documentation";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconMenuAppsComponent } from "./menu/icon-menu-apps";
import { IconMenuAuthenticationComponent } from "./menu/icon-menu-authentication";
import { IconMenuCalendarComponent } from "./menu/icon-menu-calendar";
import { IconMenuChartsComponent } from "./menu/icon-menu-charts";
import { IconMenuChatComponent } from "./menu/icon-menu-chat";
import { IconMenuComponentsComponent } from "./menu/icon-menu-components";
import { IconMenuContactsComponent } from "./menu/icon-menu-contacts";
import { IconMenuDashboardComponent } from "./menu/icon-menu-dashboard";
import { IconMenuDatatablesComponent } from "./menu/icon-menu-datatables";
import { IconMenuDragAndDropComponent } from "./menu/icon-menu-drag-and-drop";
import { IconMenuElementsComponent } from "./menu/icon-menu-elements";
import { IconMenuFontIconsComponent } from "./menu/icon-menu-font-icons";
import { IconMenuFormsComponent } from "./menu/icon-menu-forms";
import { IconMenuInvoiceComponent } from "./menu/icon-menu-invoice";
import { IconMenuMailboxComponent } from "./menu/icon-menu-mailbox";
import { IconMenuMoreComponent } from "./menu/icon-menu-more";
import { IconMenuNotesComponent } from "./menu/icon-menu-notes";
import { IconMenuPagesComponent } from "./menu/icon-menu-pages";
import { IconMenuScrumboardComponent } from "./menu/icon-menu-scrumboard";
import { IconMenuTablesComponent } from "./menu/icon-menu-tables";
import { IconMenuTodoComponent } from "./menu/icon-menu-todo";
import { IconMenuUsersComponent } from "./menu/icon-menu-users";
import { IconMenuWidgetsComponent } from "./menu/icon-menu-widgets";
import { IconAirplayComponent } from "./icon-airplay";
import { IconLoaderComponent } from "./icon-loader";
import { IconArchiveComponent } from "./icon-archive";
import { IconArrowBackwardComponent } from "./icon-arrow-backward";
import { IconArrowForwardComponent } from "./icon-arrow-forward";
import { IconArrowLeftComponent } from "./icon-arrow-left";
import { IconArrowWaveLeftUpComponent } from "./icon-arrow-wave-left-up";
import { IconAtComponent } from "./icon-at";
import { IconAwardComponent } from "./icon-award";
import { IconBarChartComponent } from "./icon-bar-chart";
import { IconBellComponent } from "./icon-bell";
import { IconBellBingComponent } from "./icon-bell-bing";
import { IconBinanceComponent } from "./icon-binance";
import { IconBitcoinComponent } from "./icon-bitcoin";
import { IconBoltComponent } from "./icon-bolt";
import { IconBookComponent } from "./icon-book";
import { IconBookmarkComponent } from "./icon-bookmark";
import { IconBoxComponent } from "./icon-box";
import { IconCalendarComponent } from "./icon-calendar";
import { IconCameraComponent } from "./icon-camera";
import { IconCaretDownComponent } from "./icon-caret-down";
import { IconCaretsDownComponent } from "./icon-carets-down";
import { IconCashBanknotesComponent } from "./icon-cash-banknotes";
import { IconChartSquareComponent } from "./icon-chart-square";
import { IconChatDotComponent } from "./icon-chat-dot";
import { IconChatDotsComponent } from "./icon-chat-dots";
import { IconChatNotificationComponent } from "./icon-chat-notification";
import { IconChecksComponent } from "./icon-checks";
import { IconChromeComponent } from "./icon-chrome";
import { IconCircleCheckComponent } from "./icon-circle-check";
import { IconClipboardTextComponent } from "./icon-clipboard-text";
import { IconClockComponent } from "./icon-clock";
import { IconCloudDownloadComponent } from "./icon-cloud-download";
import { IconCodeComponent } from "./icon-code";
import { IconCoffeeComponent } from "./icon-coffee";
import { IconCopyComponent } from "./icon-copy";
import { IconCpuBoltComponent } from "./icon-cpu-bolt";
import { IconCreditCardComponent } from "./icon-credit-card";
import { IconDesktopComponent } from "./icon-desktop";
import { IconDollarSignComponent } from "./icon-dollar-sign";
import { IconDollarSignCircleComponent } from "./icon-dollar-sign-circle";
import { IconDownloadComponent } from "./icon-download";
import { IconDribbbleComponent } from "./icon-dribbble";
import { IconDropletComponent } from "./icon-droplet";
import { IconEditComponent } from "./icon-edit";
import { IconEthereumComponent } from "./icon-ethereum";
import { IconEyeComponent } from "./icon-eye";
import { IconFacebookComponent } from "./icon-facebook";
import { IconFacebookCircleComponent } from "./icon-facebook-circle";
import { IconFileComponent } from "./icon-file";
import { IconFolderComponent } from "./icon-folder";
import { IconFolderMinusComponent } from "./icon-folder-minus";
import { IconFolderPlusComponent } from "./icon-folder-plus";
import { IconGalleryComponent } from "./icon-gallery";
import { IconGithubComponent } from "./icon-github";
import { IconGlobeComponent } from "./icon-globe";
import { IconGoogleComponent } from "./icon-google";
import { IconHeartComponent } from "./icon-heart";
import { IconHelpCircleComponent } from "./icon-help-circle";
import { IconHomeComponent } from "./icon-home";
import { IconHorizontalDotsComponent } from "./icon-horizontal-dots";
import { IconInboxComponent } from "./icon-inbox";
import { IconInfoCircleComponent } from "./icon-info-circle";
import { IconInfoHexagonComponent } from "./icon-info-hexagon";
import { IconInfoTriangleComponent } from "./icon-info-triangle";
import { IconInstagramComponent } from "./icon-instagram";
import { IconLaptopComponent } from "./icon-laptop";
import { IconLayoutComponent } from "./icon-layout";
import { IconLayoutGridComponent } from "./icon-layout-grid";
import { IconLinkComponent } from "./icon-link";
import { IconLinkedinComponent } from "./icon-linkedin";
import { IconListCheckComponent } from "./icon-list-check";
import { IconLitecoinComponent } from "./icon-litecoin";
import { IconLockComponent } from "./icon-lock";
import { IconLockDotsComponent } from "./icon-lock-dots";
import { IconLoginComponent } from "./icon-login";
import { IconLogoutComponent } from "./icon-logout";
import { IconMailComponent } from "./icon-mail";
import { IconMailDotComponent } from "./icon-mail-dot";
import { IconMapPinComponent } from "./icon-map-pin";
import { IconMenuComponent } from "./icon-menu";
import { IconMessageComponent } from "./icon-message";
import { IconMessage2Component } from "./icon-message-2";
import { IconMessageDotsComponent } from "./icon-message-dots";
import { IconMessagesDotComponent } from "./icon-messages-dot";
import { IconMicrophoneOffComponent } from "./icon-microphone-off";
import { IconMinusComponent } from "./icon-minus";
import { IconMinusCircleComponent } from "./icon-minus-circle";
import { IconMoodSmileComponent } from "./icon-mood-smile";
import { IconMoonComponent } from "./icon-moon";
import { IconMultipleForwardRightComponent } from "./icon-multiple-forward-right";
import { IconNetflixComponent } from "./icon-netflix";
import { IconNotesComponent } from "./icon-notes";
import { IconNotesEditComponent } from "./icon-notes-edit";
import { IconOpenBookComponent } from "./icon-open-book";
import { IconPaperclipComponent } from "./icon-paperclip";
import { IconPencilComponent } from "./icon-pencil";
import { IconPencilPaperComponent } from "./icon-pencil-paper";
import { IconPhoneComponent } from "./icon-phone";
import { IconPhoneCallComponent } from "./icon-phone-call";
import { IconPlayCircleComponent } from "./icon-play-circle";
import { IconPlusComponent } from "./icon-plus";
import { IconPlusCircleComponent } from "./icon-plus-circle";
import { IconPrinterComponent } from "./icon-printer";
import { IconRefreshComponent } from "./icon-refresh";
import { IconDiscardComponent } from "./icon-discard";
import { IconRestoreComponent } from "./icon-restore";
import { IconRouterComponent } from "./icon-router";
import { IconSafariComponent } from "./icon-safari";
import { IconSaveComponent } from "./icon-save";
import { IconSearchComponent } from "./icon-search";
import { IconSendComponent } from "./icon-send";
import { IconServerComponent } from "./icon-server";
import { IconSettingsComponent } from "./icon-settings";
import { IconShareComponent } from "./icon-share";
import { IconShoppingBagComponent } from "./icon-shopping-bag";
import { IconShoppingCartComponent } from "./icon-shopping-cart";
import { IconSolanaComponent } from "./icon-solana";
import { IconSquareCheckComponent } from "./icon-square-check";
import { IconSquareRotatedComponent } from "./icon-square-rotated";
import { IconStarComponent } from "./icon-star";
import { IconSunComponent } from "./icon-sun";
import { IconTagComponent } from "./icon-tag";
import { IconTetherComponent } from "./icon-tether";
import { IconThumbUpComponent } from "./icon-thumb-up";
import { IconTrashComponent } from "./icon-trash";
import { IconTrashLinesComponent } from "./icon-trash-lines";
import { IconTrendingUpComponent } from "./icon-trending-up";
import { IconTwitterComponent } from "./icon-twitter";
import { IconTxtFileComponent } from "./icon-txt-file";
import { IconUserComponent } from "./icon-user";
import { IconUserPlusComponent } from "./icon-user-plus";
import { IconUsersComponent } from "./icon-users";
import { IconUsersGroupComponent } from "./icon-users-group";
import { IconVideoComponent } from "./icon-video";
import { IconWheelComponent } from "./icon-wheel";
import { IconXComponent } from "./icon-x";
import { IconXCircleComponent } from "./icon-x-circle";
import { IconZipFileComponent } from "./icon-zip-file";
import { IconBusComponent } from "./icon-bus";
import { IconEuroComponent } from "./icon-euro";
import { IconHotelComponent } from "./icon-hotel";
import { IconDestinationComponent } from "./icon-destination";
import { IconTravelComponent } from "./icon-travel";
import { IconAgentComponent } from "./icon-agent";
import { IconCodeFileComponent } from "src/app/shared/icon/icon-code-file";
import { IconMenuPlaneComponent } from "./menu/icon-menu-plane";
import { IconMenuBusComponent } from "./menu/icon-menu-bus";
import { IconMenuDestinationComponent } from "./menu/icon-menu-destination";
import { IconMenuDailySalesComponent } from "./menu/icon-menu-daily-sales";
import { IconMenuHotelComponent } from "./menu/icon-menu-hotel";
import { IconMenuCompanyComponent } from "./menu/icon-menu-company";
import { IconMenuDashboardDinoComponent } from "./menu/icon-menu-dashboard-dino";
import { IconMenuLogoIconComponent } from "./menu/icon-menu-logo-icon";
import { IconMenuLogoTextComponent } from "./menu/icon-menu-logo-text";
import { IconMenuLogoComponent } from "./menu/icon-menu-logo";
import { IconMenuCustomerComponent } from "./menu/icon-menu-customer";
import { IconMenuOrganizerComponent } from "./menu/icon-menu-organizer";
import { IconMenuGroupTravelComponent } from "./menu/icon-menu-group-travel";
import { IconMenuTravelComponent } from "./menu/icon-menu-travel";
import { IconMenuStatisticsComponent } from "./menu/icon-menu-statistics";
import { IconMenuTransferComponent } from "./menu/icon-menu-transfer";
import { IconMenuBookOfEvidenceComponent } from "./menu/icon-menu-book-of-evidence";
import { IconMenuWorkersComponent } from "./menu/icon-menu-workers";
import { IconPersonComponent } from "./icon-person";
import { IconThumbsUpComponent } from "./icon-thumbs-up";
import { IconLocationComponent } from "./icon-location";
import { IconTopRightComponent } from "./icon-arrow-top-right";
import { IconBottomRightComponent } from "./icon-arrow-bottom-right";
import { IconSuticaseComponent } from "./icon-suitcase";
import { IconAirplaneComponent } from "./icon-airplane";
import { IconGroupTravelComponent } from "./icon-group-travel";
import { IconTransferComponent } from "./icon-transfer";
import { IconVoucherComponent } from "./icon-voucher";
import { IconContractComponent } from "./icon-contract";
import { IconBellLargerComponent } from "./icon-bell-larger";
import { IconDollarSignSmallerComponent } from "./icon-dollar-sign-smaller";
import { IconCalendarColoredComponent } from "./icon-calendar-colored";
import { IconChangeComponent } from "./icon-change";
import { IconAccountingDataComponent } from "./icon-accounting-data";
import { IconAppLoaderComponent } from "./icon-app-loader";
import { IconArrowTopComponent } from "./icon-arrow-top";
import { IconThreeDotsComponent } from "./icon-three-dots";
import { IconDownComponent } from "./icon-down";
import { IconUploadComponent } from "./icon-upload";
import { IconCompanyComponent } from "./icon-company";
import { IconWorkerMoneyComponent } from "./icon-worker-money";
import { IconWorkerLocationComponent } from "./icon-worker-location";
import { IconWorkerWorldComponent } from "./icon-worker-world";
import { IconWorkerPinComponent } from "./icon-worker-pin";
import { IconLogoComponent } from "./icon-logo";
import { IconEyeClosedComponent } from "./icon-eye-closed";
import { IconEyeOpenComponent } from "./icon-eye-open";
import { IconLuggageComponent } from "./icon-luggage";

@NgModule({
  imports: [CommonModule],
  declarations: [
    IconMenuBookOfEvidenceComponent,
    IconMenuTransferComponent,
    IconMenuStatisticsComponent,
    IconMenuTravelComponent,
    IconMenuGroupTravelComponent,
    IconMenuOrganizerComponent,
    IconMenuCustomerComponent,
    IconMenuLogoComponent,
    IconMenuLogoTextComponent,
    IconMenuLogoIconComponent,
    IconMenuDashboardDinoComponent,
    IconMenuCompanyComponent,
    IconMenuHotelComponent,
    IconMenuDailySalesComponent,
    IconMenuDestinationComponent,
    IconBusComponent,
    IconMenuPlaneComponent,
    IconMenuAppsComponent,
    IconMenuAuthenticationComponent,
    IconMenuCalendarComponent,
    IconMenuChartsComponent,
    IconMenuChatComponent,
    IconMenuComponentsComponent,
    IconMenuContactsComponent,
    IconMenuDashboardComponent,
    IconMenuDatatablesComponent,
    IconMenuDocumentationComponent,
    IconMenuDragAndDropComponent,
    IconMenuElementsComponent,
    IconMenuFontIconsComponent,
    IconMenuFormsComponent,
    IconMenuInvoiceComponent,
    IconMenuMailboxComponent,
    IconMenuMoreComponent,
    IconMenuNotesComponent,
    IconMenuPagesComponent,
    IconMenuScrumboardComponent,
    IconMenuTablesComponent,
    IconMenuTodoComponent,
    IconMenuUsersComponent,
    IconMenuWidgetsComponent,
    IconAirplayComponent,
    IconArchiveComponent,
    IconArrowBackwardComponent,
    IconArrowForwardComponent,
    IconArrowLeftComponent,
    IconArrowWaveLeftUpComponent,
    IconAtComponent,
    IconAwardComponent,
    IconBarChartComponent,
    IconBellComponent,
    IconBellBingComponent,
    IconBinanceComponent,
    IconBitcoinComponent,
    IconBoltComponent,
    IconBookComponent,
    IconBookmarkComponent,
    IconBoxComponent,
    IconCalendarComponent,
    IconCameraComponent,
    IconCaretDownComponent,
    IconCaretsDownComponent,
    IconCashBanknotesComponent,
    IconChartSquareComponent,
    IconChatDotComponent,
    IconChatDotsComponent,
    IconChatNotificationComponent,
    IconChecksComponent,
    IconChromeComponent,
    IconCircleCheckComponent,
    IconClipboardTextComponent,
    IconClockComponent,
    IconCloudDownloadComponent,
    IconCodeComponent,
    IconCoffeeComponent,
    IconCopyComponent,
    IconCpuBoltComponent,
    IconCreditCardComponent,
    IconDesktopComponent,
    IconDollarSignComponent,
    IconDollarSignCircleComponent,
    IconDownloadComponent,
    IconDribbbleComponent,
    IconDropletComponent,
    IconEditComponent,
    IconEthereumComponent,
    IconEyeComponent,
    IconFacebookComponent,
    IconFacebookCircleComponent,
    IconFileComponent,
    IconFolderComponent,
    IconFolderMinusComponent,
    IconFolderPlusComponent,
    IconGalleryComponent,
    IconGithubComponent,
    IconGlobeComponent,
    IconGoogleComponent,
    IconHeartComponent,
    IconHelpCircleComponent,
    IconHomeComponent,
    IconHorizontalDotsComponent,
    IconInboxComponent,
    IconInfoCircleComponent,
    IconInfoHexagonComponent,
    IconInfoTriangleComponent,
    IconInstagramComponent,
    IconLaptopComponent,
    IconLayoutComponent,
    IconLayoutGridComponent,
    IconLinkComponent,
    IconLinkedinComponent,
    IconListCheckComponent,
    IconLitecoinComponent,
    IconLoaderComponent,
    IconLockComponent,
    IconLockDotsComponent,
    IconLoginComponent,
    IconLogoutComponent,
    IconMailComponent,
    IconMailDotComponent,
    IconMapPinComponent,
    IconMenuComponent,
    IconMessageComponent,
    IconMessage2Component,
    IconMessageDotsComponent,
    IconMessagesDotComponent,
    IconMicrophoneOffComponent,
    IconMinusComponent,
    IconMinusCircleComponent,
    IconMoodSmileComponent,
    IconMoonComponent,
    IconMultipleForwardRightComponent,
    IconNetflixComponent,
    IconNotesComponent,
    IconNotesEditComponent,
    IconOpenBookComponent,
    IconPaperclipComponent,
    IconPencilComponent,
    IconPencilPaperComponent,
    IconPhoneComponent,
    IconPhoneCallComponent,
    IconPlayCircleComponent,
    IconPlusComponent,
    IconPlusCircleComponent,
    IconPrinterComponent,
    IconRefreshComponent,
    IconDiscardComponent,
    IconRestoreComponent,
    IconRouterComponent,
    IconSafariComponent,
    IconSaveComponent,
    IconSearchComponent,
    IconSendComponent,
    IconServerComponent,
    IconSettingsComponent,
    IconShareComponent,
    IconShoppingBagComponent,
    IconShoppingCartComponent,
    IconSolanaComponent,
    IconSquareCheckComponent,
    IconSquareRotatedComponent,
    IconStarComponent,
    IconSunComponent,
    IconTagComponent,
    IconTetherComponent,
    IconThumbUpComponent,
    IconTrashComponent,
    IconTrashLinesComponent,
    IconTrendingUpComponent,
    IconTwitterComponent,
    IconTxtFileComponent,
    IconUserComponent,
    IconUserPlusComponent,
    IconUsersComponent,
    IconUsersGroupComponent,
    IconVideoComponent,
    IconWheelComponent,
    IconXComponent,
    IconXCircleComponent,
    IconZipFileComponent,
    IconCodeFileComponent,
    IconMenuBusComponent,
    IconEuroComponent,
    IconHotelComponent,
    IconDestinationComponent,
    IconTravelComponent,
    IconAgentComponent,
    IconMenuWorkersComponent,
    IconPersonComponent,
    IconThumbsUpComponent,
    IconLocationComponent,
    IconTopRightComponent,
    IconBottomRightComponent,
    IconSuticaseComponent,
    IconAirplaneComponent,
    IconGroupTravelComponent,
    IconTransferComponent,
    IconVoucherComponent,
    IconContractComponent,
    IconBellLargerComponent,
    IconDollarSignSmallerComponent,
    IconCalendarColoredComponent,
    IconChangeComponent,
    IconAccountingDataComponent,
    IconAppLoaderComponent,
    IconArrowTopComponent,
    IconThreeDotsComponent,
    IconDownComponent,
    IconUploadComponent,
    IconCompanyComponent,
    IconWorkerMoneyComponent,
    IconWorkerLocationComponent,
    IconWorkerWorldComponent,
    IconWorkerPinComponent,
    IconLogoComponent,
    IconEyeClosedComponent,
    IconEyeOpenComponent,
    IconLuggageComponent,
  ],
  exports: [
    IconMenuBookOfEvidenceComponent,
    IconMenuTransferComponent,
    IconMenuStatisticsComponent,
    IconMenuTravelComponent,
    IconMenuGroupTravelComponent,
    IconMenuOrganizerComponent,
    IconMenuCustomerComponent,
    IconMenuLogoComponent,
    IconMenuLogoTextComponent,
    IconMenuLogoIconComponent,
    IconMenuDashboardDinoComponent,
    IconMenuCompanyComponent,
    IconMenuHotelComponent,
    IconMenuDailySalesComponent,
    IconMenuDestinationComponent,
    IconBusComponent,
    IconMenuPlaneComponent,
    IconMenuAppsComponent,
    IconMenuAuthenticationComponent,
    IconMenuCalendarComponent,
    IconMenuChartsComponent,
    IconMenuChatComponent,
    IconMenuComponentsComponent,
    IconMenuContactsComponent,
    IconMenuDashboardComponent,
    IconMenuDatatablesComponent,
    IconMenuDocumentationComponent,
    IconMenuDragAndDropComponent,
    IconMenuElementsComponent,
    IconMenuFontIconsComponent,
    IconMenuFormsComponent,
    IconMenuInvoiceComponent,
    IconMenuMailboxComponent,
    IconMenuMoreComponent,
    IconMenuNotesComponent,
    IconMenuPagesComponent,
    IconMenuScrumboardComponent,
    IconMenuTablesComponent,
    IconMenuTodoComponent,
    IconMenuUsersComponent,
    IconMenuWidgetsComponent,
    IconAirplayComponent,
    IconArchiveComponent,
    IconArrowBackwardComponent,
    IconArrowForwardComponent,
    IconArrowLeftComponent,
    IconArrowWaveLeftUpComponent,
    IconAtComponent,
    IconAwardComponent,
    IconBarChartComponent,
    IconBellComponent,
    IconBellBingComponent,
    IconBinanceComponent,
    IconBitcoinComponent,
    IconBoltComponent,
    IconBookComponent,
    IconBookmarkComponent,
    IconBoxComponent,
    IconCalendarComponent,
    IconCameraComponent,
    IconCaretDownComponent,
    IconCaretsDownComponent,
    IconCashBanknotesComponent,
    IconChartSquareComponent,
    IconChatDotComponent,
    IconChatDotsComponent,
    IconChatNotificationComponent,
    IconChecksComponent,
    IconChromeComponent,
    IconCircleCheckComponent,
    IconClipboardTextComponent,
    IconClockComponent,
    IconCloudDownloadComponent,
    IconCodeComponent,
    IconCoffeeComponent,
    IconCopyComponent,
    IconCpuBoltComponent,
    IconCreditCardComponent,
    IconDesktopComponent,
    IconDollarSignComponent,
    IconDollarSignCircleComponent,
    IconDownloadComponent,
    IconDribbbleComponent,
    IconDropletComponent,
    IconEditComponent,
    IconEthereumComponent,
    IconEyeComponent,
    IconFacebookComponent,
    IconFacebookCircleComponent,
    IconFileComponent,
    IconFolderComponent,
    IconFolderMinusComponent,
    IconFolderPlusComponent,
    IconGalleryComponent,
    IconGithubComponent,
    IconGlobeComponent,
    IconGoogleComponent,
    IconHeartComponent,
    IconHelpCircleComponent,
    IconHomeComponent,
    IconHorizontalDotsComponent,
    IconInboxComponent,
    IconInfoCircleComponent,
    IconInfoHexagonComponent,
    IconInfoTriangleComponent,
    IconInstagramComponent,
    IconLaptopComponent,
    IconLayoutComponent,
    IconLayoutGridComponent,
    IconLinkComponent,
    IconLinkedinComponent,
    IconListCheckComponent,
    IconLitecoinComponent,
    IconLoaderComponent,
    IconLockComponent,
    IconLockDotsComponent,
    IconLoginComponent,
    IconLogoutComponent,
    IconMailComponent,
    IconMailDotComponent,
    IconMapPinComponent,
    IconMenuComponent,
    IconMessageComponent,
    IconMessage2Component,
    IconMessageDotsComponent,
    IconMessagesDotComponent,
    IconMicrophoneOffComponent,
    IconMinusComponent,
    IconMinusCircleComponent,
    IconMoodSmileComponent,
    IconMoonComponent,
    IconMultipleForwardRightComponent,
    IconNetflixComponent,
    IconNotesComponent,
    IconNotesEditComponent,
    IconOpenBookComponent,
    IconPaperclipComponent,
    IconPencilComponent,
    IconPencilPaperComponent,
    IconPhoneComponent,
    IconPhoneCallComponent,
    IconPlayCircleComponent,
    IconPlusComponent,
    IconPlusCircleComponent,
    IconPrinterComponent,
    IconRefreshComponent,
    IconDiscardComponent,
    IconRestoreComponent,
    IconRouterComponent,
    IconSafariComponent,
    IconSaveComponent,
    IconSearchComponent,
    IconSendComponent,
    IconServerComponent,
    IconSettingsComponent,
    IconShareComponent,
    IconShoppingBagComponent,
    IconShoppingCartComponent,
    IconSolanaComponent,
    IconSquareCheckComponent,
    IconSquareRotatedComponent,
    IconStarComponent,
    IconSunComponent,
    IconTagComponent,
    IconTetherComponent,
    IconThumbUpComponent,
    IconTrashComponent,
    IconTrashLinesComponent,
    IconTrendingUpComponent,
    IconTwitterComponent,
    IconTxtFileComponent,
    IconUserComponent,
    IconUserPlusComponent,
    IconUsersComponent,
    IconUsersGroupComponent,
    IconVideoComponent,
    IconWheelComponent,
    IconXComponent,
    IconXCircleComponent,
    IconZipFileComponent,
    IconCodeFileComponent,
    IconMenuBusComponent,
    IconEuroComponent,
    IconHotelComponent,
    IconDestinationComponent,
    IconTravelComponent,
    IconAgentComponent,
    IconMenuWorkersComponent,
    IconPersonComponent,
    IconThumbsUpComponent,
    IconLocationComponent,
    IconTopRightComponent,
    IconBottomRightComponent,
    IconSuticaseComponent,
    IconAirplaneComponent,
    IconGroupTravelComponent,
    IconTransferComponent,
    IconVoucherComponent,
    IconContractComponent,
    IconBellLargerComponent,
    IconDollarSignSmallerComponent,
    IconCalendarColoredComponent,
    IconChangeComponent,
    IconAccountingDataComponent,
    IconAppLoaderComponent,
    IconArrowTopComponent,
    IconThreeDotsComponent,
    IconDownComponent,
    IconUploadComponent,
    IconCompanyComponent,
    IconWorkerMoneyComponent,
    IconWorkerLocationComponent,
    IconWorkerWorldComponent,
    IconWorkerPinComponent,
    IconLogoComponent,
    IconEyeClosedComponent,
    IconEyeOpenComponent,
    IconLuggageComponent,
  ],
})
export class IconModule {}
