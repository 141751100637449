import { Store } from "@ngrx/store";
import { Hotel } from "../models/hotel";
import { Router } from "@angular/router";
import { from, map, Observable, of, tap } from "rxjs";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "src/app/store/index.selector";
import { HotelsService } from "../services/hotels.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";
import { UserRole } from "src/app/shared/enums/user-roles";

@Component({
  templateUrl: "./hotels.table.component.html",
  animations: [toggleAnimation],
})
export class HotelsTableComponent extends BaseTableComponent<Hotel> implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  public form!: FormGroup;
  editMode = false;
  hotelId = 0;
  mergeMode = false;
  term!: string;
  name = "";
  foundHotels: Hotel[] = [];
  filteredHotels: Hotel[] = [];
  selectedHotelIds: number[] = [];
  hotels: Observable<Hotel[]> = of([]);
  selectedHotels: Hotel[] = [];
  hotelName!: string;
  isLoading = false;
  hotelData: any = {};
  adminRole = UserRole.Admin;
  officeBranchManagerRole = UserRole.OfficeBranchManager;

  constructor(
    private hotelsService: HotelsService,
    public override translate: TranslateService,
    public fb: FormBuilder,
    public override store: Store<AppState>,
    public override tableService: TableService,
    public router: Router,
    private positioningService: PositioningService,
    private modalService: ModalService,
    public override userService: UserService
  ) {
    super(store, tableService, `hotels/paginated`, userService, translate);
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.loadHotels();
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
  loadHotels() {
    this.filteredHotels = [];
    this.foundHotels = [];
    this.selectedHotelIds = [];
    this.hotels = this.hotelsService.getHotels().pipe(map((organizers: Hotel[]) => organizers.filter((organizer) => organizer.id !== this.hotelId)));
  }

  openAddHotelModal(): void {
    from(this.modalService.openHotelModal())
      .pipe(
        tap((result) => {
          if (result) {
            this.fetchItems(this.getPaginationParams());
            this.loadHotels();
            showMessage(PopupType.Success, this.translate.instant("hotel_data.added_hotel_successfully"));
          }
        })
      )
      .subscribe();
  }

  openEditHotelModal(hotel: Hotel) {
    from(this.modalService.openHotelModal(hotel.id))
      .pipe(
        tap((result) => {
          if (result) {
            this.fetchItems(this.getPaginationParams());
            this.loadHotels();
            showMessage(PopupType.Success, this.translate.instant("hotel_data.updated_hotel_successfully"));
          }
        })
      )
      .subscribe();
  }
  addMerge(id: number, name: any) {
    this.hotelId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open();
    this.foundHotels = [];
    this.filteredHotels = [];
    this.term = "";
  }
  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("hotel_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("hotel_info.name"), hide: false } as DatatableColumn,
      { field: "numberOfTravels", title: this.translate.instant("hotel_info.num_of_arrangements"), hide: false, sort: false } as DatatableColumn,
      { field: "numberOfGroupDestinations", title: this.translate.instant("hotel_info.num_of_destinations"), hide: false, sort: false } as DatatableColumn,
      { field: "numberOfPeople", title: this.translate.instant("hotel_info.numberOfPeople"), hide: false, sort: false } as DatatableColumn,
      { field: "earnings", title: this.translate.instant("hotel_info.earnings"), hide: false, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("hotel_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("hotel_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("hotel_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfArrangements", title: this.translate.instant("hotel_export.numberOfArrangements"), hide: false } as DatatableColumn,
    { field: "numberOfDestinations", title: this.translate.instant("hotel_export.numberOfDestinations"), hide: false } as DatatableColumn,
    { field: "numberOfPeople", title: this.translate.instant("hotel_export.numberOfPeople"), hide: false } as DatatableColumn,
    { field: "earnings", title: this.translate.instant("hotel_export.earnings"), hide: false, sort: false } as DatatableColumn,
    { field: "createdAt", title: this.translate.instant("hotel_export.createdAt"), hide: false } as DatatableColumn,
  ];

  mergeSelectedHotels(selectedHotelIds: number[]) {
    if (selectedHotelIds.length > 0 && this.hotelId) {
      this.isLoading = true;
      const mergeSubscription = this.hotelsService.mergeHotels(this.hotelId, selectedHotelIds).subscribe({
        next: () => {
          mergeSubscription.unsubscribe();
          this.loadHotels();
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.isLoading = false;
          this.modal.close();
          this.fetchItems(this.getPaginationParams());
        },
        error: () => {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
          this.isLoading = false;
        },
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }
  async deleteHotels(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.hotelsService.deleteHotel(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.fetchItems(this.getPaginationParams());
          this.loadHotels();
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
}
