import { Subscription } from "rxjs";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { Component, forwardRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { AutocompleteRemoteComponent } from "src/app/shared/components/autocomplete-remote/autocomplete-remote.component";

@Component({
  selector: "app-destination-autocomplete",
  templateUrl: "./destination-autocomplete.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationAutocompleteComponent),
      multi: true,
    },
  ],
})
export class DestinationAutocompleteComponent implements ControlValueAccessor {
  @Input() form!: FormGroup;
  @Input() formControlName!: string;
  public subscription!: Subscription;

  EntityPicklistType = EntityPicklistType;
  isLoading = false;
  mockFormName: FormGroup;
  countrySelectorForm!: FormGroup;
  value: any;
  isDisabled = false;
  @ViewChild("countryModal") countryModal!: ModalComponent;
  @ViewChild("createSearch") createSearch!: AutocompleteRemoteComponent;
  @ViewChild("initialSearch") initialSearch!: AutocompleteRemoteComponent;
  cityName!: string;

  constructor(public utilService: UtilService, public translate: TranslateService) {
    this.mockFormName = new FormGroup({
      [this.formControlName]: new FormControl(""),
    });

    this.countrySelectorForm = new FormGroup({
      country: new FormControl("", Validators.required),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(value: any): void {
    if (value === this.value) return;
    this.value = value;
    if (this.form && this.formControlName) {
      this.form.controls[this.formControlName].setValue(value, { emitEvent: false });
    }
  }

  focusOnInput(): void {
    setTimeout(() => {
      this.createSearch.focusField();
    }, 0);
  }

  addDestinationWithCountry() {
    return (value: string) => {
      this.countryModal.open();
      this.focusOnInput();
      this.cityName = value;
    };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Update form control value and propagate changes
  updateValue(newValue: any) {
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    if (newValue && newValue.description) {
      this.form.controls[this.formControlName].setValue(newValue.description, { emitEvent: false });
    }
  }

  focusField() {
    this.initialSearch.focusField();
  }

  save() {
    this.isLoading = true;
    const country = this.countrySelectorForm.controls["country"].value;
    const countryId = country?.id;
    if (!countryId) {
      showMessage(PopupType.Danger, this.translate.instant("countryRequired"));
      this.isLoading = false;
      return;
    }
    if (countryId !== undefined) {
      this.subscription = this.utilService.addDestinationWithCountry(this.cityName, countryId).subscribe((res) => {
        this.form.get(this.formControlName)?.setValue(res);
        this.cityName = "";
        this.countrySelectorForm.reset();
        this.subscription.unsubscribe();
      });
      this.isLoading = false;
    }
    this.countryModal.close();
  }
}
