<div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid h-screen place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>
<div *ngIf="user">
  <form class="mb-5 rounded-md border border-[#ebedf2] bg-white p-4 dark:border-[#191e3a] dark:bg-[#0e1726]" [formGroup]="userForm" (ngSubmit)="submitUserForm()">
    <div class="mb-10 flex justify-between">
      <h6 class="mb-5 text-lg font-bold">{{ "user_profile.general_information" | translate }}</h6>
      <button type="button" class="btn btn-primary" (click)="modal.open()"><icon-lock-dots class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />{{ "user_profile.change_password" | translate }}</button>
    </div>
    <div class="flex flex-col sm:flex-row">
      <div class="mb-5 flex w-full flex-col items-center sm:w-2/12 ltr:sm:mr-4 rtl:sm:ml-4">
        <img [src]="user.imageUrl ?? '/assets/images/auth/user-icon.png'" alt="User profile" class="mx-auto mb-4 h-32 w-32 rounded-full object-cover" />
        <button type="button" class="btn btn-primary mb-2 rounded-lg px-4 py-2 font-medium transition duration-300" (click)="fileInput.click()" [disabled]="isLoading">
          {{ "user_profile.change_image" | translate }}
        </button>
        <input type="file" #fileInput class="hidden" (change)="onFileChange($event)" />
      </div>

      <div class="grid flex-1 grid-cols-1 gap-5 sm:grid-cols-2">
        <div [ngClass]="userForm.controls['first_name'].touched ? (userForm.controls['first_name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="name">{{ "user_profile.first_name" | translate }}</label>
          <input
            id="name"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="first_name"
            [appLimitCharacterInput]="60"
          />
          <ng-container *ngIf="userForm.controls['first_name'].touched && userForm.controls['first_name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_name" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="userForm.controls['last_name'].touched ? (userForm.controls['last_name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="surname">{{ "user_profile.last_name" | translate }}</label>
          <input
            id="surname"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="last_name"
            [appLimitCharacterInput]="60"
          />
          <ng-container *ngIf="userForm.controls['last_name'].touched && userForm.controls['last_name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_surname" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div>
          <label for="email">{{ "user_profile.email" | translate }}</label>
          <input
            id="email"
            type="text"
            class="form-input"
            class="form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]"
            formControlName="email"
            [appLimitCharacterInput]="60"
          />
        </div>
        <div [ngClass]="userForm.controls['uniqueMasterCitizenNumber'].touched ? (userForm.controls['uniqueMasterCitizenNumber'].errors ? 'has-error' : 'has-success') : ''">
          <label for="uniqueMasterCitizenNumber">{{ "user_profile.uniqueMasterCitizenNumber" | translate }}</label>
          <input
            id="uniqueMasterCitizenNumber"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="uniqueMasterCitizenNumber"
            [appLimitCharacterInput]="60"
          />
          <ng-container *ngIf="userForm.controls['uniqueMasterCitizenNumber'].touched && userForm.controls['uniqueMasterCitizenNumber'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_uniqueMasterCitizenNumber" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div></div>
        <div class="flex justify-end">
          <div class="flex justify-end" *ngIf="editProfile">
            <button type="submit" class="btn btn-success">{{ "user_profile.save" | translate }}</button>
            <button type="button" class="btn btn-warning ml-10" (click)="discardChanges()">{{ "danger_zone.clear" | translate }}</button>
          </div>
          <div class="flex" *ngIf="!editProfile">
            <button type="button" class="btn btn-warning" (click)="editUser()">{{ "user_profile.edit" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal -->
<modal #modal class="modal-center auth-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5"></div>
  </ng-template>
  <ng-template #modalBody>
    <app-user-new-password [email]="user.email" (notifyWhenSend)="passwordUpdated($event)"></app-user-new-password>
  </ng-template>
</modal>
