import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { AppService } from "src/app/service/app.service";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/domain/user-profile/service/user.service";
import { Language, LanguageState } from "src/app/store/language/language.reducer";

@Component({
  moduleId: module.id,
  templateUrl: "./forgot-password.html",
  animations: [toggleAnimation],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  langData!: LanguageState;
  userData: any;
  loading = false;
  protected resetForm: FormGroup;
  passwordVisible = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(public userService: UserService, public router: Router, private fb: FormBuilder, public state: Store<AppState>, private appSetting: AppService, public translate: TranslateService) {
    this.resetForm = fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.state.subscribe((d: AppState) => {
      this.langData = d.lang;
    });
  }

  submitForm() {
    if (this.resetForm.valid) {
      this.sendResetPasswordRequest();
    } else {
      this.resetForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendResetPasswordRequest() {
    if (this.resetForm.valid) {
      this.loading = true;
      const resetSubscription = this.userService.forgotPasswordRequest(this.resetForm.value.email).subscribe({
        next: (res) => {
          this.loading = false;
          resetSubscription.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("reset_password.request_successful"));
          setTimeout(() => {
            this.router.navigate([""]);
          }, 3000);
        },
        error: (error) => {
          this.loading = false;
          resetSubscription.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("reset_password.request_unsuccessful"));
        },
      });
    }
  }

  changeLanguage(item: Language) {
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }
}
