import * as moment from "moment";
import { catchError, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ETouristCodebookEntry } from "src/app/shared/models/etourist";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { GroupTravelDestination } from "../../group-travels/models/group-travel";
import { ETouristStateService } from "../../sales/services/etourist-state.service";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { typeOrAccommodationCodeRequired } from "src/app/shared/validators/field.validator";

@Component({
  selector: "app-group-destinations-form",
  templateUrl: "./group-destinations.form.component.html",
  animations: [toggleAnimation],
})
export class GroupDestinationsFormComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    start: [null, Validators.required],
    end: [null, Validators.required],
    days: [null, Validators.required],
    nights: [null, Validators.required],
    notes: [null, Validators.required],
    service: [null],
    destination: [null, Validators.required],
    hotel: [null],
    eTuristAccommodationCode: [null],
    eTuristAccommodationLabel: [null],
  });

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  @Input() defaultDestination: GroupTravelDestination = {} as GroupTravelDestination;
  @Input() id: number | null = null;
  @Output() submitDestination: EventEmitter<GroupTravelDestination> = new EventEmitter<GroupTravelDestination>();
  protected accomodationSubscriber$!: Observable<ETouristCodebookEntry[]>;

  constructor(public fb: FormBuilder, public utilService: UtilService, private translate: TranslateService, public eTuristService: ETouristService, private etouristStateService: ETouristStateService) {}

  ngOnInit(): void {
    this.initForm(this.defaultDestination);
    this.etouristStateService.checked$.subscribe((status) => {
      if (status) {
        this.accomodationSubscriber$ = this.eTuristService.getAccomodation().pipe(
          catchError((error) => {
            showMessage(PopupType.Danger, error.error);
            return of([]);
          })
        );

        this.accomodationSubscriber$.subscribe((accomodationList) => {
          const selectedAccomodation = accomodationList?.find((t) => t.code === this.defaultDestination.eTuristAccommodationCode) || null;
          if (this.form && selectedAccomodation) {
            this.form.patchValue({
              eTuristAccomodationCode: selectedAccomodation?.code,
            });
          }
        });
      }
    });
  }

  initForm(initDestination: GroupTravelDestination) {
    const {
      start = this.form ? this.form.controls["start"].value : "",
      end = this.form ? this.form.controls["end"].value : "",
      notes = this.form ? this.form.controls["notes"].value : "",
      days = this.form ? this.form.controls["days"].value : "",
      nights = this.form ? this.form.controls["nights"].value : "",
      service = this.form ? this.form.controls["service"].value : null,
      destination = this.form ? this.form.controls["destination"].value : null,
      hotel = this.form ? this.form.controls["hotel"].value : "",
      eTuristAccommodationCode = this.form ? this.form.controls["eTuristAccommodationCode"].value : "",
      eTuristAccommodationLabel = this.form ? this.form.controls["eTuristAccommodationLabel"].value : "",
    } = initDestination || {};
    const country = destination?.country || null;
    this.form = this.fb.group(
      {
        start: [start, Validators.required],
        end: [end, Validators.required],
        days: [days, Validators.required],
        nights: [nights, Validators.required],
        notes: [notes],
        service: [service, { updateOn: "blur" }],
        destination: [destination, { validators: [Validators.required], updateOn: "blur" }],
        hotel: [hotel, { validators: [Validators.required], updateOn: "blur" }],
        country: [country],
        eTuristAccommodationCode: [eTuristAccommodationCode, { updateOn: "blur" }],
        eTuristAccommodationLabel: [eTuristAccommodationLabel],
      },
      { validators: [typeOrAccommodationCodeRequired] }
    );
  }

  setDaysNights() {
    const start = this.form.controls["start"].value;
    const end = this.form.controls["end"].value;
    if (!start || !end) return "";
    if (start.includes(".") || end.includes(".")) {
      return start.includes(".") ? start : end;
    } else if (start && end) {
      const formatDate = (date: string) => moment(`${date.slice(0, 2)}.${date.slice(2, 4)}.${date.slice(4)}`, "DD.MM.YYYY");
      const diff = Math.abs(formatDate(start).diff(formatDate(end), "days"));

      this.form.controls["days"].setValue(diff);
      this.form.controls["nights"].setValue(diff - 1);
    }
  }

  checkIfValid(): boolean {
    return this.form.valid;
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      return;
    }
    const { start, end, days, nights, notes, service, destination, hotel, eTuristAccommodationCode, eTuristAccommodationLabel } = this.form.getRawValue();
    const editedDestination = {
      ...this.defaultDestination,
      start,
      end,
      days,
      nights,
      notes,
      service,
      destination,
      hotel,
      eTuristAccommodationCode,
      eTuristAccommodationLabel,
    };
    this.submitDestination.emit(editedDestination);
  }
  onAccommodationChange(event: ETouristCodebookEntry) {
    this.form.patchValue({
      eTuristAccommodationLabel: event.name,
    });
  }
}
