import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ETouristService } from "../shared/services/etourist.service";
import { ETouristStateService } from "../domain/sales/services/etourist-state.service";
import { showMessage } from "../shared/utils/toast.popup";
import { PopupType } from "../shared/enums/popup-types";

@Component({
  moduleId: module.id,
  selector: "app-etourist-checker",
  templateUrl: "./etourist-checker.html",
})
export class EtouristCheckerComponent implements OnInit {
  public checked = false;
  public message!: string;
  constructor(public eTouristService: ETouristService, public router: Router, public translate: TranslateService, private etouristStateService: ETouristStateService) {}

  ngOnInit() {
    this.checkAvailability();
  }

  navigateToETuristTable(event: Event) {
    event.preventDefault();
    if (this.checked) {
      this.router.navigate(["/dashboard/e-tourist"]);
    }
  }

  checkAvailability() {
    const subscription = this.eTouristService.checkAvailability().subscribe({
      next: (check) => {
        subscription.unsubscribe();
        this.checked = check;
        this.message = check ? this.translate.instant("e-turist-success") : this.translate.instant("e-turist-error", { link: "https://turistagent.com/uputstva" });
        this.etouristStateService.setChecked(this.checked);
      },
      error: (err) => {
        subscription.unsubscribe();
        showMessage(PopupType.Danger, err.error);
      },
    });
  }
}
