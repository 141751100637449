import * as moment from "moment";
import { Subscription } from "rxjs";
import { Sale } from "../models/sale";
import { ModalComponent } from "angular-custom-modal";
import { CashRegister } from "../models/cash-register";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesService } from "../services/sales.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Component, OnInit, ViewChild } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { NgDataTableComponent } from "@bhplugin/ng-datatable";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RouterLinkHelper } from "src/app/shared/utils/router-link-helper";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  selector: "app-daily-sale",
  templateUrl: "./daily-sale.component.html",
})
export class DailySaleComponent implements OnInit {
  @ViewChild("viewTaskModal") viewTaskModal!: ModalComponent;
  @ViewChild("addSaleInCashRegister") addSaleModal!: ModalComponent;
  @ViewChild("addCashRegisterModal") addCashRegisterModal!: ModalComponent;
  @ViewChild("datatable") datatable!: NgDataTableComponent;
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  searchTask = "";
  plans: Sale[] = [];
  checked!: boolean;
  selectedTab!: number | undefined;
  isShowTaskMenu = true;
  public form!: FormGroup;
  plansWithoutCashRegister: Sale[] = [];
  cashRegisters: CashRegister[] = [];
  selectedCashRegisters: CashRegister | undefined;
  cashRegisterId: number | undefined;
  loading = false;
  display!: boolean;
  fromDate!: string;
  toDate!: string;
  public pageSize = 10;
  public totalRows = 1;
  public originalPlans: Sale[] = [];
  private langChangeSubscription!: Subscription;
  public cols: DatatableColumn[] = [];
  public currentId!: number;

  constructor(public fb: FormBuilder, public saleService: SalesService, public translate: TranslateService, private router: Router, public route: ActivatedRoute) {}
  pagerWithoutCashRegister = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    totalCount: 2,
    sortColumn: undefined,
    sortDirection: undefined,
  };
  ngOnInit() {
    this.getCashRegisters();
    this.initForm();
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.getColumns();
    });

    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.cashRegisterId = +params["id"];
        this.getCashRegisterById(this.cashRegisterId);
        this.getColumns();
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      processedAt: ["", Validators.required],
      processedFrom: ["", Validators.required],
    });
  }

  searchTasks() {
    if (this.searchTask) {
      const searchTerm = this.searchTask.toLowerCase();
      this.plans = this.originalPlans.filter((plan) => plan.customerFullName.toLowerCase().includes(searchTerm));
    } else {
      this.plans = this.originalPlans;
    }
  }

  tabChanged(id: number) {
    this.selectedCashRegisters = undefined;
    this.cashRegisterId = id;
    this.selectedTab = undefined;
    this.isShowTaskMenu = false;
    this.router.navigate([`/dashboard/daily-sale`, id]);
    this.getCashRegisterById(id);
  }
  toggleTaskMenu() {
    this.isShowTaskMenu = !this.isShowTaskMenu;
  }

  openModal() {
    this.isShowTaskMenu = false;
    this.addCashRegisterModal.open();
  }
  closeDocument() {
    this.addCashRegisterModal.close();
  }

  saveCashRegister() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.createCashRegister();
  }

  fetchCashRegister(): CashRegister {
    const { processedAt, processedFrom } = this.form.value;

    const formattedProcessedAt = DateTimeFormatter.formatDateToISO(processedAt);
    const formattedProcessedFrom = DateTimeFormatter.formatDateToISO(processedFrom);

    return {
      processedAt: formattedProcessedAt,
      processedFrom: formattedProcessedFrom,
    } as CashRegister;
  }
  getCashRegisters() {
    const cashRegisterSubscription = this.saleService.getCashRegisters().subscribe((res) => {
      this.cashRegisters = res.map((c) => DateTimeFormatter.formatCashRegisterDates(c));
      cashRegisterSubscription.unsubscribe();
    });
  }

  getCashRegisterById(id: number | undefined) {
    this.isShowTaskMenu = false;
    if (id) {
      this.saleService.getCashRegisterById(id).subscribe((res) => {
        this.plans = res.plans;
        this.originalPlans = res.plans;
        this.cashRegisterId = id;
        this.selectedCashRegisters = DateTimeFormatter.formatCashRegisterDates(res);
        this.selectedTab = id;

        this.fromDate = this.formatDate(res.processedFrom);
        this.toDate = this.formatDate(res.processedAt);

        this.display = true;
      });
    }
  }

  formatDate(date: string): string {
    return moment(date).format("DD-MM-YYYY");
  }

  createCashRegister() {
    const createSub = this.saleService.createCashRegister(this.fetchCashRegister()).subscribe({
      next: (res) => {
        createSub.unsubscribe();
        this.addSaleModal.close();
        this.getCashRegisters();
        this.router.navigate([`/dashboard/daily-sale`, res.id]);

        showMessage(PopupType.Success, this.translate.instant("cash_register.created_successfully"));
      },
      error: (error) => {
        createSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }
  handleFromDateChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.fromDate = input.value;
    this.getPlansWithoutCashRegister();
  }

  handleToDateChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.toDate = input.value;
    this.getPlansWithoutCashRegister();
  }

  openSaleModal() {
    if (this.selectedTab === undefined) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_cash_register"));
      return;
    }
    this.addSaleModal.open();
    this.getPlansWithoutCashRegister();
  }

  addPlansInCashRegister() {
    if (this.datatable.getSelectedRows().length === 0) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_plans"));
      return;
    }
    if (this.selectedTab === undefined) return;
    const updateSub = this.saleService.updateCashRegister(this.updateCashRegister(), this.selectedTab).subscribe({
      next: () => {
        updateSub.unsubscribe();
        this.addSaleModal.close();
        this.getCashRegisterById(this.selectedTab);
        showMessage(PopupType.Success, this.translate.instant("cash_register.added_plans"));

        this.getCashRegisters();
      },
      error: (error) => {
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  getPlansWithoutCashRegister() {
    const { currentPage, pageSize, sortColumn, sortDirection } = this.pagerWithoutCashRegister;
    const from = moment(this.fromDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const to = moment(this.toDate, "DD-MM-YYYY").format("YYYY-MM-DD");

    this.saleService.getPlansWithoutCashRegister(currentPage, pageSize, "WithoutCashRegister", from, to, sortColumn, sortDirection).subscribe((res) => {
      this.plansWithoutCashRegister = res.body;
      const paginationHeader = res.headers.get("X-Pagination");
      if (paginationHeader) {
        const pagination = JSON.parse(paginationHeader || "");
        this.pagerWithoutCashRegister.currentPage = pagination.CurrentPage;
        this.pagerWithoutCashRegister.pageSize = pagination.PageSize;
        this.pagerWithoutCashRegister.totalPages = pagination.TotalPages ?? 0;
        this.pagerWithoutCashRegister.totalCount = pagination.TotalCount ?? 0;
      }
    });
  }
  public changeServer(data: any) {
    this.pagerWithoutCashRegister.sortColumn = data.sort_column;
    this.pagerWithoutCashRegister.sortDirection = data.sort_direction;
    this.pagerWithoutCashRegister.pageSize = data.pagesize;
    this.pagerWithoutCashRegister.currentPage = data.current_page;
    this.pagerWithoutCashRegister.totalCount = data.total_count;
    this.getPlansWithoutCashRegister();
  }

  updateCashRegister() {
    const cashRegister = this.cashRegisters.find((cashRegister) => cashRegister.id === this.selectedTab);
    const planIDs = this.datatable.getSelectedRows().map((plan: { id: number }) => plan.id);
    const updatedAt = new Date().toISOString();
    const processedAt = cashRegister ? DateTimeFormatter.formatDateToISO(cashRegister.processedAt) : "";
    const processedFrom = cashRegister ? DateTimeFormatter.formatDateToISO(cashRegister.processedFrom) : "";

    const createdAt = new Date().toISOString();

    const updatedCashRegister = { processedAt, processedFrom, createdAt, updatedAt, planIDs };
    return updatedCashRegister;
  }

  openDocument() {
    if (this.cashRegisterId === undefined) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_cash_register"));
      return;
    }
    this.router.navigate([`/dashboard/daily-sale/document/${this.cashRegisterId}`]);
  }
  getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("cash_register.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "customer", title: this.translate.instant("cash_register.customer"), hide: false } as DatatableColumn,
      { field: "purchased", title: this.translate.instant("cash_register.purchased"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("cash_register.status"), hide: false } as DatatableColumn,
      { field: "sold", title: this.translate.instant("cash_register.sold"), hide: false } as DatatableColumn,
      { field: "advance_payment", title: this.translate.instant("cash_register.advance_payment"), hide: false } as DatatableColumn,
      { field: "total_price", title: this.translate.instant("cash_register.total_price"), hide: false } as DatatableColumn,
      { field: "payment_deadline", title: this.translate.instant("cash_register.payment_deadline"), hide: false } as DatatableColumn,
    ];
  }
  getIconAndTooltip(entityName: string, entityType?: string) {
    return RouterLinkHelper.getIconAndTooltip(entityName, entityType);
  }
  getRouterLink(value: string): string | undefined {
    return RouterLinkHelper.getRouterLink(value);
  }
  onMouseEnter(id: number): void {
    this.currentId = id;
  }
}
