import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-arrow-right",
  template: `
    <ng-template #template>
      <div style="margin-bottom: 20px;">
        <div style="display: flex; justify-content: center; margin-bottom: 4px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="black" viewBox="0 0 16 16" style="border: 1px solid black; border-radius: 4px; padding: 2px;">
            <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V4.707l2.646 2.647a.5.5 0 0 0 .708-.708l-3.5-3.5a.5.5 0 0 0-.708 0l-3.5 3.5a.5.5 0 1 0 .708.708L7.5 4.707V11.5A.5.5 0 0 0 8 12z" />
          </svg>
        </div>

        <div style="display: flex; justify-content: center; align-items: center; gap: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="black" viewBox="0 0 16 16" style="border: 1px solid black; border-radius: 4px; padding: 2px; transform: rotate(-90deg);">
            <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V4.707l2.646 2.647a.5.5 0 0 0 .708-.708l-3.5-3.5a.5.5 0 0 0-.708 0l-3.5 3.5a.5.5 0 1 0 .708.708L7.5 4.707V11.5A.5.5 0 0 0 8 12z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="black" viewBox="0 0 16 16" style="border: 1px solid black; border-radius: 4px; padding: 2px;">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1-.5.5v6.293L4.854 8.354a.5.5 0 1 1-.708.708l3.5 3.5a.5.5 0 0 1 .708 0l3.5-3.5a.5.5 0 1 1-.708-.708L8.5 10.793V4.5A.5.5 0 0 1 8 4z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="black" viewBox="0 0 16 16" style="border: 1px solid black; border-radius: 4px; padding: 2px; transform: rotate(90deg);">
            <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V4.707l2.646 2.647a.5.5 0 0 0 .708-.708l-3.5-3.5a.5.5 0 0 0-.708 0l-3.5 3.5a.5.5 0 1 0 .708.708L7.5 4.707V11.5A.5.5 0 0 0 8 12z" />
          </svg>
        </div>
      </div>
    </ng-template>
  `,
})
export class IconArrowRightComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
