import { AuditEntry } from "./models/auditentry";
import { Component, Input, OnInit, Output } from "@angular/core";
import { TravelsService } from "src/app/domain/travels/services/travels.service";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-file-history",
  templateUrl: "./file-history.component.html",
})
export class FileHistoryComponent implements OnInit {
  @Input() isShowingSidebar = false;
  @Input() entityname: any = "";
  @Input() id!: number;
  showFileHistory = false;
  showCustomizer = false;


  travel!: string;
  auditEntries: AuditEntry[] = [];
  @Output() toggleSidebar = new EventEmitter<void>();

  constructor(private travelservice: TravelsService) { }

  ngOnInit(): void {
    this.fetchAuditLogs();
  }
  fetchAuditLogs() {
    const getSubscriber = this.travelservice.getAuditLogs(this.entityname, this.id).subscribe((data) => {
      getSubscriber.unsubscribe();
      this.auditEntries = data;
    });
  }
  toggleFileHistory() {
    this.showFileHistory = !this.showFileHistory;
  }
  toggleSidebarAction() {
    this.toggleSidebar.emit(); 
  }
  parseModifiedColumns(entry: AuditEntry): any[] {
    try {
      if (!entry.modifiedColumns) {
        return [];
      }
      const sanitizedString = entry.modifiedColumns.trim();
      if (sanitizedString === "") {
        return [];
      }
      return JSON.parse(sanitizedString);
    } catch (e) {
      return [];
    }
  }
}
