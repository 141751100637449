import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { VoucherService } from "../services/voucher.service";
import { Voucher } from "src/app/domain/travels/models/voucher";
import { TravelsService } from "src/app/domain/travels/services/travels.service";
import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from "@angular/core";
import { PrintPreviewService } from "./../../../../shared/services/print-preview.service";
@Component({
  selector: "app-voucher",
  templateUrl: "./voucher.component.html",
})
export class VoucherComponent implements OnInit {
  constructor(private route: ActivatedRoute, public travelService: TravelsService, public router: Router, public voucherService: VoucherService, public printPreviewService: PrintPreviewService) {}
  @Input() voucher!: Voucher;
  @Input() editUrl!: string;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  isShowingSidebar = false;
  public entityname = "vouchers";
  public id!: number;
  travelId!: number;
  isLoading = false;
  today: string = new Date().toLocaleDateString("sr-RS");

  sendVoucher() {
    this.modal.open();
  }
  ngOnInit() {
    this.id = this.voucher.id;
  }

  navigateToEditVoucher() {
    this.router.navigate([this.editUrl]);
  }

  printVoucher() {
    this.isLoading = true;
    const subscription = this.voucherService.generateVoucherHtml(this.voucher.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }

  finishSending() {
    this.modal.close();
  }
  closeModal()
	{
		this.modal.close();
	}
	
}
