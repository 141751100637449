import { Subscription } from "rxjs";
import { Sale } from "../models/sale";
import { Payment } from "../models/payment";
import { TranslateService } from "@ngx-translate/core";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-payment-form",
  templateUrl: "./payment.form.component.html",
  animations: [toggleAnimation],
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  public localPayment!: Payment;
  public form!: FormGroup;
  public isSubmitForm = false;
  public formattedDate!: string;
  PicklistType = PicklistType;
  public paymentTypeSubscription: Subscription | undefined;

  private _max!: number | null;

  @Input() set max(value: number | null) {
    this._max = value;
  }

  get max(): number | null {
    return this._max;
  }

  @Input() payment!: Payment;
  @Output() submitSale: EventEmitter<Sale> = new EventEmitter<Sale>();

  constructor(public fb: FormBuilder, public translate: TranslateService) {}

  initForm() {
    const { payment, paymentDate, paymentType } = this.localPayment || {};
    this.form = this.fb.group({
      payment: [payment, { validators: [Validators.required, this.max ? Validators.max(this.max) : null], updateOn: "blur" }],
      paymentDate: [paymentDate, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
      paymentType: [paymentType, Validators.required],
      paymentTypeId: [null],
    });
    this.paymentTypeSubscription = this.form.get("paymentType")?.valueChanges.subscribe((value) => {
      const paymentTypeIdControl = this.form.get("paymentTypeId");
      if (paymentTypeIdControl) {
        paymentTypeIdControl.setValue(value?.id || null);
        paymentTypeIdControl.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy() {
    if (this.paymentTypeSubscription) {
      this.paymentTypeSubscription.unsubscribe();
    }
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getPayment(): Payment {
    return { ...this.localPayment, ...this.form.getRawValue() };
  }

  submitForm() {
    this.isSubmitForm = true;
    this.submitSale.emit(this.form.getRawValue());
  }

  ngOnInit(): void {
    this.localPayment = DateTimeFormatter.formatPaymentDates(this.payment);
    this.initForm();
  }
}
