<form [formGroup]="form">
  <div [ngClass]="form.controls['senderEmail'].touched ? (form.controls['senderEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="senderEmail">{{ "office-branch.senderEmail" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_senderEmail' | translate }}" class="form-input" id="senderEmail" formControlName="senderEmail" />
    <ng-container *ngIf="form.controls['senderEmail'].touched && form.controls['senderEmail'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_senderEmail" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['senderName'].touched ? (form.controls['senderName'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="senderName">{{ "office-branch.senderName" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_senderName' | translate }}" class="form-input" id="senderName" formControlName="senderName" />
    <ng-container *ngIf="form.controls['senderName'].touched && form.controls['senderName'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_senderName" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['adminEmail'].touched ? (form.controls['adminEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="adminEmail">{{ "office-branch.adminEmail" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_adminEmail' | translate }}" class="form-input" id="adminEmail" formControlName="adminEmail" />
    <ng-container *ngIf="form.controls['adminEmail'].touched && form.controls['adminEmail'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_adminEmail" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="password">{{ "office-branch.password" | translate }}</label>
    <input type="password" autocomplete="off" placeholder="{{ 'office-branch.enter_password' | translate }}" class="form-input" id="password" formControlName="password" />
    <ng-container *ngIf="form.controls['password'].touched && form.controls['password'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_password" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <button type="button" (click)="closeModal()" class="btn btn-primary mt-3 w-full">
    {{ "eventdetails.close" | translate }}
  </button>
</form>
