import { Store } from "@ngrx/store";
import { User } from "../auth/model/user";
import { Injectable } from "@angular/core";
import { $themeConfig } from "../theme.config";
import { AppState } from "../store/index.selector";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, map, Observable } from "rxjs";
import { LayoutState } from "../store/layout/layout.reducer";
import { Language } from "../store/language/language.reducer";
import { selectFeatureUser } from "../store/auth/auth.selector";
import { setLanguage } from "../store/language/language.actions";
import { setUser, updateSettings } from "../store/auth/auth.actions";
import { UserService } from "../domain/user-profile/service/user.service";
import { selectLanguageList, selectLocale } from "../store/language/language.selector";
import { toggleAnimation, toggleLayout, toggleMenu, toggleNavbar, toggleRTL, toggleSemidark, toggleTheme } from "../store/layout/layout.actions";

@Injectable()
export class AppService {
  public user!: User;
  public key = "language";
  layoutData!: LayoutState;
  public settings: any = {};
  locale$!: Observable<string>;
  public user$!: Observable<User | undefined>;
  languageList$!: Observable<{ code: string; name: string }[]>;

  constructor(public translate: TranslateService, public store: Store<AppState>, public userService: UserService) {
    this.initStore();
    this.initStoreData();
    this.loadUserSettings();
  }

  initStore() {
    this.locale$ = this.store.select(selectLocale);
    this.languageList$ = this.store.select(selectLanguageList);
  }

  loadUserSettings() {
    this.user$ = this.store.select(selectFeatureUser).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev?.settings) === JSON.stringify(curr?.settings)),
      map((user) => {
        if (user) {
          this.user = user;
          this.settings = user.settings ? JSON.parse(user.settings) : {};
        }
        return user;
      })
    );
    this.user$.subscribe();
  }
  initStoreData() {
    this.store.subscribe((d: AppState) => {
      this.layoutData = d.layout;
    });
    const layoutSettings = this.settings?.layout || {};
    this.store.dispatch(toggleTheme({ theme: layoutSettings?.theme || $themeConfig.theme }));
    this.store.dispatch(toggleMenu({ menu: layoutSettings?.menu || $themeConfig.menu }));
    this.store.dispatch(toggleLayout({ layout: layoutSettings?.layout || $themeConfig.layout }));
    this.store.dispatch(toggleRTL({ rtlClass: layoutSettings?.rtlClass || $themeConfig.rtlClass }));
    this.store.dispatch(toggleAnimation({ animation: layoutSettings?.animation || $themeConfig.animation }));
    this.store.dispatch(toggleNavbar({ navbar: layoutSettings?.navbar || $themeConfig.navbar }));
    this.store.dispatch(toggleSemidark({ semidark: layoutSettings?.semidark || $themeConfig.semidark }));

    let val = localStorage.getItem("i18n_locale");
    val = val || $themeConfig.locale;
    this.languageList$.subscribe((list) => {
      const item = list.find((item: Language) => item.code === val);
      if (item) {
        this.toggleLanguage(item);
      }
    });
    val = localStorage.getItem("user");
    if (val && val != "undefined") {
      const user = JSON.parse(val);
      this.store.dispatch(setUser({ user }));
    }
  }

  toggleLanguage(item: Language) {
    const lang = item;
    this.translate.use(lang.code);
    this.store.dispatch(setLanguage({ locale: lang.code }));
    const updatedSettings = { ...this.settings };
    updatedSettings[this.key] = {
      ...updatedSettings[this.key],
      code: lang.code,
    };
    if (this.user) {
      this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
      this.userService.updateUser(this.user.id, { ...this.user, settings: JSON.stringify(updatedSettings) });
    }
    return lang;
  }

  changeAnimation(type = "add") {
    if (this.layoutData.animation) {
      const ele = document.querySelector(".animation");
      if (type === "add") {
        ele?.classList.add("animate__animated");
        ele?.classList.add(this.layoutData.animation);
      } else {
        ele?.classList.remove("animate__animated");
        ele?.classList.remove(this.layoutData.animation);
      }
    }
  }
}
