import * as moment from "moment";
import { Subscription } from "rxjs";
import { Sale } from "../../sales/models/sale";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { Transfer } from "../../transfers/models/transfer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { GroupTravelDestination } from "./../models/group-travel";
import { ModalService } from "src/app/shared/services/modal.service";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { GroupTravelsService } from "../services/group-travels.service";
import { LocalStorageService } from "src/app/service/local-storage.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-group-travel-plan",
  templateUrl: "./group-travel-plan.component.html",
})
export class GroupTravelPlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("nextStepButton") nextStepButton!: ElementRef;

  activeTab = 1;
  customer!: Customer;
  passengers!: Customer[];
  sale!: Sale;
  groupTravelId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;
  currentPlan: any;
  isLoading = false;
  hasGroupTravelData = false;
  public isEditingPassanger = false;
  entityDetails: string | undefined;
  localNeto!: number;
  localBruto!: number;
  routeSubscription!: Subscription;

  constructor(
    public router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private groupTravelService: GroupTravelsService,
    private modalService: ModalService,
    public validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id !== null) {
        this.groupTravelId = parseInt(id);
        const subscription = this.groupTravelService.getGroupTravelData(this.groupTravelId).subscribe((res) => {
          subscription.unsubscribe();
          this.localBruto = res.bruto;
          this.localNeto = res.neto;
        });
      }
    });

    this.hasGroupTravelData = !!this.localStorageService.loadDataFromLocalStorage<string>("groupTravelPlan");
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
      if (this.localBruto && this.localNeto) {
        const numberOfPassangers = this.passangerList.getPassangers().length;
        this.sales.prefillPrices(this.localBruto * numberOfPassangers, this.localNeto * numberOfPassangers);
      }
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
    this.saveGroupTravelPlanToLocalStorage();
  }
  private focusNextStepButton() {
    setTimeout(() => {
      this.nextStepButton.nativeElement.focus();
    }, 0);
  }
  handleCustomerSubmit() {
    this.focusNextStepButton();
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }
  ngAfterViewInit() {
    this.loadGroupTravelPlanFromLocalStorage();
    this.cdr.detectChanges();
  }
  saveGroupTravelPlanToLocalStorage() {
    const groupTravelData = {
      customer: this.customer,
      passengers: this.passangerList.getPassangers(),
    };
    this.localStorageService.updateKey("groupTravelPlan", JSON.stringify(groupTravelData));
  }
  loadGroupTravelPlanFromLocalStorage() {
    const savedData = this.localStorageService.loadDataFromLocalStorage<string>("groupTravelPlan");
    if (savedData) {
      const groupTravelData = JSON.parse(savedData);
      if (groupTravelData) {
        if (this.passangerList) {
          this.passangerList.setPassangers(groupTravelData.passengers);
        }
        this.customer = groupTravelData.customer;
      }
    }
  }

  toggleButton(isEditing: boolean) {
    this.isEditingPassanger = isEditing;
  }
  async submitGroupTravel() {
    try {
      if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
        this.passengers = this.passangerList.getPassangers();
        this.sale = this.sales.getSale();
        this.isLoading = true;
        const subscription = this.groupTravelService.addGroupTravelPassanger(this.fetchGroupTravelData()).subscribe({
          next: (r) => {
            this.isLoading = false;
            this.currentPlan = r.plan;
            const mainDestination = r.groupTravel?.groupTravelDestinations?.find((d: GroupTravelDestination) => d.isMainDestination) || null;
            if (mainDestination && mainDestination.destination && mainDestination.hotel && mainDestination.start) {
              this.entityDetails =
                this.translate.instant("paymentsEntities.groupTravel") +
                ": " +
                mainDestination.destination.description +
                ", " +
                mainDestination.hotel.name +
                ", " +
                moment(mainDestination.start).format("DD.MM.YYYY");
            }

            this.sendInvoice();
            showMessage(PopupType.Success, this.translate.instant("group-travels_info.added_passangers_successfully"));
            subscription.unsubscribe();
            this.localStorageService.removeSpecificItem("groupTravelPlan");
          },
          error: (exception) => {
            this.isLoading = false;
            const { error } = exception;
            if (error.includes("limit")) showMessage(PopupType.Danger, error);
            else showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
            subscription.unsubscribe();
          },
        });
      }
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
      return;
    }
  }

  fetchGroupTravelData() {
    return {
      groupTravelId: this.groupTravelId,
      passangers: this.passengers.map((p: Customer) => {
        return { customerId: p.id, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer };
      }),
      plan: { ...this.sale, dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"), customerId: this.customer.id },
      customerId: this.customer.id,
    };
  }
  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send.avans"),
      message: this.translate.instant("confirm.send_avans"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.currentPlan,
      navigateToRoute: `dashboard/sales`,
      isOnlyAvans: true,
      activeTab: undefined,
      paymentId: undefined,
      entityDetails: this.entityDetails,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
  clearGroupTravelData(): void {
    this.localStorageService.removeSpecificItem("groupTravelPlan");
    this.router.navigate(["dashboard/sales"]);
  }
}
