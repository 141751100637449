import { Store } from "@ngrx/store";
import { Travel } from "../models/travel";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TravelsService } from "./../services/travels.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { UserService } from "../../user-profile/service/user.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  templateUrl: "./travels.table.component.html",
  animations: [toggleAnimation],
})
export class TravelsTableComponent extends BaseTableComponent<Travel> implements OnInit {
  public travels: Travel[] = [];
  public currentId!: number;

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  constructor(
    private modalService: ModalService,
    private positioningService: PositioningService,
    public override translate: TranslateService,
    store: Store<AppState>,
    tableService: TableService,
    public travelService: TravelsService,
    public override userService: UserService
  ) {
    super(store, tableService, "travels/paginated", userService, translate);
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getColumns();
  }

  getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("hotel_info.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("travels.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "isInEturist", title: this.translate.instant("travels.is_in_eturist"), hide: false, sort: false } as DatatableColumn,
      { field: "customerId", title: this.translate.instant("customer"), hide: false } as DatatableColumn,
      { field: "destination", title: this.translate.instant("travels.destination"), hide: false, sort: false } as DatatableColumn,
      { field: "departureId", title: this.translate.instant("travels.departure"), hide: false } as DatatableColumn,
      { field: "numberOfPassangers", title: this.translate.instant("travels.number_of_passengers"), hide: false, sort: false } as DatatableColumn,
      { field: "start", title: this.translate.instant("travels.departure_date"), hide: false } as DatatableColumn,
      { field: "service", title: this.translate.instant("travels.service"), hide: true, sort: false } as DatatableColumn,
      { field: "periodOfTime", title: this.translate.instant("travels.period_of_time"), hide: true, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("travels.created_date"), hide: true } as DatatableColumn,
      { field: "profit", title: this.translate.instant("travels.profit"), hide: true, sort: false } as DatatableColumn,
      { field: "dept", title: this.translate.instant("travels.dept"), hide: true, sort: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("travels.details"), hide: false, sort: false } as DatatableColumn,
    ];
  }

  public exportCols = [
    { field: "planId", title: this.translate.instant("travel_export.planId"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("travel_export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("travel_export.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPassangers", title: this.translate.instant("travel_export.numberOfPassangers"), hide: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("travel_export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("travel_export.destination"), hide: false } as DatatableColumn,
    { field: "startDate", title: this.translate.instant("travel_export.startDate"), hide: false } as DatatableColumn,
    { field: "endDate", title: this.translate.instant("travel_export.endDate"), hide: true, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("travel_export.bruto"), hide: true, sort: false } as DatatableColumn,
    { field: "serviceType", title: this.translate.instant("travel_export.serviceType"), hide: true, sort: false } as DatatableColumn,
    { field: "eTuristStatus", title: this.translate.instant("travel_export.eTuristStatus"), hide: true, sort: false } as DatatableColumn,
    { field: "planStatus", title: this.translate.instant("travel_export.planStatus"), hide: true, sort: false } as DatatableColumn,
    { field: "dateOfSale", title: this.translate.instant("travel_export.dateOfSale"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("travel_export.user"), hide: true } as DatatableColumn,
  ];

  calculateProfit(neto: number, bruto: number): number {
    if (neto && bruto) return bruto - neto;
    return 0;
  }

  calculateDept(bruto: number, payments: number, avans: number): number {
    if (bruto && payments) {
      return bruto - payments - avans;
    }
    return 0;
  }

  async deleteTravel(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.travelService.deleteTravel(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.fetchItems(this.getPaginationParams());
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
          this.fetchItems(this.getPaginationParams());
        },
      });
    }
  }
  onMouseEnter(id: number): void {
    this.currentId = id;
  }
}
