import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { User, PasswordResetRequest } from "src/app/auth/model/user";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  updatePassword(data: any) {
    return this.http.post(`${this.apiUrl}/api/users/updatepassword`, data);
  }

  getUserData() {
    return this.http.get<User>(`${this.apiUrl}/api/users/me`);
  }

  updateUser(id: number, data: any) {
    return this.http.put<User>(`${this.apiUrl}/api/users/patch/${id}`, data);
  }

  forgotPasswordRequest(email: string) {
    const currentOrigin = window.location.origin;
    return this.http.post(`${this.apiUrl}/api/users/forgotpassword`, { email: email, url: currentOrigin });
  }

  validateResetToken(token: string) {
    return this.http.get(`${this.apiUrl}/api/users/validatetoken?token=${token}`);
  }

  resetPasswordRequest(data: PasswordResetRequest) {
    return this.http.post(`${this.apiUrl}/api/users/resetpassword`, data);
  }
  uploadProfileImage(userId: number, formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/users/upload-profile-image/${userId}`, formData);
  }
}
