import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesService } from "../../services/sales.service";
import { DailySaleDocumentToUpdateDTO } from "../../models/sale";

@Component({
  selector: "app-daily-sale-edit",
  templateUrl: "./daily-sale.edit.component.html",
})
export class DailySaleEditComponent implements OnInit {
  constructor(private salesService: SalesService, private route: ActivatedRoute, private router: Router) {}
  dailySaleDocument!: DailySaleDocumentToUpdateDTO;
  cashRegisterId!: number;
  totalPrice = 0;
  all = 0;
  isLoading = false;

  denomination = [5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];
  extraRows = [
    { voucherName: "", description: "", price: null, invoiceNumber: "" },
    { voucherName: "", description: "", price: null, invoiceNumber: "" },
  ];
  ngOnInit(): void {
    this.isLoading = true;

    this.route.paramMap.subscribe((params) => {
      const idParam = params.get("id");
      this.cashRegisterId = idParam ? parseInt(idParam) : 0;
      this.getDailySaleDocument(this.cashRegisterId);
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  getDailySaleDocument(id: number) {
    const subscription = this.salesService.getDailySaleDocument(id).subscribe((response) => {
      this.dailySaleDocument = response;
      this.dailySaleDocument.items.forEach((item) => {
        if (item.price !== null && item.price !== undefined) {
          this.totalPrice += item.price;
        }
        subscription.unsubscribe();
      });
      this.dailySaleDocument.denomination = this.distributeAmountByDenomination(this.totalPrice);
    });
  }
  distributeAmountByDenomination(amount: number): number[] {
    const result: number[] = [];
    this.denomination.forEach((denom) => {
      const count = Math.floor(amount / denom);
      result.push(count);
      amount -= count * denom;
    });
    return result;
  }

  saveDocument() {
    this.extraRows.forEach((extraRow) => {
      if (extraRow.voucherName && extraRow.price !== null) {
        this.dailySaleDocument.items.push(extraRow);
      }
    });
    this.all = 0;
    this.dailySaleDocument.denomination.map((denom, index) => {
      this.all += denom * this.denomination[index];
    });

    const subscription = this.salesService.updateDailySaleDocument(this.cashRegisterId, this.dailySaleDocument).subscribe({
      next: () => {
        this.router.navigate([`/dashboard/daily-sale/document/${this.cashRegisterId}`]);
        subscription.unsubscribe();
      },
      error: (error) => {
        console.error("Error updating daily sale document:", error);
      },
    });
  }

  calculate() {
    this.all = 0;
    this.dailySaleDocument.denomination.map((denom, index) => {
      this.all += denom * this.denomination[index];
    });
  }
}
