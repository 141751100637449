import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AppState } from "../store/index.selector";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnDestroy, OnInit, Type } from "@angular/core";
import { toggleSidebar } from "../store/layout/layout.actions";
import { selectSemidark } from "../store/layout/layout.selector";
import { slideDownUp, toggleAnimation } from "../shared/animations";
import { LocalStorageService } from "../service/local-storage.service";
import { IconMenuBusComponent } from "../shared/icon/menu/icon-menu-bus";
import { IconMenuHotelComponent } from "../shared/icon/menu/icon-menu-hotel";
import { IconMenuPlaneComponent } from "../shared/icon/menu/icon-menu-plane";
import { IconMenuTravelComponent } from "../shared/icon/menu/icon-menu-travel";
import { IconMenuCompanyComponent } from "../shared/icon/menu/icon-menu-company";
import { IconMenuWorkersComponent } from "../shared/icon/menu/icon-menu-workers";
import { IconMenuTransferComponent } from "../shared/icon/menu/icon-menu-transfer";
import { IconMenuCustomerComponent } from "../shared/icon/menu/icon-menu-customer";
import { IconMenuOrganizerComponent } from "../shared/icon/menu/icon-menu-organizer";
import { IconMenuStatisticsComponent } from "../shared/icon/menu/icon-menu-statistics";
import { IconMenuDailySalesComponent } from "../shared/icon/menu/icon-menu-daily-sales";
import { IconMenuDestinationComponent } from "../shared/icon/menu/icon-menu-destination";
import { IconMenuGroupTravelComponent } from "../shared/icon/menu/icon-menu-group-travel";
import { IconMenuDashboardDinoComponent } from "../shared/icon/menu/icon-menu-dashboard-dino";
import { IconMenuOtherServicesComponent } from "../shared/icon/menu/icon-menu-other-services";
import { IconMenuDailyWorkerSalesComponent } from "../shared/icon/menu/icon-menu-dailyworker-sales";
import { UserRole } from "../shared/enums/user-roles";
import { IconCalendarComponent } from "../shared/icon/icon-calendar";

@Component({
  moduleId: module.id,
  selector: "app-sidebar",
  templateUrl: "./sidebar.html",
  animations: [slideDownUp, toggleAnimation],
})
export class SidebarComponent implements OnInit, OnDestroy {
  active = false;
  activeDropdown: string[] = [];
  parentDropdown = "";
  semidark$!: Observable<boolean>;
  isFlightTicketData!: boolean;
  isBusTicketData!: boolean;
  isGroupTravelInProgress!: boolean;
  isOtherServiceInProgress!: boolean;
  isTransferInProgress!: boolean;
  isTravelDataInProgress!: boolean;
  private subscriptions: Subscription[] = [];
  inProgressCount = 0;
  sidebarSections!: SidebarSection[];
	isMobile = false;


  constructor(public translate: TranslateService, public storeData: Store<AppState>, public router: Router, private localStorageService: LocalStorageService) {
    this.semidark$ = this.storeData.select(selectSemidark);
  }

  isMobileView(): boolean {
    return window.innerWidth < 767;
  }

  initializeSidebarSections() {

    this.sidebarSections = [
      {
        titleTranslationKey: "",
        items: [
          { path: "/dashboard/sales", translationKey: "dashboard", iconComponent: IconMenuDashboardDinoComponent },
          { path: "/dashboard/statistics", translationKey: "stats", iconComponent: IconMenuStatisticsComponent, packageRequirment: "statisticsAccounting" },
					...(this.isMobile
            ? [{ path: "/dashboard/calendar", translationKey: "calendar", iconComponent: IconCalendarComponent }]
            : []),

						...(this.isMobile
							? [{ path: "/dashboard/e-tourist", translationKey: "e-tourist", iconComponent: IconCalendarComponent }]
							: []),
					],	
      },
      {
        titleTranslationKey: "entities",
        items: [
          { path: "/dashboard/tickets/flight", translationKey: "plane_tickets", iconComponent: IconMenuPlaneComponent },
          { path: "/dashboard/travels", translationKey: "travel", iconComponent: IconMenuTravelComponent },
          { path: "/dashboard/tickets/bus", translationKey: "bus_tickets", iconComponent: IconMenuBusComponent },
          { path: "/dashboard/group-travels", translationKey: "group_travel", iconComponent: IconMenuGroupTravelComponent },
          { path: "/dashboard/other-services", translationKey: "sidebar.other_services", iconComponent: IconMenuOtherServicesComponent },
          { path: "/dashboard/transfers", translationKey: "sidebar.transfer", iconComponent: IconMenuTransferComponent },
        ],
      },
      {
        titleTranslationKey: "clients_and_colaborators",
        items: [
          { path: "/dashboard/customers", translationKey: "clients", iconComponent: IconMenuCustomerComponent },
          { path: "/dashboard/hotels", translationKey: "hotels", iconComponent: IconMenuHotelComponent },
          { path: "/dashboard/companies", translationKey: "companies", iconComponent: IconMenuCompanyComponent },
          { path: "/dashboard/organizers", translationKey: "organizers", iconComponent: IconMenuOrganizerComponent },
          { path: "/dashboard/destinations", translationKey: "destinations", iconComponent: IconMenuDestinationComponent },
          { path: "/dashboard/workers", translationKey: "workers", iconComponent: IconMenuWorkersComponent, roles: [UserRole.Admin, UserRole.OfficeBranchManager] },
        ],
      },
      {
        titleTranslationKey: "book_keeping",
        items: [
          { path: "/dashboard/daily-sale", translationKey: "daily_sales", iconComponent: IconMenuDailySalesComponent, packageRequirment: "creationOfDailySalesOrders" },
          { path: "/dashboard/daily_actions", translationKey: "daily_actions", iconComponent: IconMenuDailySalesComponent, packageRequirment: "creationOfDailySummaries", roles: [UserRole.Agent] },
          { path: "/dashboard/daily_actions_worker", translationKey: "daily_action_worker", iconComponent: IconMenuDailyWorkerSalesComponent, roles: [UserRole.Admin, UserRole.OfficeBranchManager] },
        ],
      },
    ];
  }

  ngOnInit() {
		this.checkViewport();
		window.addEventListener("resize", this.checkViewport.bind(this));
    this.initializeSidebarSections();
    this.setActiveDropdown();
    this.checkInProgress();
    this.subscriptions.push(
      this.localStorageService.getKeyStatus("flightTicketData").subscribe((status) => {
        this.isFlightTicketData = status;
        this.checkInProgress();
      })
    );
    this.subscriptions.push(
      this.localStorageService.getKeyStatus("busTicketData").subscribe((status) => {
        this.isBusTicketData = status;
        this.checkInProgress();
      })
    );
		
    this.subscriptions.push(
      this.localStorageService.getKeyStatus("groupTravelPlan").subscribe((status) => {
        this.isGroupTravelInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("otherService").subscribe((status) => {
        this.isOtherServiceInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("transferPlan").subscribe((status) => {
        this.isTransferInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("travelData").subscribe((status) => {
        this.isTravelDataInProgress = status;
        this.checkInProgress();
      })
    );
  }


	ngOnDestroy() {
    window.removeEventListener("resize", this.checkViewport.bind(this));
  }

	checkViewport() {
    this.isMobile = window.innerWidth < 1024;
    this.initializeSidebarSections(); 
  }

  setActiveDropdown() {
    const selector = document.querySelector('.sidebar ul a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }

  toggleMobileMenu() {
    if (window.innerWidth < 1024) {
      this.storeData.dispatch(toggleSidebar());
    }
  }
  toggleSidebar() {
    this.storeData.dispatch(toggleSidebar());
  }

  checkInProgress() {
    this.inProgressCount = 0;
    const keys = ["flightTicketData", "busTicketData", "groupTravelPlan", "otherService", "transferPlan", "travelData"];
    keys.forEach((key) => {
      if (this.localStorageService.getKeyStatus(key).getValue()) {
        this.inProgressCount++;
      }
    });
  }
}

interface SidebarItem {
  path: string;
  translationKey: string;
  iconComponent: Type<any>;
  roles?: string[];
  packageRequirment?: string | undefined;
}

interface SidebarSection {
  titleTranslationKey: string;
  items: SidebarItem[];
}
