<div>
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
    <button type="button" class="btn btn-info gap-2" (click)="sendVoucher()">
      <icon-send />
      {{ "vaucher.send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printVoucher()" [disabled]="isLoading">
      <icon-printer />
      {{ "vaucher.print" | translate }}
    </button>

    <button type="button" class="btn btn-warning gap-2" (click)="navigateToEditVoucher()">
      <icon-edit />
      {{ "vaucher.edit" | translate }}
    </button>

    <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  </div>
  <app-voucher-template [voucher]="voucher"></app-voucher-template>
  <modal #modal class="modal-top custom-modal" [closeOnOutsideClick]="false">
    <ng-template #modalBody>
			<button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
				<icon-x class="h-5 w-5"></icon-x>
			</button>
      <app-file-send
        [title]="'voucher_sending_form_title' | translate"
        fileType="voucher"
        [id]="voucher.id"
        [passangers]="voucher.passengers"
        [sendDocumentFn]="voucherService.sendVoucher(this.voucher.id)"
        (notifyWhenSend)="finishSending()"
      >
        <h2>{{ "contract.passangers_information" | translate }}:</h2>
        <table class="mb-4 w-full table-auto border border-gray-400">
          <thead>
            <tr class="border border-gray-300 bg-gray-100">
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let passenger of voucher.passengers">
              <td class="border px-4 py-2">{{ passenger.name }}</td>
              <td class="border px-4 py-2">{{ passenger.surname }}</td>
              <td class="border px-4 py-2">{{ passenger.email }}</td>
            </tr>
          </tbody>
        </table>
        <h2 class="mb-2">{{ "contract.traveller_information" | translate }} :</h2>
        <div class="flex">
          <table class="w-full table-auto border border-gray-400">
            <tbody>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.hotel" | translate }}: {{ this.voucher.hotelLabel }}</td>
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.agent" | translate }}: {{ this.voucher.agent }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.checkin" | translate }}: {{ this.voucher.checkin | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.checkout" | translate }}: {{ this.voucher.checkout | date : "dd.MM.yyyy" }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.createdate" | translate }}: {{ this.voucher.createDate | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.rooms" | translate }}: {{ this.voucher.roomService }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.createbyfullname" | translate }}: {{ this.voucher.createdByFullName }}</td>
                <td class="border-b border-gray-300 p-2">{{ "vouchersendemail.transport" | translate }}: {{ this.voucher.transport }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-file-send>
    </ng-template>
  </modal>
</div>
