import { TranslateService } from "@ngx-translate/core";
import { FormControl, ValidatorFn } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TravelsService } from "../../travels/services/travels.service";
import { ModalComponent } from "angular-custom-modal";

@Component({
  selector: "app-travel-e-turist-pdf-viewer",
  templateUrl: "./travel-e-turist-pdf-viewer.component.html",
})
export class TravelETuristPdfViewerComponent implements OnInit {
  @Input() fileUrl!: string;
  @Input() id!: string;
  @Input() email: string[] = [];
  @Input() modalPdf!: ModalComponent;

  emails: string[] = [];

  @Output() eventClose = new EventEmitter<void>();

  loading = false;

  public emailValidator(control: FormControl) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(control.value)) {
      return {
        invalidEmail: true,
      };
    }
    return null;
  }

  public validators = [this.emailValidator as ValidatorFn];

  public errorMessages = {
    invalidEmail: this.translate.instant("contractsendemail.email_invalid_format"),
  };

  constructor(public translate: TranslateService, public travelService: TravelsService) {}

  send() {
    const validEmails = this.emails.filter((email) => email && email.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

    if (validEmails.length === 0) {
      showMessage(PopupType.Warning, this.translate.instant("popup.emails_are_missing_and_or_form_is_invalid"));
      return;
    }

    this.loading = true;

    fetch(this.fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const formData = new FormData();
        validEmails.forEach((email) => formData.append("emails", email));
        formData.append("file", blob, "guarantee.pdf");

        const subscription = this.travelService.sendGuarantee(formData).subscribe(() => {
          this.loading = false;
          this.eventClose.emit();
          this.modalPdf.close();
          subscription.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("popup.guarantee_sent_successfully"));
        });
      })
      .catch((error) => {
        this.loading = false;
        console.error("Error fetching file:", error);
      });
  }

  ngOnInit() {
    this.emails = this.email ? [...this.email.filter((e) => e && e.trim() !== "")] : [];
  }
}
