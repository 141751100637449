import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DailyDebtStatistics } from "../models/dailydebt";
import { StatisticsYear } from "../models/statisticsyear";
import { StatisticsYearlyDebt } from "../models/yearlydebt";
import { MonthlyDebtStatistics } from "../models/monthlydebt";
import { HttpClient, HttpParams } from "@angular/common/http";
import { StatisticsDaily } from "../models/statisticmoneydaily";
import { OverallStatistics } from "../models/overallstatistics";
import { DailyEarningsStatistics } from "../models/dailyearnings";
import { StatisticsYearlyEarnings } from "../models/yearlyearning";
import { BaseHttpService } from "src/app/service/base.http.service";
import { StatisticsMonthly } from "../models/statisticmoneymonthly";
import { MonthlyEarningsStatistics } from "../models/monthlyearnings";
import { YearlyPopularDestinations } from "../models/yearlypopulardestinations";
import { MostPopularCountriesByMonth } from "../models/mostpopularcountriesbymonth";
import { StatisticOrganizer, StatisticOrganizerProfit } from "../models/statisticorganizer";

@Injectable({
  providedIn: "root",
})
export class StatisticsMoneyService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getStatistics() {
    return this.http.get<StatisticsYear>(`${this.apiUrl}/api/Statistics/money/stats`);
  }

  getYearlyPopularDestinations(year?: number, numberOfDestinations?: number) {
    return this.http.get<YearlyPopularDestinations>(`${this.apiUrl}/api/Statistics/yearlyPopularDestinations?year=${year}&numberOfDestinations=${numberOfDestinations}`);
  }
  getOrganizerStatistics(year?: number, numberOfOrganizers?: number) {
    return this.http.get<StatisticOrganizer[]>(`${this.apiUrl}/api/Statistics/organizers/stats?year=${year}&numberOfOrganizers=${numberOfOrganizers}`);
  }
  getStatisticsOrganizerProfit(year?: number, numberOfOrganizers?: number) {
    return this.http.get<StatisticOrganizerProfit[]>(`${this.apiUrl}/api/Statistics/organizers-profit/stats?year=${year}&numberOfOrganizers=${numberOfOrganizers}`);
  }
  getOverallStatistics(from: string, to?: string) {
    const apiUrl = `${this.apiUrl}/api/Statistics/overall`;
    let params = new HttpParams().set("from", from);
    if (to) {
      params = params.set("to", to);
    }
    return this.http.get<OverallStatistics>(apiUrl, { params });
  }

  getYearlyEarningStatistics() {
    return this.http.get<StatisticsYearlyEarnings>(`${this.apiUrl}/api/Statistics/money/stats/earnings`);
  }
  getYearlyDebtStatistics() {
    return this.http.get<StatisticsYearlyDebt>(`${this.apiUrl}/api/Statistics/money/stats/debt`);
  }
  getEarningsMonthStatistics(year?: number) {
    return this.http.get<MonthlyEarningsStatistics>(`${this.apiUrl}/api/Statistics/earnings/monthly?year=${year}`);
  }
  getDebtMonthStatistics(year?: number) {
    return this.http.get<MonthlyDebtStatistics>(`${this.apiUrl}/api/Statistics/debt/monthly?year=${year}`);
  }
  getMoneyMonthStatistics(year?: number): Observable<StatisticsMonthly> {
    return this.http.get<StatisticsMonthly>(`${this.apiUrl}/api/Statistics/money/stats/monthly?year=${year}`);
  }

  getEarningsDailyStatistics(year?: number, month?: number) {
    return this.http.get<DailyEarningsStatistics>(`${this.apiUrl}/api/Statistics/earnings/daily?year=${year}&month=${month}`);
  }
  getDebtDailyStatistics(year?: number, month?: number) {
    return this.http.get<DailyDebtStatistics>(`${this.apiUrl}/api/Statistics/dept/daily?year=${year}&month=${month}`);
  }
  getMoneyDailyStatistics(year?: number, month?: number) {
    return this.http.get<StatisticsDaily>(`${this.apiUrl}/api/Statistics/money/stats/daily?year=${year}&month=${month}`);
  }
  getMostPopularCountriesByMonth(year?: number, numberOfCountries?: number) {
    return this.http.get<MostPopularCountriesByMonth>(`${this.apiUrl}/api/Statistics/monthlyPopularCountries?year=${year}&numberOfCountries=${numberOfCountries}`);
  }
}
