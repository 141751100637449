import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-dynamic-entity-icon",
  templateUrl: "./dynamic-entity-icon.html",
})
export class DynamicEntityIconComponent {
  @Input() entityName!: string;
  @Input() type!: string | null;
}
