import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { combineLatest, Observable, of } from "rxjs";
import { AuthService } from "../auth/service/auth.service";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HasRoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles = route.data["role"].map((e: string) => e.toLowerCase());

    return combineLatest([this.authService.getUserRole(), this.authService.getUserId()]).pipe(
      map(([role, userId]) => {
        const normalizedRole = role.toLowerCase();
        const normalizedRoles = roles.map((r: string) => r.toLowerCase());
        if (normalizedRoles.includes(normalizedRole)) {
          return true;
        } else {
          const redirectTo = (route.data["redirectTo"] as string) || "/404";
          this.router.navigate([`${redirectTo}/${userId}`]);
          return false;
        }
      }),
      catchError((error) => {
        console.error("Error in combineLatest:", error);
        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }
}
