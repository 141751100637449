import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { UserAuth } from "../model/user.auth";
import { HttpClient } from "@angular/common/http";
import { PackageConfig, User } from "../model/user";
import { catchError, map, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { setUser } from "src/app/store/auth/auth.actions";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { BaseHttpService } from "src/app/service/base.http.service";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { UserRole } from "src/app/shared/enums/user-roles";

@Injectable()
export class AuthService extends BaseHttpService {
  private defaultPackageConfig: PackageConfig = {
    agentQuantityCap: 100,
    branchesQuantityCap: 100,
    calendarAccounting: true,
    creationOfDailySalesOrders: true,
    creationOfDailySummaries: true,
    customerSupport: true,
    eTouristAccounting: true,
    eTuristEntriesQuantityCap: 0,
    organizersHotelsCommunication: true,
    passengersDatabase: true,
    salesEntriesQuantityCap: 0,
    sendingOfAnnouncementDocuments: true,
    servicesDatabase: true,
    statisticsAccounting: true,
    statisticsDatabase: true,
    subAgentsCommunication: true,
    travelersCommunication: true,
  };

  constructor(private http: HttpClient, public store: Store<AppState>, public translate: TranslateService) {
    super();
    this.loadUserFromLocalStorage();
  }

  authenticate(form: UserAuth): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/api/Users/login`, form).pipe(tap((response) => this.handleAuthentication(response)));
  }

  saveUser(user: User): void {
    const userInfo = {
      id: user.id,
      username: user.username,
      email: user.email,
      imageUrl: user.imageUrl,
      role: user.role || UserRole.Agent,
      settings: user.settings,
      token: user.token,
      isOfficeBranchManager: user.isOfficeBranchManager,
      updatedAt: new Date(user.updatedAt),
      createdAt: new Date(user.createdAt),
      eTouristAccessToken: user.eTouristAccessToken,
      eTouristRefreshToken: user.eTouristRefreshToken,
      officeBranch: user.officeBranch,
      name: user.name,
      surname: user.surname,
      uniqueMasterCitizenNumber: user.uniqueMasterCitizenNumber,
    } as User;
    localStorage.setItem("user", JSON.stringify(userInfo));
    this.store.dispatch(setUser({ user: userInfo }));
  }

  handleAuthentication(response: User): void {
    if (response && response.token) {
      const user = {
        id: response.id,
        username: response.username,
        email: response.email,
        imageUrl: response.imageUrl,
        role: response.role || UserRole.Agent,
        settings: response.settings,
        isOfficeBranchManager: response.isOfficeBranchManager,
        token: response.token,
        updatedAt: new Date(response.updatedAt),
        createdAt: new Date(response.createdAt),
        eTouristAccessToken: response.eTouristAccessToken,
        eTouristRefreshToken: response.eTouristRefreshToken,
        officeBranch: response.officeBranch,
        name: response.name,
        surname: response.surname,
        uniqueMasterCitizenNumber: response.uniqueMasterCitizenNumber,
      } as User;

      this.saveUser(user);
      if (!response.officeBranch) {
        showMessage(PopupType.Danger, this.translate.instant("please_add_officeBranch"));
      }
    }
  }

  loadUserFromLocalStorage(): void {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (this.isTokenExpired(parsedUser.token)) {
        localStorage.removeItem("user");
      } else {
        this.store.dispatch(setUser({ user: parsedUser }));
      }
    }
  }

  isTokenExpired(token: string): boolean {
    if (!token) {
      return false;
    }
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  getUserRole(): Observable<string> {
    return this.store.select(selectFeatureUser).pipe(
      map((user) => {
        return this.isOfficeBranchManager(user as User);
      }),
      catchError((error) => {
        console.error("Error fetching user role", error);
        return of(UserRole.Agent); // Return "Agent" in case of error
      })
    );
  }

  getUserId(): Observable<number> {
    return this.store.select(selectFeatureUser).pipe(
      map((user) => {
        const id = user?.id;
        return id ?? 0;
      }),
      catchError((error) => {
        console.error("Error fetching userId", error);
        return of(0); // Return 0 in case of error
      })
    );
  }

  getPackageConfiguration(): Observable<PackageConfig> {
    return this.store.select((state) => {
      return state.auth.user?.packageConfiguration || this.defaultPackageConfig;
    });
  }

  isOfficeBranchManager(user: User) {
    const { role, isOfficeBranchManager } = user as User;
    if (role.toLowerCase() === UserRole.Admin.toLowerCase()) {
      return isOfficeBranchManager ? UserRole.OfficeBranchManager : UserRole.Admin;
    }
    return UserRole.Agent;
  }
}
