<div class="panel ng-tns-c35-2 mb-4 mt-4 h-full sm:col-span-3 xl:col-span-2">
  <div class="ng-tns-c35-2 mb-5 flex items-start justify-between">
    <h5 class="ng-tns-c35-2 text-lg font-semibold dark:text-white-light">{{'worker.sale_category' | translate}}</h5>
  </div>
  <div class="ng-tns-c35-2 flex flex-col space-y-5">
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
          <div class="flex h-6 w-6 items-center justify-center">
            <icon-menu-plane></icon-menu-plane>
          </div>
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{'worker.tickets_sold' | translate}} - {{tickets}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage1}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #46b196"
              [ngStyle]="{ 'width.%': percentage1}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
          <div class="flex h-6 w-6 items-center justify-center">
            <icon-menu-travel></icon-menu-travel>
          </div>
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.travels_sold' | translate}} - {{travels}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage2}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #46b196"
              [ngStyle]="{'width.%': percentage2}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
          <div class="flex h-6 w-6 items-center justify-center">
            <icon-menu-transfer></icon-menu-transfer>
          </div>
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.transfers_sold' | translate}} - {{transfer}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage3}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #46b196"
              [ngStyle]="{'width.%': percentage3}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
          <div class="flex h-6 w-6 items-center justify-center">
            <icon-menu-group-travel></icon-menu-group-travel>
          </div>
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.group_travels_sold' | translate}} - {{grouptravel}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage4}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #46b196"
              [ngStyle]="{'width.%': percentage4}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
          <div class="flex h-6 w-6 items-center justify-center">
            <icon-menu-other-services></icon-menu-other-services>
          </div>
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.other_services_sold' | translate}} - {{otherServices}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage5}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #46b196"
              [ngStyle]="{'width.%': percentage5}"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
