import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";
import { GroupTravelPlanQuickView } from "../../group-travels/models/group-travel-plan-quick";

@Component({
  selector: "app-group-travel-plan-quickview",
  templateUrl: "./group-travel-plan-quickview.component.html",
})
export class GroupTravelPlanQuickviewComponent implements OnInit, OnDestroy {
  public data!: GroupTravelPlanQuickView;
  @Input() id!: number | null;
  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService, private groupTravelService: GroupTravelsService) {}

  ngOnInit(): void {
    if (this.id) {
      this.fetchGroupTravelPlanQuickView(this.id);
    }
  }
  fetchGroupTravelPlanQuickView(id: number) {
    this.groupTravelService
      .getGroupTravelsPlanQuickView(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((travelPlan) => {
        this.data = travelPlan;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
