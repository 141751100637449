<div class="panel h-full w-full">
  <div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid h-screen place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
    <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
  </div>
  <app-overall-statistics [overallStatistics]="overallStatistics"></app-overall-statistics>

  <div class="flex flex-wrap justify-between gap-2 pt-3 sm:flex-nowrap">
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.purchasesMade" name="purchases_made"></app-statistics>
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.travelsSold" name="travels_sold"></app-statistics>
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.ticketsSold" name="tickets_sold"></app-statistics>
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.transferPlansSold" name="transfers_sold"></app-statistics>
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.groupTravelPlansSold" name="group_travels_sold"></app-statistics>
    <app-statistics class="flex-grow basis-full sm:basis-1/5" [value]="overallStatistics?.otherServicesSold" name="other_services_sold"></app-statistics>
  </div>

  <div class="mb-5 mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a] print:hidden">
    <a
      *ngFor="let tab of tabs"
      href="javascript:;"
      class="-mb-[1px] flex items-center border-transparent p-5 py-3 !outline-none transition duration-300 hover:border-b hover:!border-secondary hover:text-secondary"
      [ngClass]="{
        'border-b-2 border-secondary text-secondary': activeTab === tab.id,
        'tab-active': activeTab === tab.id,
        'hover:border-b hover:border-secondary hover:text-secondary': activeTab !== tab.id
      }"
      (click)="onTabChange(tab.id)"
    >
      {{ tab.label | translate }}
    </a>
  </div>

  <div class="container" *ngIf="activeTab === 'Overall'">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "overall.statistics" | translate }}</h5>
        </div>
        <app-statistics-chart [simpleColumnStacked]="statisticsData"></app-statistics-chart>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">
            {{ "overall.yearlyEarnings" | translate }}
          </h5>
        </div>
        <app-yearly-earnings-chart [simpleColumnStackedEarnings]="yearlyearning"></app-yearly-earnings-chart>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">
            {{ "overall.yearlyDebt" | translate }}
          </h5>
        </div>
        <app-yearly-debt-chart [simpleColumnStackedDebt]="yearlydebt"></app-yearly-debt-chart>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="activeTab === 'Monthlystatistics'">
    <div class="align-items-center flex">
      <div class="mx-2">
        <select class="btn btn-dark" [(ngModel)]="selectedYear" (ngModelChange)="selectYear($event)">
          <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "monthlyStats.earnings" | translate }}</h5>
        </div>
        <app-montly-money-chart [montlyearningmoney]="monthlystatistic"></app-montly-money-chart>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "monthlyStats.earningMoney" | translate }}</h5>
        </div>
        <app-montly-earnings-chart [montlyearningEarnings]="montlyearning"></app-montly-earnings-chart>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "monthlyStats.debt" | translate }}</h5>
        </div>
        <app-montly-debt-chart [montlyearningDebt]="monthlydebt"></app-montly-debt-chart>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="activeTab === 'Dailystatistics'">
    <div class="align-items-center flex">
      <div class="mx-2">
        <label class="mr-2">{{ "destination-stats-by-year.selectyear" | translate }}</label>
        <select class="btn btn-dark" [(ngModel)]="selectedYear" (ngModelChange)="selectYear($event)">
          <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
        </select>
      </div>

      <div class="mx-2">
        <label class="mr-2">{{ "destination-stats-by-year.selectmonth" | translate }}</label>
        <select class="btn btn-dark" [(ngModel)]="selectedMonth" (ngModelChange)="selectMonth($event)">
          <option *ngFor="let month of availableMonths" [value]="month.value">{{ month.label }}</option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "dailyStats.moneyChart" | translate }}</h5>
        </div>
        <app-daily-money-chart [dailymoneystatistic]="dailystatistic"></app-daily-money-chart>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "dailyStats.earningsChart" | translate }}</h5>
        </div>
        <app-daily-earnings-chart [dailyearningstatistics]="dailyearnings"></app-daily-earnings-chart>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div>
          <h5 class="font-bold">{{ "dailyStats.debtChart" | translate }}</h5>
        </div>
        <app-daily-debt-chart [dailydebtstatistics]="dailyeardebt"></app-daily-debt-chart>
      </div>
    </div>
  </div>
  <div class="mb-5 mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a] print:hidden">
    <a
      *ngFor="let tab of tabsCountryDestination"
      href="javascript:;"
      class="-mb-[1px] flex items-center border-transparent p-5 py-3 !outline-none transition duration-300 hover:border-b hover:!border-secondary hover:text-secondary"
      [ngClass]="{
        'border-b-2 border-secondary text-secondary': activeTabs === tab.id,
        'tab-active': activeTabs === tab.id,
        'hover:border-b hover:border-secondary hover:text-secondary': activeTabs !== tab.id
      }"
      (click)="onTabChanges(tab.id)"
    >
      {{ tab.label | translate }}
    </a>
  </div>
  <div class="align-items-center flex">
    <div class="mx-2">
      <label class="mr-2">{{ "destination-stats-by-year.selectyear" | translate }}</label>
      <select class="btn btn-dark" [(ngModel)]="selectedYear" (ngModelChange)="selectYear($event)">
        <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
      </select>
    </div>
    <div class="mx-2">
      <label class="mr-2">{{ "destination-stats-by-year.destination_number" | translate }}</label>
      <select class="btn btn-dark" [(ngModel)]="numberOfDestinations" (ngModelChange)="onNumberOfDestinationsChange($event)">
        <option [value]="10">10</option>
        <option [value]="15">15</option>
        <option [value]="20">20</option>
      </select>
    </div>
  </div>

  <div class="container" *ngIf="activeTabs === 'yearly'">
    <div *ngIf="yearlypopulardestination" class="mt-8">
      <app-chart-statistics
        [popularDestinationsByTicket]="yearlypopulardestination.popularDestinationsByTicket"
        [popularDestinationsByTravel]="yearlypopulardestination.popularDestinationsByTravel"
        [popularDestinationsByGroupTravel]="yearlypopulardestination.popularDestinationsByGroupTravel"
        [popularDestinationsByTransfer]="yearlypopulardestination.popularDestinationsByTransfer"
      >
      </app-chart-statistics>
    </div>
  </div>
  <div class="container" *ngIf="activeTabs === 'monthly'">
    <div *ngIf="mostPopularCountriesByMonth" class="mt-8">
      <app-country-chart
        [popularCountriesByTicket]="mostPopularCountriesByMonth.popularCountriesByTicket"
        [popularCountriesByTravel]="mostPopularCountriesByMonth.popularCountriesByTravel"
        [popularCountriesByGroupTravel]="mostPopularCountriesByMonth.popularCountriesByGroupTravel"
        [popularCountriesByTransfer]="mostPopularCountriesByMonth.popularCountriesByTransfer"
      >
      </app-country-chart>
    </div>
  </div>

  <div class="align-items-center flex">
    <div class="mx-2">
      <label class="mr-2">{{ "destination-stats-by-year.selectyear" | translate }}</label>
      <select class="btn btn-dark" [(ngModel)]="selectedYear" (ngModelChange)="selectYear($event)">
        <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
      </select>
    </div>
    <div class="mx-2">
      <label class="mr-2">{{ "organizerstatistics.numberOfOrganizers" | translate }}</label>
      <select class="btn btn-dark" [(ngModel)]="numberOfOrganizers" (ngModelChange)="onNumberOfOrganizersChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="15">15</option>
        <option [value]="20">20</option>
      </select>
    </div>
  </div>

  <div class="chart-container container grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
    <div>
      <h3 class="text-center">{{ "organizerstatistics.organizernumberofarrangements" | translate }}</h3>
      <app-organizer-statistics [statisticsOrganizer]="statisticsOrganizer"></app-organizer-statistics>
    </div>
    <div>
      <h3 class="text-center">{{ "organizerstatistics.organizerprofit" | translate }}</h3>
      <app-organizer-statistics-profit [statisticsOrganizerProfit]="statisticsOrganizerProfit"></app-organizer-statistics-profit>
    </div>
  </div>
</div>
