<div class="modal-overlay">
  <div class="modal-content">
    <div class="mx-auto w-full">
      <div class="mb-2 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
        <button type="button" class="btn btn-primary gap-2" (click)="printInvoice()" [disabled]="isLoading">
          <icon-printer />
        </button>
        <button type="button" class="btn btn-danger gap-2" (click)="dismiss()" [disabled]="isLoading">
          <icon-x />
        </button>
      </div>
      <div class="panel" id="payment">
        <div class="d-flex justify-content-between align-items-center">
          <div class="flex items-center justify-between">
            <div *ngIf="plan.createdBy.officeBranch.logoImage">
              <img [src]="plan.createdBy.officeBranch.logoImage.url" alt="Logo" class="w-25 h-10" />
            </div>
            <div class="text-white-dark">
              <div class="w-full">
                <div class="space-y-1">
                  <div class="flex w-full max-w-full items-center justify-end space-x-1">
                    <span class="max-w-full truncate">{{ plan.createdBy.officeBranch.fullAddress }}</span>
                    <icon-home />
                  </div>
                  <div class="flex w-full max-w-full items-center justify-end space-x-1">
                    <span class="max-w-full truncate">{{ plan.createdBy.officeBranch.phoneNumber }} </span>
                    <icon-phone />
                  </div>
                  <div class="flex w-full max-w-full items-center justify-end space-x-1">
                    <span class="max-w-full truncate">{{ plan.createdBy.officeBranch.email }} </span>
                    <icon-mail />
                  </div>
                  <div class="flex w-full max-w-full items-center justify-end space-x-1">
                    <span class="max-w-full truncate"> https://{{ plan.createdBy.officeBranch.siteURL }} </span>
                    <icon-chrome />
                  </div>
                  <div class="flex w-full max-w-full items-center justify-end space-x-1">
                    <span class="max-w-full truncate"> {{ "giro-account" | translate }}: {{ plan.createdBy.officeBranch.bankAccount }}, PIB:{{ plan.createdBy.officeBranch.pib }} </span>
                    <icon-zip-file />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 flex flex-col">
          <div class="mt-2 flex flex-row items-center">{{ entityDetails }}</div>
          <div class="flex flex-col lg:flex-row lg:items-center">
            <div class="w-2/3 lg:w-[250px]">{{ "paymentdetails.customerFullName" | translate }}: {{ plan.customer.name + " " + plan.customer.surname }}</div>
          </div>
          <div class="flex items-center">
            <div class="mr-2">{{ "paymentdetails.email" | translate }}:</div>
            <div>{{ plan.customer.email }}</div>
          </div>

          <div class="mt-2 flex flex-col space-y-2">
            <div class="flex flex-row items-center" *ngIf="isOnlyAvans">
              <label class="w-1/2 font-semibold">{{ "paymentdetails.total" | translate }}: </label>
              <div class="w-1/2 text-right">{{ plan.bruto || 0 | currency : "EUR" }}</div>
            </div>
            <div class="flex flex-row items-center" *ngIf="plan.status === false">
              <label class="w-1/2 font-semibold">{{ "paymentdetails.avans" | translate }}:</label>
              <div class="w-1/2 text-right">{{ plan.avans || 0 | currency : "EUR" }}</div>
            </div>
            <div class="flex flex-col justify-between lg:flex-row lg:items-center" *ngIf="paymentId">
              <label for="number" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2"> {{ "paymentdetails.previouspayments" | translate }}: </label>
              <div class="flex w-full flex-col lg:w-[250px]" name="invoiceNo">
                <div *ngIf="paymentDocument && paymentDocument.paymentDetails">
                  <ng-container *ngIf="parsePaymentDetails(paymentDocument.paymentDetails).length > 0; else noPayments">
                    <div *ngFor="let payment of parsePaymentDetails(paymentDocument.paymentDetails)">
                      <div class="flex-1 overflow-hidden text-ellipsis text-right">{{ payment.PayedAt }} -- {{ payment.Amount | currency : "EUR" }}</div>
                    </div>
                  </ng-container>
                  <ng-template #noPayments>
                    <div class="text-right">{{ "paymentdetails.noPreviousPayments" | translate }}</div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center" *ngIf="paymentId">
              <label class="w-1/2 font-semibold">{{ "paymentdetails.currentPayment" | translate }}:</label>
              <div class="w-1/2 text-right">{{ paymentDocument?.currentPayment | currency : "EUR" }}</div>
            </div>
            <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
            <div class="flex flex-row items-center text-red-500" *ngIf="isOnlyAvans && plan.avans !== null">
              <label class="w-1/2 font-semibold">{{ "paymentdetails.toBePaid" | translate }}:</label>
              <div class="w-1/2 text-right">
                {{ paymentId ? (paymentDocument?.toBePaid | currency : "EUR") : ((plan.bruto || 0) - (plan.avans || 0) | currency : "EUR") }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="flex justify-between">
            <p>
              <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
            </p>
            <p *ngIf="!paymentId">
              <strong>{{ "paymentdetails.agent" | translate }}:</strong>
              {{ plan.createdBy.name + " " + plan.createdBy.surname }}
            </p>
            <p *ngIf="paymentId">
              <strong>{{ "paymentdetails.agent" | translate }}:</strong>
              {{ paymentDocument?.createdByFullName }}
            </p>
          </div>
        </div>
        <app-file-send
          [title]="'invoicesendemail' | translate"
          fileType="invoice"
          [id]="plan.id"
          [paymentId]="paymentId"
          [passangers]="[plan.customer]"
          [sendDocumentFn]="paymentService.sendPayment(this.plan.id)"
          messageRs="Racun se nalazi u dodatku maila"
          messageEn="Invoice is in the attachement of the mail"
          (notifyWhenSend)="finishSending()"
          [isOnlyAvans]="isOnlyAvans"
        >
        </app-file-send>
      </div>
    </div>
  </div>
</div>
