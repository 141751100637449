<div>
  <div class="mb-6 flex flex-wrap items-center gap-4 print:hidden">
    <div class="mb-4">
      <tag-input
        [(ngModel)]="emails"
        [modelAsStrings]="true"
        [placeholder]="'contractsendemail.email_to_send' | translate"
        [secondaryPlaceholder]="'contractsendemail.email_to_send' | translate"
        [maxItems]="4"
        [blinkIfDupe]="true"
        [validators]="validators"
        [trimTags]="true"
        [errorMessages]="errorMessages"
      ></tag-input>
      <p>{{ "contractsendemail.email_hint" | translate }}</p>
    </div>
    <div class="flex items-center justify-center lg:justify-end">
      <button type="button" class="btn btn-info gap-2 rounded-md px-4 py-2 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" (click)="send()" [disabled]="loading">
        <icon-send />
        {{ "send_guarantee" | translate }}
      </button>
    </div>
  </div>
  <div>
    <iframe [src]="fileUrl | safe : 'resourceUrl'" width="100%" height="500px" frameborder="0"></iframe>
  </div>
</div>
