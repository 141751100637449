<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button type="button" class="btn btn-info gap-2" (click)="sendContract()">
    <icon-send />
    {{ "contract.send" | translate }}
  </button>
  <button type="button" class="btn btn-primary gap-2" (click)="printContract()" [disabled]="isLoading">
    <icon-printer />
    {{ "contract.print" | translate }}
  </button>
  <button type="button" class="btn btn-warning gap-2" (click)="editContract()">
    <icon-edit />
    {{ "contract.edit" | translate }}
  </button>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
</div>
<div class="panel" *ngIf="contract" id="contract">
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex-2" *ngIf="contract.officeBranch && contract.officeBranch.logoImage">
        <img [src]="contract.officeBranch.logoImage.url" alt="" class="w-15 h-20" />
      </div>
      <div class="space-y-1 text-white-dark">
        <div class="w-full" *ngIf="contract.officeBranch">
          <div class="space-y-1 text-white-dark">
            <div class="flex flex-wrap justify-end" *ngIf="contract.officeBranch.fullAddress">
              <span class="mr-2">{{ contract.officeBranch.fullAddress }} </span>
              <icon-home />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="contract.officeBranch.phoneNumber">
              <span class="mr-2">{{ contract.officeBranch.phoneNumber }} </span>
              <icon-phone />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="contract.officeBranch.email">
              <span class="mr-2">{{ contract.officeBranch.email }} </span>
              <icon-mail />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="contract.officeBranch.siteURL">
              <span class="mr-2">https://www.{{ contract.officeBranch.siteURL }} </span>
              <icon-chrome />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ "giro-account" | translate }}: {{ contract.officeBranch.bankAccount }},PIB: {{ contract.officeBranch.pib }} </span>
              <icon-zip-file />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 grid text-center text-xl font-bold">
    <p>{{ "contract.travel_contract" | translate }}</p>
    <p>{{ "contract.no" | translate }} {{ contract.uniqueId }} {{ "contract.from" | translate }} {{ contract.createDate | date : "dd.MM.yyyy" }}</p>
  </div>
  <div class="col-span-2 mt-10 flex flex-col sm:col-span-1">
    <div class="">{{ "contract.travel_organizer" | translate }}:</div>
    <div class="flex justify-between">
      <div class="font-bold">{{ getOrganizerInfo() }}</div>
    </div>
    <div class="flex justify-between">
      <div class="font-bold">{{ getResponsiblePersonInfo() }}</div>
    </div>
    <div class="font-bold">{{ getOfficeBranchInfo() }}</div>
  </div>
  <div class="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-1">
    <div class="col-span-2 mb-4 flex flex-col sm:col-span-1">
      <div class="">{{ "contract.traveller_information" | translate }}</div>
    </div>
    <div class="flex flex-col sm:flex-row">
      <table class="w-full table-auto border border-gray-400">
        <tbody>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.destination" | translate }}: {{ contract.destination }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.hotel" | translate }}: {{ contract.hotel }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.check_in" | translate }}: {{ contract.checkIn | date : "dd/MM/yyyy" }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.check_out" | translate }}: {{ contract.checkOut | date : "dd/MM/yyyy" }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.start_date" | translate }}: {{ contract.startDate | date : "dd/MM/yyyy" }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.end_date" | translate }}: {{ contract.endDate | date : "dd/MM/yyyy" }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.service" | translate }}: {{ contract.service }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.transportation" | translate }}: {{ contract.transportation }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.days" | translate }}: {{ contract.days }}, {{ "contract.nights" | translate }}: {{ contract.nights }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.rooms" | translate }}: {{ contract.rooms }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="mb-4 flex flex-col sm:col-span-1">
        <div class="">{{ "contract.passangers_information" | translate }}</div>
      </div>
      <div class="overflow-x-auto">
        <table class="mb-3 w-full table-auto border border-gray-400">
          <thead class="border-t-2">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
              <th class="border border-gray-300">{{ "contract.guests" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.name_and_surname" | translate }}</th>
              <!-- <th class="border border-gray-300 sm:col-span-1">{{ "contract.date_of_birth" | translate }}</th> -->
              <th class="border border-gray-300">{{ "contract.date_of_birth" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.email" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.address" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.contact" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let passanger of contract.passengers; index as i">
              <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
                <td class="border border-gray-300">{{ i + 1 }}</td>
                <td class="border border-gray-300">{{ passanger?.name }} {{ passanger?.surname }}</td>
                <!-- <td class="border border-gray-300 sm:col-span-1">{{ passanger?.dateOfBirth | date : "dd/MM/yyyy" }}</td> -->
                <td class="border border-gray-300">{{ passanger?.dateOfBirth | date : "dd/MM/yyyy" }}</td>
                <td class="border border-gray-300">{{ passanger?.email }}</td>
                <td class="border border-gray-300">{{ passanger?.address }}</td>
                <td class="border border-gray-300">{{ passanger?.phoneNumber }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="col-span-2 mb-4 mt-4 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div class="">{{ "contract.trip_invoice" | translate }}</div>
      </div>
      <table class="table-bordered mb-5 table">
        <tbody class="bg-gray-50">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_euro" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ contract.totalInEuroPrice | currency : "EUR " : "symbol" : "1.2-2" }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_din" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ contract.priceTotal | currency : "RSD " : "symbol" : "1.2-2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="justify-content-between flex w-full">
        <div class="w-full">{{ "contract.payment_method" | translate }}:</div>
        <div class="w-full">{{ "contract.cash" | translate }}:X</div>
        <div class="w-full">{{ "contract.check" | translate }}:</div>
        <div class="w-full">{{ "contract.other" | translate }}: x</div>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="w-full text-start">
        <p>{{ "contract.note" | translate }}:___________________________.</p>
        <p>{{ "contract.approved_deffered_payment_until" | translate }} _________________________________________.</p>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="mt-4 text-start">
        <p>
          <u>{{ "contract.statement" | translate }}</u>
        </p>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="justify-content-end mt-10 flex w-full flex-col sm:flex-row">
        <div class="w-full text-start sm:w-1/2">
          <p>{{ "contract.signature" | translate }}</p>
          <br />
          <p class="mx-auto max-w-xs sm:mx-0">_____________________________</p>
          <p>
            <strong>{{ "contract.date" | translate }}:</strong> {{ today }}
          </p>
        </div>
        <div class="mt-4 w-full text-end sm:mt-0">
          <p>
            <strong>PTTO: {{ contract.officeBranch.name }}</strong>
          </p>
          <br />
          <p>_____________________________</p>

          <p><strong>Agent:</strong> {{ contract.createdByFullName }}</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 text-start sm:grid-cols-1">
      <p class="mb-4 mt-4 text-start font-bold">{{ "contract.special_terms" | translate }}:</p>

      <p>&nbsp; {{ "contract.section_2" | translate }}</p>
      <p class="p-5">
        {{ "contract.5_%" | translate }}<br />
        {{ "contract.10_%" | translate }}<br />
        {{ "contract.20_%" | translate }}<br />
        {{ "contract.40_%" | translate }}<br />
        {{ "contract.80_%" | translate }}a<br />
        {{ "contract.90_%" | translate }}<br />
        {{ "contract.100_%" | translate }}
      </p>
      <p>&nbsp; {{ "contract.section_3" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_4" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_5" | translate }}</p>
      <br />
      &nbsp; {{ "contract.section_6" | translate }}
    </div>
  </div>
</div>

<modal #modal class="modal-top custom-modal" [closeOnOutsideClick]="false">
  <ng-template #modalBody>
		<button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
			<icon-x class="h-5 w-5"></icon-x>
		</button>
		<br>
    <ng-container *ngIf="contract">
      <app-file-send
        [title]="'contract_form_title_slip' | translate"
        fileType="contract"
        [id]="contract.id"
        [passangers]="contract.passengers"
        [sendDocumentFn]="contractService.sendContract(this.contract.id)"
        messageRs="Ugovor je u dodatku imejla. Molim vas da proverite podatke.Pri prihvatanju ugovora prihvatate Opšte uslove o putovanje agencije.MOLIMO VAS DA POTVRDITE DA SE SAGLASNI SA UGOVOROM ODGOVOROM NA OVAJ IMEJL.Molim Vas da se raspitate o uslovima putovanja i prelaska granica. Takođe proverite vaša lična dokumenta. To nije obaveza turističke agencije."
        messageEn="The contract is attached to the email. Please check the details. When the contract is accepted, you are obliged to follow the General Travel Conditions. Please answer this email if you confirm the contract.  Please inquire about travel conditions and border crossing requirements. Also, check your personal documents. This is not the responsibility of the travel agency."
        (notifyWhenSend)="finishSending()"
      >
        <table class="mb-4 w-full table-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let passenger of contract.passengers">
              <td class="border px-4 py-2">{{ passenger.name }}</td>
              <td class="border px-4 py-2">{{ passenger.surname }}</td>
              <td class="border px-4 py-2">{{ passenger.email }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex">
          <table class="w-full table-auto border border-gray-400">
            <tbody>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.createdate" | translate }}: {{ this.contract.createDate | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.pricetotal" | translate }}: {{ this.contract.totalInEuroPrice | currency : "EUR" }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.hotel" | translate }}: {{ this.contract.hotel }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.rooms" | translate }}: {{ this.contract.rooms }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.checkin" | translate }}: {{ this.contract.checkIn | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.checkout" | translate }}: {{ this.contract.checkOut | date : "dd.MM.yyyy" }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.startdate" | translate }}: {{ this.contract.startDate | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.enddate" | translate }}: {{ this.contract.endDate | date : "dd.MM.yyyy" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-file-send>
    </ng-container>
  </ng-template>
</modal>