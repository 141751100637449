import { catchError, tap } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Organizer } from "src/app/domain/organizers/models/organizers";
import { OrganizersService } from "../services/organizers.service";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-organizer-form",
  templateUrl: "./organizer-form.component.html",
})
export class OrganizerFormComponent implements OnInit, AfterViewInit {
  @Input() entityId!: number;
  @Input() editMode = false;
  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<Organizer | null>();
  @ViewChild("nameInput") nameInput!: ElementRef;
  form!: FormGroup;
  constructor(private fb: FormBuilder, public organizerService: OrganizersService) {}

  ngOnInit(): void {
    this.initForm();
    if (this.entityId) {
      this.loadOrganizerDetails();
    }
  }
  ngAfterViewInit(): void {
    if (this.nameInput) {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }
  }

  private initForm(organizer: Organizer = {} as Organizer): void {
    this.form = this.fb.group({
      name: [organizer.name, Validators.required],
      firstName: [organizer.firstName],
      lastName: [organizer.lastName],
      phone: [organizer.phone],
      licence: [organizer.licence],
      email: [organizer.email, [Validators.email]],
      address: [organizer.address],
      accountNumber: [organizer.accountNumber],
      pib: [organizer.pib],
      registrationNumber: [organizer.registrationNumber],
    });
  }
  loadOrganizerDetails(): void {
    this.organizerService
      .getOrganizer(this.entityId)
      .pipe(
        tap((organizer) => {
          this.initForm(organizer);
        }),
        catchError((err) => {
          console.error("Error loading customer data:", err);
          return [];
        })
      )
      .subscribe();
  }
  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = this.form.value;
    this.formSubmit.emit(formData);
  }

  closeModal() {
    this.formSubmit.emit(null);
  }
}
