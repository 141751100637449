import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { User } from "../auth/model/user";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { AppState } from "../store/index.selector";
import { selectFeatureUser } from "../store/auth/auth.selector";
import { NavigationDataService } from "../domain/office-branch/services/navigation-data.service";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HasBranchData implements CanActivate {
  user$!: Observable<User | undefined>;
  constructor(private router: Router, public store: Store<AppState>, private navigationService: NavigationDataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.user$ = this.store.select(selectFeatureUser);

    return this.user$.pipe(
      map((res) => {
        if (state.url === "/auth/login" || (res?.isOfficeBranchManager && this.hasCompleteBranchData(res))) {
          return true;
        }

        if (!this.hasCompleteBranchData(res) && res?.isOfficeBranchManager) {
          this.navigationService.setOfficeBranchId(res?.officeBranch?.id || null);
          return this.router.createUrlTree(["/missing-office-branch-data"]);
        }

        return true;
      }),
      catchError(() => of(this.router.createUrlTree(["/missing-office-branch-data"])))
    );
  }

  private hasCompleteBranchData(user: User | undefined): boolean {
    if (!user?.isOfficeBranchManager || !user.officeBranch) return false;

    const branch = user.officeBranch;
    return !!(
      branch.name &&
      branch.guid &&
      branch.bankAccount &&
      branch.pib &&
      branch.phoneNumber &&
      branch.email &&
      branch.siteURL &&
      branch.senderEmail &&
      branch.senderName &&
      branch.adminEmail &&
      branch.password &&
      branch.fullAddress
    );
  }
}
