<div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>

<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <ng-container *ngIf="checked && travel && !travel.eTuristRecordId; else registeredInEturist">
    <button *appHasPackageRight="['eTouristAccounting']" type="button" (click)="addRecordsToETurist()" class="btn btn-primary gap-2 p-0 px-1" ngxTippy="{{ 'account_data.record_sale' | translate }}">
      <icon-accounting-data></icon-accounting-data>
      {{ "account_data.accounting_data" | translate }}
      <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
    </button>

    <div [ngxTippy]="'higher_tier' | translate">
      <button *appHasPackageRight="['eTouristAccounting', true]" type="button" disabled="true" class="btn btn-primary gap-2 p-0 px-1">
        <icon-accounting-data></icon-accounting-data>
        {{ "account_data.accounting_data" | translate }}
        <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
      </button>
    </div>
  </ng-container>

  <ng-template #registeredInEturist>
    <ng-container *ngIf="travel && travel.eTuristRecordId">
      <app-details-dropdown-eturist [eTuristRecordId]="travel.eTuristRecordId" [plan]="travel.plan" [travelId]="travel.id" [id]="id" [emails]="emails" (recordUpdated)="refetch()">
      </app-details-dropdown-eturist>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="travel">
    <app-details-dropdown [id]="planId" [entityId]="id" [memorandumId]="travel.memorandumId || undefined" [contactDriveId]="travel.travelContactDriveId || undefined"> </app-details-dropdown>
  </ng-container>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  <button type="button" class="btn btn-primary gap-2" *ngIf="travel && travel.id">
    <a class="flex items-center justify-center" href="javascript:;" [routerLink]="'/dashboard/travels/draft/edit-travel/' + travel.id">
      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0" />
    </a>
  </button>
</div>

<div *ngIf="travel">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl font-bold">
            {{ "travel_details.lead_passanger" | translate }}
          </div>
          <div class="text-xl">{{ travel.customer.name }} {{ travel.customer.surname }}</div>
          <div class="text-[15px] italic text-white-dark" style="font-style: italic">
            {{ travel.customer.phoneNumber }}
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="flex flex-col">
            <div>{{ "ticket_details.status" | translate }}:</div>
            <ng-container *ngIf="travel.plan.status; else notPaid">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.paid" | translate }}
              </div>
            </ng-container>
            <ng-template #notPaid>
              <div class="badge mt-1 inline-flex w-auto self-start bg-danger text-white">
                {{ "ticket_details.not_paid" | translate }}
              </div>
            </ng-template>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
            <ng-container *ngIf="travel.plan.active; else notActive">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.active" | translate }}
              </div>
            </ng-container>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "ticket_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4 print:hidden">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "travel_details_label" | translate }}
            </div>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.departure_from" | translate }} :</div>
          <div *ngIf="travel.departure">{{ travel.departure.description }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.destination" | translate }} :</div>
          <div *ngIf="mainDestination">{{ mainDestination.destination?.description }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "notes" | translate }}:</div>
          <div>{{ travel.plan.notes }}</div>
        </div>
        <div class="order-last col-span-1 flex w-full flex-col py-2 sm:order-none sm:col-span-1 sm:row-span-3">
          <div>{{ "travel_details.additional_destinations" | translate }} :</div>
          <div *ngFor="let dest of additionalDestinations">
            <app-travel-accordion [content]="dest"></app-travel-accordion>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.departure_date" | translate }}:</div>
          <div>{{ travel.start | date : "dd.MM.yyyy." }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.return_date" | translate }}:</div>
          <div>{{ travel.end | date : "dd.MM.yyyy." }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.days_nights" | translate }}:</div>
          <div>{{ travel.days }}/{{ travel.nights }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.hotel" | translate }}:</div>
          <div *ngIf="mainDestination">{{ mainDestination.hotel?.name }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.type" | translate }}:</div>
          <div *ngIf="travel?.type?.title; else showTransport">
            {{ travel.type.title }}
          </div>
          <ng-template #showTransport>
            <div>
              {{ travel.eTuristTransportLabel }}
            </div>
          </ng-template>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.service_type" | translate }}:</div>
          <div *ngIf="mainDestination">
            <div *ngIf="mainDestination.service?.title; else showService">
              {{ mainDestination.service?.title }}
            </div>
            <ng-template #showService>
              <div>
                {{ mainDestination.eTuristAccommodationLabel }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <app-sale-details class="col-span-2 sm:col-span-4" [id]="planId" [hideStatus]="true" (reFetchEvent)="refetch()" [entityDetails]="entityDetails"></app-sale-details>
      </div>
    </div>
    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden" *ngIf="passangers && passangers.length > 0">
      <div class="text-[20px] text-dark">{{ "travel_details.all_passengers" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
        <ng-container *ngFor="let item of passangers; index as i">
          <div
            class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
          >
            <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
              <img [src]="item.imageUrl ? item.imageUrl : '/assets/images/auth/user-icon.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
            </div>

            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.phone_number" | translate }}:
              {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
            </div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.email" | translate }}:
              {{ item.email ? item.email : "N/A" }}
            </div>

            <ng-template #backUp> </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="small text-secondary">{{ travel.plan.createdBy.name }} {{ travel.plan.createdBy.surname }}, {{ travel.plan.createdAt | date : "dd.MM.yyyy." }}</div>
  </div>
</div>

<modal #eturistModal class="modal-top auth-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5">{{ "account_data.record_sale" | translate }}</div>
  </ng-template>
  <ng-template #modalBody *ngIf="mainDestination">
    <app-travel-e-tourist-form
      [nbsExchangeRate]="travel.plan.exchangeRate"
      [id]="id"
      [bruto]="travel.plan.bruto"
      (formSubmit)="addedRecordToEturist()"
      [eTuristTransportCode]="travel.eTuristTransportCode || ''"
      [eTuristAccommodationCode]="mainDestination.eTuristAccommodationCode || ''"
    ></app-travel-e-tourist-form>
  </ng-template>
</modal>
