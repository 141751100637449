import Swal from "sweetalert2";
import { PopupType } from "../enums/popup-types";

interface ErrorMessage {
  errors?: { [key: string]: string[] };
}

export function showMessage(type = PopupType.Primary, msg: ErrorMessage | string = "Example notification text.", showCloseButton = true, duration = 3000) {
  let messageToShow: string;

  if (typeof msg !== "string" && msg.errors) {
    messageToShow = Object.values(msg.errors).flat().join(" ");
  } else {
    messageToShow = msg as string;
  }

  const toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: duration,
    customClass: {
      popup: type,
    },
    width: "35rem",
    showCloseButton: showCloseButton,
  });

  toast.fire({
    title: messageToShow,
  });
}
