import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService implements OnDestroy {
  private storageSubjects: { [key: string]: BehaviorSubject<boolean> } = {};

  constructor() {
    window.addEventListener("storage", this.handleStorageEvent.bind(this));
  }

  private handleStorageEvent(event: StorageEvent): void {
    if (event.key && this.storageSubjects[event.key]) {
      this.storageSubjects[event.key].next(this.checkKeyExists(event.key));
    }
  }

  private checkKeyExists(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  getKeyStatus(key: string): BehaviorSubject<boolean> {
    if (!this.storageSubjects[key]) {
      this.storageSubjects[key] = new BehaviorSubject<boolean>(this.checkKeyExists(key));
    }
    return this.storageSubjects[key];
  }

  updateKey(key: string, data: string): void {
    if (data) {
      localStorage.setItem(key, data);
    } else {
      localStorage.removeItem(key);
    }
    if (this.storageSubjects[key]) {
      this.storageSubjects[key].next(this.checkKeyExists(key));
    }
  }

  loadDataFromLocalStorage<T>(key: string): T {
    const savedData = localStorage.getItem(key);
    if (savedData) {
      return savedData as unknown as T;
    }
    return null as T;
  }
  clearSpecificData(): void {
    const keysToRemove = ["flightTicketData", "busTicketData", "groupTravelPlan", "otherService", "transferPlan", "travelData"];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
      this.getKeyStatus(key).next(false);
    });
  }
  removeSpecificItem(key: string): void {
    localStorage.removeItem(key);
    this.getKeyStatus(key).next(false);
  }

  ngOnDestroy(): void {
    window.removeEventListener("storage", this.handleStorageEvent.bind(this));
  }
}
