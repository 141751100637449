import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { catchError, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelDestination } from "../models/travel-destination";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ETouristCodebookEntry } from "src/app/shared/models/etourist";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";
import { typeOrAccommodationCodeRequired } from "src/app/shared/validators/field.validator";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { dateFormatValidator, endDateAfterStartDateValidator } from "src/app/shared/validators/date.validator";
import { DestinationAutocompleteComponent } from "../../destinations/destination-autocomplete/destination-autocomplete.component";
import { ETouristStateService } from "../../sales/services/etourist-state.service";

@Component({
  selector: "app-travel-destination-create",
  templateUrl: "./travel-destination.create.component.html",
  animations: [toggleAnimation],
})
export class TravelDestinationCreateComponent implements OnInit, AfterViewInit {
  @Input() defaultDestination: TravelDestination = {} as TravelDestination;
  @Input() id!: number;
  @Input() isMainDestination = true;
  @Output() submitDestination: EventEmitter<TravelDestination> = new EventEmitter<TravelDestination>();
  @ViewChild("startInput") startInput!: DestinationAutocompleteComponent;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  public form!: FormGroup;
  public isSubmitForm = false;
  protected accomodationSubscriber$!: Observable<ETouristCodebookEntry[]>;

  constructor(
    public store: Store<AppState>,
    public translate: TranslateService,
    public fb: FormBuilder,
    public utilService: UtilService,
    public groupTravelService: GroupTravelsService,
    private router: Router,
    public eTuristService: ETouristService,
    private etouristStateService: ETouristStateService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.startInput.focusField();
    }, 0);
  }

  ngOnInit(): void {
    this.initForm(this.defaultDestination);
    if (this.router.url.includes("/edit")) {
      this.fetchEntities();
    }
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.getTravel();
      }
    });
    this.etouristStateService.checked$.subscribe((status) => {
      if (status) {
        this.accomodationSubscriber$ = this.eTuristService.getAccomodation().pipe(
          catchError((error) => {
            showMessage(PopupType.Danger, error.error);
            return of([]);
          })
        );

        this.accomodationSubscriber$.subscribe((accomodationList) => {
          const selectedAccomodation = accomodationList?.find((t) => t.code === this.defaultDestination.eTuristAccommodationCode) || null;
          if (this.form && selectedAccomodation) {
            this.form.patchValue({
              eTuristAccomodationCode: selectedAccomodation?.code,
            });
          }
        });
      }
    });
  }

  initForm(travel: TravelDestination | undefined) {
    if (travel) {
      const { id, destination, hotel, start, end, service, days, nights, roomDescription, eTuristAccommodationCode, eTuristAccommodationLabel } = travel;
      const country = destination?.country || null;
      this.form = this.fb.group(
        {
          id: [id || null],
          destination: [destination || "", { validators: [Validators.required], updateOn: "blur" }],
          hotel: [hotel || "", { validators: [Validators.required], updateOn: "blur" }],
          start: [start ? moment(start).format("DD.MM.YYYY") : "", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
          end: [end ? moment(end).format("DD.MM.YYYY") : "", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
          service: [service || "", { validators: [], updateOn: "blur" }],
          days: [days || "", { validators: [Validators.required], updateOn: "blur" }],
          nights: [nights || "", { validators: [Validators.required], updateOn: "blur" }],
          roomDescription: [roomDescription || ""],
          isMainDestination: [true],
          country: [country],
          eTuristAccommodationCode: [eTuristAccommodationCode],
          eTuristAccommodationLabel: [eTuristAccommodationLabel],
        },
        {
          validators: [endDateAfterStartDateValidator, typeOrAccommodationCodeRequired],
        }
      );
    } else {
      this.form = this.fb.group({
        id: [""],
        destination: ["", { validators: [Validators.required], updateOn: "blur" }],
        hotel: ["", { validators: [Validators.required], updateOn: "blur" }],
        start: ["", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: ["", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        service: ["", { validators: [], updateOn: "blur" }],
        days: ["", { validators: [Validators.required], updateOn: "blur" }],
        nights: ["", { validators: [Validators.required], updateOn: "blur" }],
        roomDescription: [""],
        isMainDestination: [true],
        eTuristAccommodationCode: ["", { updateOn: "blur" }],
        eTuristAccommodationLabel: [],
      });
    }
  }
  onAccommodationChange(event: ETouristCodebookEntry) {
    this.form.patchValue({
      eTuristAccommodationLabel: event.name,
    });
  }

  setDaysNights() {
    const startValue = this.form.controls["start"].value;
    if (startValue && !this.form.controls["start"].errors) {
      const start = moment(startValue.replace(/(\d{2})(\d{2})(\d{4})/, "$1.$2.$3"), "DD.MM.YYYY", true);
      if (start.isValid()) {
        this.form.controls["end"].setValue(start.add(this.form.controls["days"].value - 1, "days").format("DD.MM.YYYY"));
      }
    }
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  fetchEntities() {
    if (this.id) {
      this.groupTravelService.getGroupTravelDestinations(this.id).subscribe((response) => {
        this.initForm(response);
        this.getTravel();
      });
    }
  }
  getTravel(): TravelDestination {
    const travelDestination = { ...this.form.getRawValue(), travelId: this.id, isMainDestination: true };
    return travelDestination;
  }
  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      return;
    }
    const rawValues = this.form.getRawValue();
    const formattedStart = moment(rawValues.start, "DD.MM.YYYY").format("YYYY-MM-DD");
    const formattedEnd = moment(rawValues.end, "DD.MM.YYYY").format("YYYY-MM-DD");

    const editedDestination = {
      ...this.defaultDestination,
      ...rawValues,
      start: formattedStart,
      end: formattedEnd,
      serviceId: rawValues.service?.id,
      isMainDestination: this.isMainDestination,
    };
    this.submitDestination.emit(editedDestination);
  }
  setStartAndEnd(event: any) {
    this.form.controls["start"].setValue(event.start);
    this.form.controls["end"].setValue(event.end);
    this.form.controls["days"].setValue(event.days);
    this.form.controls["nights"].setValue(event.nights);
  }
  save() {
    const country = this.form.controls["country"].value;
    const countryId = country?.id;
    if (countryId !== undefined) {
      this.utilService.selectCountry(countryId);
    }
  }
}
