<button type="button" (click)="toggleSidebarAction()" class="btn btn-primary gap-2">
	<icon-clock></icon-clock>
</button>
<div *ngIf="isShowingSidebar">
  <nav
    class="fixed bottom-0 top-0 z-[51] w-full max-w-[600px] bg-white p-4 shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-700 ease-in-out dark:bg-[#0e1726]"
    [ngClass]="{ 'ltr:right-0 rtl:left-0': isShowingSidebar, 'ltr:-right-[400px] rtl:-left-[400px]': !isShowingSidebar }"
  >
    <a
      href="javascript:;"
      class="absolute bottom-0 top-0 my-auto flex h-10 w-12 cursor-pointer items-center justify-center bg-primary text-white ltr:-left-12 ltr:rounded-bl-full ltr:rounded-tl-full rtl:-right-12 rtl:rounded-br-full rtl:rounded-tr-full"
      (click)="isShowingSidebar = !isShowingSidebar"
    >
      <icon-settings class="h-5 w-5 animate-[spin_3s_linear_infinite]" />
    </a>
    <ng-scrollbar class="relative h-full overflow-x-hidden ltr:-mr-3 ltr:pr-3 rtl:-ml-3 rtl:pl-3">
      <div>
        <div class="relative pb-5 text-center">
          <a href="javascript:;" class="absolute top-0 opacity-30 hover:opacity-100 dark:text-white ltr:right-0 rtl:left-0" (click)="toggleSidebarAction()">
            <icon-x class="h-5 w-5" />
          </a>
          <h4 class="mb-1 text-2xl font-semibold text-gray-800 dark:text-white">{{ "change_history.title" | translate }}</h4>
          <p class="text-gray-600 dark:text-gray-400">{{ "change_history.description" | translate }}</p>
        </div>

        <div class="mx-auto max-w-[900px]">
          <div class="flex" *ngFor="let entry of auditEntries">
            <div class="mb-4 flex">
              <p class="mr-2 min-w-[58px] max-w-[100px] py-2.5 text-base font-semibold text-[#3b3f5c] dark:text-white-light">{{ entry.dateTime | date : "dd-MM-yyyy" }}</p>
              <div
                class="relative before:absolute before:left-1/2 before:top-[15px] before:h-2.5 before:w-2.5 before:-translate-x-1/2 before:rounded-full before:border-2 before:border-primary after:absolute after:-bottom-[30px] after:left-1/2 after:top-[25px] after:h-auto after:w-0 after:-translate-x-1/2 after:rounded-full after:border-l-2 after:border-primary"
              ></div>
              <div class="self-center p-2.5 ltr:ml-2.5 rtl:mr-2.5">
                <p class="text-[13px] font-semibold text-gray-800 dark:text-white">{{ entry.changeType }}</p>
                <p class="min-w-[100px] self-center text-xs font-bold text-gray-600 dark:text-gray-300">{{ entry.tableName }}</p>
              </div>

              <div class="self-center p-2.5 ltr:ml-2.5 rtl:mr-2.5">
                <div *ngIf="parseModifiedColumns(entry).length > 0">
                  <div *ngFor="let column of parseModifiedColumns(entry)" class="mb-2">
                    <div class="flex items-center">
                      <p class="flex-1 text-[13px] font-semibold text-gray-800 dark:text-white">
                        <strong class="mr-1">{{ column.PropertyName }}:</strong>
                        <span class="text-red-500">{{ column.OldValue }}</span>
                        <span class="text-black-500">&rarr;</span>
                        <span class="text-blue-500">{{ column.NewValue }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="parseModifiedColumns(entry).length === 0">
                  <p class="text-xs italic text-gray-600 dark:text-gray-300">{{ "change_history.no_changes" | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </nav>
</div>
