import * as moment from "moment";
import { Store } from "@ngrx/store";
import { User } from "src/app/auth/model/user";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { GenericResponse } from "../../models/generics";
import { TableService } from "../../services/table.service";
import { OnInit, Directive, OnDestroy } from "@angular/core";
import { PaginationProps } from "../../models/pagination-props";
import { updateSettings } from "src/app/store/auth/auth.actions";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { setFromTo } from "src/app/store/datepicker/datepicker.actions";
import { UserService } from "src/app/domain/user-profile/service/user.service";
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, Subject, Subscription, take, takeUntil } from "rxjs";

@Directive()
export abstract class BaseTableComponent<T> implements OnInit, OnDestroy {
  public items: T[] = [];
  public cols: DatatableColumn[] = [];
  public search = "";
  public loading = false;

  public pageSize = 10;
  public totalRows = 1;
  public pageNumber = 1;

  public from!: Date;
  public to!: Date | undefined;

  public filterType = "None";
  public sortColumn!: string;
  public sortDirection!: string;

  withDeptPlans!: number;
  withoutDeptPlans!: number;
  totalRecords!: number;
  private destroy$ = new Subject<void>();

  private langChangeSubscription!: Subscription;

  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;
  public user$!: Observable<User | undefined>;
  public user!: User;
  public settings: any = {};
  public key = this.apiUrl.includes("?") ? this.apiUrl.split("?")[0] : this.apiUrl;
  protected updatePaginationProps = new BehaviorSubject<PaginationProps>(this.getPaginationParams());
  public fetchSubscription!: Subscription;
  private searchSubject = new BehaviorSubject<string>("");

  constructor(public store: Store<AppState>, public tableService: TableService, public apiUrl: string, public userService: UserService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.getColumns();
    this.initializeSelectors();

    this.updatePaginationProps
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((obj) => {
        this.saveSnapshotOfProps();
        this.fetchItems(obj);
      });

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.preserveColumnSettings();
    });
    this.searchSubject.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((searchTerm) => {
      this.search = searchTerm;
      this.pageNumber = 1;
      this.updatePaginationProps.next(this.getPaginationParams());
    });
    this.triggerInitialLoad();
  }

  protected preserveColumnSettings(): void {
    this.cols = this.cols.map((col) => ({
      ...col,
      title: col.title === this.translate.instant(col.title) ? col.title : this.translate.instant(col.title),
    }));
  }

  protected initializeSelectors() {
    this.datepick$ = this.store.select(selectFromTo);
    this.user$ = this.store.select(selectFeatureUser);

    this.user$.pipe(takeUntil(this.destroy$), take(1)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.settings = user.settings ? JSON.parse(user.settings) : {};
        this.restoreState(this.settings);
        this.updatePaginationProps.next(this.getPaginationParams());
      }
    });

    this.datepick$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          const isFromSame = moment(prev.from).isSame(moment(curr.from), "day");
          const isToSame = prev.to && curr.to ? moment(prev.to).isSame(moment(curr.to), "day") : prev.to === curr.to;
          return isFromSame && isToSame;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((datepick) => {
        if (datepick) {
          this.from = datepick.from;
          this.to = datepick.to;
          this.updatePaginationProps.next(this.getPaginationParams());
        }
      });
  }

  protected triggerInitialLoad() {
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  public changeServer(data: any) {
    this.sortColumn = data.sort_column;
    this.sortDirection = data.sort_direction;
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    if (!this.loading) {
      this.updatePaginationProps.next(this.getPaginationParams());
    }
  }

  public onSearchChange(searchTerm: string): void {
    this.searchSubject.next(searchTerm);
  }

  public onSelectionChange(val: string): void {
    this.filterType = val;
    this.pageNumber = 1;
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  protected fetchItems(paginationParams: PaginationProps) {
    this.loading = true;
    const { apiUrl, pageNumber, pageSize, filterType, from, to, sortColumn, sortDirection, search } = paginationParams;
    this.fetchSubscription = this.tableService.getItemsWithHeaders<T>(apiUrl, pageNumber, pageSize, filterType, from, to, sortColumn, sortDirection, search).subscribe((response: GenericResponse<T>) => {
      this.loading = false;
      this.items = response.body;
      const paginationHeader = response.headers.get("X-Pagination");
      if (paginationHeader) {
        const pagination = JSON.parse(paginationHeader || "");
        this.pageNumber = pagination.CurrentPage;
        this.pageSize = pagination.PageSize;
        this.totalRecords = pagination.TotalPages ?? 0;
        this.totalRows = pagination.TotalCount ?? 0;
        this.withDeptPlans = pagination.Dept ?? 0;
        this.withoutDeptPlans = pagination.WithoutDept ?? 0;
      }
      this.fetchSubscription.unsubscribe();
    });
  }

  public getPaginationParams(): PaginationProps {
    return {
      apiUrl: this.apiUrl,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filterType: this.filterType,
      from: this.from,
      to: this.to,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      search: this.search,
      cols: this.cols,
    };
  }

  private saveSnapshotOfProps() {
    const localSettings: PaginationProps = {
      apiUrl: this.apiUrl,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filterType: this.filterType,
      from: this.from,
      to: this.to || undefined,
      sortColumn: this.sortColumn || undefined,
      sortDirection: this.sortDirection || undefined,
      search: this.search || undefined,
      cols: this.cols,
    };

    const updatedSettings = { ...this.settings };
    updatedSettings[this.key] = { ...localSettings };
    if (this.user) {
      this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
      this.userService.updateUser(this.user.id, { ...this.user, settings: JSON.stringify(updatedSettings) });
    }
  }

  private restoreState(settings: any) {
    if (settings) {
      const localSettings = this.settings[this.key];
      if (localSettings) {
        this.pageNumber = localSettings.pageNumber ?? 1;
        this.pageSize = localSettings.pageSize ?? 10;
        this.filterType = localSettings.filterType ?? "None";
        this.from = localSettings.from ? new Date(localSettings.from) : this.from;
        this.to = localSettings.to ? new Date(localSettings.to) : undefined;
        this.sortColumn = localSettings.sortColumn ?? "";
        this.sortDirection = localSettings.sortDirection ?? "asc";
        this.search = localSettings.search ?? "";
        this.cols = localSettings.cols ?? this.cols;
        this.store.dispatch(setFromTo({ from: this.from, to: this.to }));
      }
    }
  }

  updateColumn(col: DatatableColumn) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
    if (this.user) {
      this.settings[this.key].cols = this.cols;
      const updatedSettings = { ...this.settings };
      this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
      this.userService.updateUser(this.user.id, { ...this.user, settings: JSON.stringify(updatedSettings) });
    }
  }
  abstract getColumns(): void;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
    if (this.searchSubject) {
      this.searchSubject.unsubscribe();
    }
  }
}

export interface DatatableColumn {
  condition: string;
  field: string;
  filter: boolean;
  hide: boolean;
  html: boolean;
  isUnique: boolean;
  search: boolean;
  sort: boolean;
  title: string;
  type?: string;
}
