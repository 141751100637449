import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-transfer",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #ffffff;
          }
          .st1 {
            fill: #2b2d41;
          }
        </style>
        <g>
          <path
            class="st0"
            d="M10.3,19c-0.9,0-1.7,0-2.6,0c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.3,3.4-3.4c0.6,0,1.2,0,1.9,0
				 c0.7,0,1.2-0.4,1.2-1.1c0-0.6-0.4-1-1.2-1c-0.5,0-1,0-1.5,0c-0.4,0-0.4-0.2-0.4-0.6C7.6,9.2,7.7,9,8,9c0.6,0,1.2,0,1.8,0
				 c1.1,0.1,2,1.1,1.9,2.2c0,1.1-0.9,2-2.1,2c-0.6,0-1.2,0-1.9,0c-1.4,0-2.4,1.1-2.4,2.4c0,1.3,1,2.3,2.4,2.3c1.7,0,3.5,0,5.2,0
				 c0.4,0,0.7,0,0.7,0.5c0,0.5-0.2,0.6-0.6,0.5C12.1,19,11.2,19,10.3,19C10.3,19,10.3,19,10.3,19z"
          />
          <path
            class="st0"
            d="M7.7,2.2C6.3,0.7,4.1,0.5,2.5,1.7c-1.6,1.2-2.1,3.4-1,5.1C2.5,8,3.7,9.1,4.8,10.3c1.1-1.1,2-2,3-3
				 C9.1,5.9,9,3.6,7.7,2.2z M7.2,6.2C6.6,7.2,5.7,7.9,4.8,8.9C4,8,3.2,7.2,2.5,6.4C1.7,5.3,1.9,3.6,3,2.7c1-0.9,2.6-1,3.7-0.1
				 C7.7,3.5,8.1,5.1,7.2,6.2z"
          />
          <path
            class="st0"
            d="M16.2,19.1c-0.9-0.9-1.7-1.7-2.4-2.6c-0.8-1.1-0.5-2.7,0.5-3.6c1.1-0.9,2.6-0.9,3.7,0c1,0.9,1.4,2.5,0.5,3.6
				 C17.9,17.4,17.1,18.2,16.2,19.1z M16.2,17.5c0.5-0.6,1-1,1.5-1.6c0.5-0.7,0.4-1.7-0.3-2.3c-0.7-0.6-1.6-0.6-2.3,0
				 c-0.7,0.6-0.9,1.6-0.4,2.3C15.2,16.5,15.7,17,16.2,17.5z"
          />
          <path
            class="st1"
            d="M6.6,2.6C5.5,1.7,4,1.8,3,2.7c-1,0.9-1.3,2.6-0.4,3.7C3.2,7.2,4,8,4.8,8.9c0.9-0.9,1.7-1.7,2.4-2.6
				 C8.1,5.1,7.7,3.5,6.6,2.6z M4.8,6.6c-1,0-1.8-0.8-1.7-1.8c0-1,0.8-1.7,1.7-1.7c1,0,1.8,0.8,1.8,1.8C6.6,5.8,5.8,6.6,4.8,6.6z"
          />
          <path
            class="st1"
            d="M16.2,17.5c-0.5-0.6-1.1-1-1.5-1.6c-0.5-0.7-0.3-1.7,0.4-2.3c0.7-0.6,1.6-0.5,2.3,0c0.7,0.6,0.8,1.6,0.3,2.3
				 C17.3,16.5,16.8,17,16.2,17.5z"
          />
          <path
            class="st0"
            d="M4.8,3.1c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.8,1.7,1.8c1,0,1.8-0.8,1.8-1.8C6.6,3.8,5.8,3,4.8,3.1z M4.8,6
				 C4.2,6,3.6,5.5,3.6,4.8c0-0.7,0.5-1.2,1.2-1.2C5.5,3.6,6,4.2,6,4.8C6,5.5,5.5,6,4.8,6z"
          />
          <path class="st1" d="M6,4.8C6,5.5,5.5,6,4.8,6C4.2,6,3.6,5.5,3.6,4.8c0-0.7,0.5-1.2,1.2-1.2C5.5,3.6,6,4.2,6,4.8z" />
          <circle class="st0" cx="16.2" cy="14.9" r="1" />
          <circle class="st1" cx="16.2" cy="14.9" r="0.6" />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuTransferComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
