import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TravelsService } from "../../travels/services/travels.service";
import { TravelQuickView } from "../../travels/models/travel-quick-view";

@Component({
  selector: "app-travel-quick-view",
  templateUrl: "./travel-quick-view.component.html",
})
export class TravelQuickViewComponent implements OnInit, OnDestroy {
  public data!: TravelQuickView;
  @Input() id!: number;
  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService, private travelService: TravelsService) {}

  ngOnInit(): void {
    if (this.id) {
      this.fetchTravelQuickView(this.id);
    }
  }
  fetchTravelQuickView(id: number) {
    this.travelService
      .getTravelQuickView(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((travel) => {
        this.data = travel;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
