import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { combineLatest, Observable, of } from "rxjs";
import { AuthService } from "../auth/service/auth.service";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HasIdGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const workerId = route.paramMap.get("id");

    // Combine both observables
    return combineLatest([this.authService.getUserRole(), this.authService.getUserId()]).pipe(
      map(([role, userId]) => {
        const normalizedRole = role.toLowerCase();
        const normalizedRoles = route.data["role"].map((r: string) => r.toLowerCase());
        if (normalizedRoles.includes(normalizedRole) || userId.toString() === workerId) {
          return true;
        } else {
          this.router.navigate(["/404"]);
          return false;
        }
      }),
      catchError((error) => {
        console.error("Error fetching user role or ID", error);
        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }
}
