import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-email-configuration-modal",
  templateUrl: "./email-configuration-modal.component.html",
})
export class EmailConfigurationModalComponent {
  @Input() form!: FormGroup;
  @Output() close: EventEmitter<null> = new EventEmitter();

  closeModal(): void {
    this.close.emit(null);
  }
}
