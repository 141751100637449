<div class="w-6">
  <ng-container [ngSwitch]="entityName">
    <!-- Ticket -->
    <ng-container *ngSwitchCase="'ticket'">
      <ng-container [ngSwitch]="type || 'default'">
        <ng-container *ngSwitchCase="'flight'">
          <icon-menu-plane></icon-menu-plane>
        </ng-container>
        <ng-container *ngSwitchCase="'bus'">
          <icon-menu-bus></icon-menu-bus>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>No specific ticket type</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Travel -->
    <ng-container *ngSwitchCase="'travel'">
      <icon-menu-travel></icon-menu-travel>
    </ng-container>

    <!-- Group Travel -->
    <ng-container *ngSwitchCase="'grouptravel'">
      <icon-menu-group-travel></icon-menu-group-travel>
    </ng-container>

    <!-- Transfer -->
    <ng-container *ngSwitchCase="'transfer'">
      <icon-menu-transfer></icon-menu-transfer>
    </ng-container>

    <!-- Plan Service -->
    <ng-container *ngSwitchCase="'planservice'">
      <icon-menu-other-services></icon-menu-other-services>
    </ng-container>

    <!-- Default -->
    <ng-container *ngSwitchDefault>
      <span>No icon available</span>
    </ng-container>
  </ng-container>
</div>
