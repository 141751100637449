import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function typeOrTransportCodeRequired(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const type = control.get("type");
    const eTuristTransportCode = control.get("eTuristTransportCode");

    if (type && !type.value && eTuristTransportCode && !eTuristTransportCode.value) {
      return { typeOrTransportCodeRequired: true };
    }
    return null;
  };
}
export function typeOrAccommodationCodeRequired(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const service = control.get("service");
    const eTuristAccommodationCode = control.get("eTuristAccommodationCode");

    if (service && !service.value && eTuristAccommodationCode && !eTuristAccommodationCode.value) {
      return { typeOrAccommodationCodeRequired: true };
    }

    return null;
  };
}
