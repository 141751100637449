<div [ngClass]="{ 'dark text-white-dark': (semidark$ | async), 'print:hidden': true }">
  <nav class="sidebar fixed bottom-0 top-0 z-40 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
    <div class="h-full bg-white dark:bg-[#0e1726]">
      <div class="flex items-center justify-between px-4 py-3">
        <a routerLink="/dashboard/sales" class="main-logo flex shrink-0 items-center">
          <img class="ml-[5px] w-8 flex-none" src="/assets/images/logo.svg" alt="" />
          <img class="w-40 flex-none" src="/assets/images/logo-text.svg" alt="" />
        </a>
        <a
          href="javascript:;"
          class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary dark:text-white-light dark:hover:bg-dark-light/10 rtl:rotate-180"
          (click)="toggleSidebar()"
        >
          <icon-carets-down class="m-auto rotate-90" />
        </a>
      </div>
      <div class="mx-4 pb-5">
        <div class="dropdown">
          <div hlMenu class="align-middle">
            <a href="javascript:;" hlMenuButton class="btn btn-danger dropdown-toggle w-full">
              <icon-plus></icon-plus>
              {{ "sidebar.add" | translate }}
              <span *ngIf="inProgressCount > 0" class="badge ms-2 bg-warning">{{ inProgressCount }}</span>
            </a>
            <ul *hlMenuItems @toggleAnimation class="red-menu red-menu red-menu w-full ltr:right-0 rtl:left-0">
              <li>
                <a routerLink="/dashboard/tickets/flight/create/new" *hlMenuItem="let menuItem">
                  {{ "sidebar.plane_ticket" | translate }}
                  <span *ngIf="isFlightTicketData" class="indicator-dot"></span>
                </a>
              </li>
              <li>
                <a routerLink="/dashboard/travels/create" *hlMenuItem="let menuItem">
                  {{ "sidebar.travel" | translate }}
                  <span *ngIf="isTravelDataInProgress" class="indicator-dot"></span>
                </a>
              </li>
              <li>
                <a routerLink="/dashboard/tickets/bus/create/new" *hlMenuItem="let menuItem">
                  {{ "sidebar.bus_ticket" | translate }}
                  <span *ngIf="isBusTicketData" class="indicator-dot"></span>
                </a>
              </li>
              <li>
                <a routerLink="/dashboard/group-travels" *hlMenuItem="let menuItem">
                  {{ "sidebar.group_travel" | translate }}
                  <span *ngIf="isGroupTravelInProgress" class="indicator-dot"></span>
                </a>
              </li>
              <li>
                <a routerLink="/dashboard/other-services/create" *hlMenuItem="let menuItem">
                  {{ "sidebar.other_services" | translate }}
                  <span *ngIf="isOtherServiceInProgress" class="indicator-dot"></span>
                </a>
              </li>
              <li>
                <a routerLink="/dashboard/transfers" *hlMenuItem="let menuItem">
                  {{ "sidebar.transfer" | translate }}
                  <span *ngIf="isTransferInProgress" class="indicator-dot"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ng-scrollbar class="relative !h-[calc(100vh-120px)]" appearance="compact">
        <ul class="relative space-y-0.5 p-4 py-0 font-semibold">
          <ng-container *ngFor="let section of sidebarSections">
            <h2 *ngIf="section.titleTranslationKey" class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
              <span>{{ section.titleTranslationKey | translate }}</span>
            </h2>
            <li *ngFor="let item of section.items" class="menu nav-item">
              <ng-container *appHasPackageRight="[item?.packageRequirment,true]">
                <a *appHasRole="item.roles" aria-disabled="true" class="nav-link group cursor-not-allowed">
                  <div [ngxTippy]="('higher_tier' | translate)" class="flex items-center">
                    <app-dynamic-icon [iconComponent]="item.iconComponent" class="shrink-0"></app-dynamic-icon>
                    <span class="text-gray-400 dark:text-[#506690] ltr:pl-3 rtl:pr-3">{{ item.translationKey | translate }}</span>
                  </div>
                </a>
              </ng-container>
              <ng-container *appHasPackageRight="[item?.packageRequirment]">
                <a *appHasRole="item.roles" [routerLink]="item.path" class="nav-link group" routerLinkActive="active" (click)="toggleMobileMenu()">
                  <div class="flex items-center">
                    <app-dynamic-icon [iconComponent]="item.iconComponent" class="shrink-0 group-hover:!text-primary"></app-dynamic-icon>
                    <span class="text-black dark:text-[#506690] dark:group-hover:text-white-dark ltr:pl-3 rtl:pr-3">{{ item.translationKey | translate }}</span>
                  </div>
                </a>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </ng-scrollbar>
    </div>
  </nav>
</div>
