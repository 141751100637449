import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Customer } from "src/app/domain/customers/models/customer";
import { DocumentService } from "src/app/shared/services/documents.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from "@angular/forms";

@Component({
  selector: "app-file-send",
  templateUrl: "./file-send.component.html",
})
export class FileSendComponent implements OnInit {
  @Input() passangers!: Customer[];
  @Input() sendDocumentFn!: (data: any) => Observable<any>;
  @Input() fileType!: string;
  @Input() title!: string;
  @Input() email!: string;
  @Input() id!: number;
  @Input() messageEn!: string;
  @Input() messageRs!: string;
  @Input() paymentId!: number | undefined;
  @Input() isOnlyAvans = false;
  form!: FormGroup;
  emails: string[] = [];
  isLoading = false;
  @Output() notifyWhenSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  translationKeys: { [key: string]: string } = {
    contract: "popup.contract_sent_successfully",
    voucher: "popup.voucher_sent_successfully",
    invoice: "popup.invoice_sent_successfully",
    announcement: "popup.announcement_sent_successfully",
  };

  constructor(public route: ActivatedRoute, public router: Router, private fb: FormBuilder, public translate: TranslateService) {}

  ngOnInit() {
    this.emails = [...(this.email ? [this.email] : []), ...this.passangers.filter((p) => p.email).map((p) => p.email), ...(this.emails || [])];

    this.form = this.fb.group({
      additionalEmails: new FormControl(this.emails, this.validators),
    });
  }

  public emailValidator(control: FormControl) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(control.value)) {
      return {
        invalidEmail: true,
      };
    }
    return null;
  }

  public validators = [this.emailValidator as ValidatorFn];

  public errorMessages = {
    invalidEmail: this.translate.instant("contractsendemail.email_invalid_format"),
  };

  sendDocument() {
    const hasAtLeastOneEmail = this.emails.length > 0 || this.email || this.passangers.some((p) => p.email);
    if (this.emails.length == 0) {
      showMessage(PopupType.Warning, this.translate.instant("popup.emails_are_missing_and_or_form_is_invalid"));
      return;
    }
    this.isLoading = true;

    const { content, css } = DocumentService.getContent(this.fileType);
    const combinedContent = `
      <style>
        ${css}
      </style>
      ${content}
    `;

    const htmlFile = DocumentService.stringToHtmlFile(combinedContent, `${this.fileType}.html`);
    const formData = new FormData();
    formData.append("Id", this.id.toString());
    formData.append("PaymentId", this.paymentId?.toString() || "");
    formData.append("EmailContent", this.messageRs);
    formData.append("EmailContentEnglish", this.messageEn);
    formData.append("Content", htmlFile, htmlFile.name);
    formData.append("AdditionalEmails", this.emails.join(","));
    formData.append("IsOnlyAvans", this.isOnlyAvans.toString());
    const passengerNames = this.passangers.filter((p) => p.email).map((p) => `${p.name} ${p.surname}`);
    if (this.passangers.length === 1 && !this.passangers[0].email) {
      formData.append("AdditionalEmails", this.emails.join(","));
      formData.append("PassengerNames", `${this.passangers[0].name} ${this.passangers[0].surname}`);
    } else {
      formData.append("PassengerNames", passengerNames.join(","));
    }
    const sendSub = this.sendDocumentFn(formData).subscribe({
      next: () => {
        showMessage(PopupType.Success, this.translate.instant(this.translationKeys[this.fileType]));
        this.isLoading = false;
        sendSub.unsubscribe();
        this.notifyWhenSend.emit(true);
      },
      error: () => {
        this.isLoading = false;
        sendSub.unsubscribe();
      },
    });
  }
}
