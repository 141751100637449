import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "../store/index.selector";
import { AppService } from "../service/app.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { toggleMainLoader, toggleSidebar } from "../store/layout/layout.actions";
import { selectIsButtonVisible, selectIsShowMainLoader, selectLayout, selectMenu, selectNavbar, selectRtlClass, selectSidebar } from "../store/layout/layout.selector";

@Component({
  selector: "app-root",
  templateUrl: "./app-layout.html",
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  showTopButton = false;
  headerClass = "";
  menu$!: Observable<string>;
  layout$!: Observable<string>;
  rtlClass$!: Observable<string>;
  navbar$!: Observable<string>;
  sidebar$!: Observable<boolean>;
  isShowMainLoader$!: Observable<boolean>;
  isVisible!: Observable<boolean>;
  isSettingsVisible = false;
  constructor(public translate: TranslateService, public storeData: Store<AppState>, private service: AppService, private router: Router) {}
  ngOnInit() {
    this.initAnimation();
    this.toggleLoader();
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.showTopButton = true;
      } else {
        this.showTopButton = false;
      }
    });
    this.menu$ = this.storeData.select(selectMenu);
    this.layout$ = this.storeData.select(selectLayout);
    this.rtlClass$ = this.storeData.select(selectRtlClass);
    this.navbar$ = this.storeData.select(selectNavbar);
    this.sidebar$ = this.storeData.select(selectSidebar);
    this.isShowMainLoader$ = this.storeData.select(selectIsShowMainLoader);
    this.isVisible = this.storeData.select(selectIsButtonVisible);
  }

  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener("scroll", () => {});
  }

  initAnimation() {
    this.service.changeAnimation();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.service.changeAnimation();
      }
    });

    const ele: HTMLElement | null = document.querySelector(".animation");
    if (ele) {
      ele.addEventListener("animationend", () => {
        this.service.changeAnimation("remove");
      });
    }
  }

  toggleLoader() {
    this.storeData.dispatch(toggleMainLoader({ isShow: true }));
    setTimeout(() => {
      this.storeData.dispatch(toggleMainLoader({ isShow: false }));
    }, 500);
  }

  public goToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  toggleSidebar() {
    this.storeData.dispatch(toggleSidebar());
  }
}
