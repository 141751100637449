import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NavigationDataService {
  private officeBranchId: number | null = null;

  setOfficeBranchId(id: number | null): void {
    this.officeBranchId = id;
  }

  getOfficeBranchId(): number | null {
    return this.officeBranchId;
  }
}
