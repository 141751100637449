<form [formGroup]="form" (ngSubmit)="submitForm()">
  <label for="current_password">{{ "user_profile.current_password" | translate }}</label>

  <div [ngClass]="form.controls['current_password'].touched ? (form.controls['current_password'].errors ? 'has-error' : 'has-success') : ''" class="relative mb-4">
    <div class="relative text-white-dark">
      <input
        id="current_password"
        [type]="visibility['currentPassword'] ? 'text' : 'password'"
        placeholder="{{ 'user_profile.current_password' | translate }}"
        class="form-input"
        formControlName="current_password"
        [appLimitCharacterInput]="60"
      />
      <button type="button" class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2" (click)="toggleVisibility('currentPassword')">
        <icon-eye-closed *ngIf="!visibility['currentPassword']; else currentHideIcon" class="dark:text-white"></icon-eye-closed>
        <ng-template #currentHideIcon>
          <icon-eye-open class="dark:text-white"></icon-eye-open>
        </ng-template>
      </button>
      <small class="text-danger" *ngIf="form.controls['current_password'].touched && form.controls['current_password'].errors">
        {{ "user_profile.please_provide_current_password" | translate }}
      </small>
    </div>
  </div>
  <label for="password">{{ "user_profile.password" | translate }}</label>

  <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''" class="relative mb-4">
    <div class="relative text-white-dark">
      <input
        id="password"
        [type]="visibility['password'] ? 'text' : 'password'"
        placeholder="{{ 'user_profile.password' | translate }}"
        class="form-input"
        formControlName="password"
        [appLimitCharacterInput]="60"
      />
      <button type="button" class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2" (click)="toggleVisibility('password')">
        <icon-eye-closed *ngIf="!visibility['password']; else passwordHideIcon" class="dark:text-white"></icon-eye-closed>
        <ng-template #passwordHideIcon>
          <icon-eye-open class="dark:text-white"></icon-eye-open>
        </ng-template>
      </button>
      <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
        <small class="text-danger" *ngIf="form.controls['password'].errors?.['required']">
          {{ "user_profile.enter_valid_password" | translate }}
        </small>
        <small class="text-danger" *ngIf="form.controls['password'].errors?.['invalidPassword']">
          {{ "user_profile.password_must_be_at_least_8_characters" | translate }}
        </small>
      </div>
    </div>
  </div>
  <label for="confirm_password">{{ "user_profile.confirm_password" | translate }}</label>

  <div [ngClass]="form.controls['confirm_password'].touched ? (form.controls['confirm_password'].errors ? 'has-error' : 'has-success') : ''" class="relative mb-4">
    <div class="relative text-white-dark">
      <input
        id="confirm_password"
        [type]="visibility['confirmPassword'] ? 'text' : 'password'"
        placeholder="{{ 'user_profile.confirm_password' | translate }}"
        class="form-input"
        formControlName="confirm_password"
        [appLimitCharacterInput]="60"
      />
      <button type="button" class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2" (click)="toggleVisibility('confirmPassword')">
        <icon-eye-closed *ngIf="!visibility['confirmPassword']; else confirmHideIcon" class="dark:text-white"></icon-eye-closed>
        <ng-template #confirmHideIcon>
          <icon-eye-open class="dark:text-white"></icon-eye-open>
        </ng-template>
      </button>
      <small class="text-danger" *ngIf="form.errors?.['notSame'] && form.controls['confirm_password'].touched">
        {{ "user_profile.passwords_dont_match" | translate }}
      </small>
    </div>
  </div>

  <button type="submit" class="btn btn-primary w-full">
    {{ "user_profile.save" | translate }}
  </button>
</form>
