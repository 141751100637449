<div class="relative">
  <div class="absolute inset-0">
    <img src="/assets/images/auth/Background-Login.png" alt="image" class="fixed inset-0 h-full w-full object-cover" />
    <div class="fixed inset-0 bg-black opacity-0 dark:opacity-50"></div>
  </div>

  <div class="absolute end-4 top-4 z-10">
    <div class="dropdown z-10">
      <div hlMenu>
        <button type="button" hlMenuButton class="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black">
          <div>
            <img [src]="'/assets/images/flags/' + (locale$ | async)?.toUpperCase() + '.svg'" alt="image" class="h-5 w-5 rounded-full object-cover" />
          </div>
          <div class="text-base font-bold uppercase">{{ (locale$ | async)?.toUpperCase() }}</div>
          <span class="shrink-0">
            <icon-caret-down />
          </span>
        </button>
        <ul
          *hlMenuItems
          @toggleAnimation
          class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark dark:text-white-dark dark:text-white-light/90 ltr:-right-14 sm:ltr:-right-2 rtl:-left-14 sm:rtl:-left-2"
          style="right: 0; padding-right: 1rem; @media (min-width: 640px) {right: auto; padding-right: 0}"
        >
          <li *ngFor="let item of languageList$ | async">
            <button *hlMenuItem="let menuItem" type="button" class="w-full hover:text-primary" [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }" (click)="changeLanguage(item)">
              <img class="h-5 w-5 rounded-full object-cover" [src]="'/assets/images/flags/' + item.code.toUpperCase() + '.svg'" alt="" />
              <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="relative flex min-h-screen flex-col items-center justify-center px-6 py-10 sm:px-16">
    <div class="mt-10 max-w-[1000px] rounded-[2rem] bg-white p-5">
      <h1 class="mt-4 text-center text-4xl font-bold">{{ "missing_branch_data.title" | translate }}</h1>
      <p class="mb-5 mt-5 pl-20 pr-20 text-center text-xl">{{ "missing_branch_data.desc" | translate }}</p>
      <div class="flex max-w-[1000px] flex-col items-center justify-center gap-3">
        <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
          <div class="custom-file-container" *ngIf="existingLogo; else uploadNewImage">
            <div class="image-wrapper m-auto w-1/3 rounded bg-gray-200 p-2">
              <img [src]="existingLogo.url" alt="" />
            </div>
            <div class="button-wrapper m-auto flex justify-center py-2">
              <button class="btn btn-danger" type="button" (click)="removeImage($event)">{{ "office-branch.remove_image" | translate }}</button>
            </div>
          </div>
          <ng-template #uploadNewImage>
            <div class="custom-file-container rounded bg-gray-100" data-upload-id="office-branch-logo" (click)="uploadClick($event)"></div>
          </ng-template>
          <div class="relative flex items-center pt-5">
            <span class="mr-4 flex-shrink font-bold text-gray-400">{{ "office-branch.branch_info" | translate }}</span>
            <div class="flex-grow border-t border-gray-400"></div>
          </div>
          <div class="pb-5">
            <small>{{ "office-branch.branch_info_desc" | translate }}</small>
          </div>
          <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
            <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
              <label for="name">{{ "office-branch.name" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_name' | translate }}" class="form-input" id="name" formControlName="name" />
              <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_name" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['guid'].touched ? (form.controls['guid'].errors ? 'has-error' : 'has-success') : ''">
              <label for="guid">{{ "office-branch.guid" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_guid' | translate }}" class="form-input" id="guid" formControlName="guid" />
              <ng-container *ngIf="form.controls['guid'].touched && form.controls['guid'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_guid" | translate }}</small>
                </p>
              </ng-container>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-5 md:grid-cols-3">
            <div [ngClass]="form.controls['bankAccount'].touched ? (form.controls['bankAccount'].errors ? 'has-error' : 'has-success') : ''">
              <label for="bankAccount">{{ "office-branch.bankAccount" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_bankAccount' | translate }}" class="form-input" id="bankAccount" formControlName="bankAccount" />
              <ng-container *ngIf="form.controls['bankAccount'].touched && form.controls['bankAccount'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_bankAccount" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['PIB'].touched ? (form.controls['PIB'].errors ? 'has-error' : 'has-success') : ''">
              <label for="PIB">{{ "office-branch.PIB" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_PIB' | translate }}" class="form-input" id="PIB" formControlName="PIB" />
              <ng-container *ngIf="form.controls['PIB'].touched && form.controls['PIB'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_PIB" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['fullAddress'].touched ? (form.controls['fullAddress'].errors ? 'has-error' : 'has-success') : ''">
              <label for="full_address">{{ "office-branch.full_address" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_full_address' | translate }}" class="form-input" id="full_address" formControlName="fullAddress" />
              <ng-container *ngIf="form.controls['fullAddress'].touched && form.controls['fullAddress'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_full_address" | translate }}</small>
                </p>
              </ng-container>
            </div>
          </div>

          <!-- START eTurist Requirement -->
          <!--<div [ngClass]="form.controls['city'].touched ? (form.controls['city'].errors ? 'has-error' : 'has-success') : ''">
    <label for="city">{{ "office-branch.city" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_city' | translate }}" class="form-input" id="city" formControlName="city" />
    <ng-container *ngIf="form.controls['city'].touched && form.controls['city'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_city" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['street'].touched ? (form.controls['street'].errors ? 'has-error' : 'has-success') : ''">
    <label for="street">{{ "office-branch.street" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_street' | translate }}" class="form-input" id="street" formControlName="street" />
    <ng-container *ngIf="form.controls['street'].touched && form.controls['street'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_street" | translate }}</small>
      </p>
    </ng-container>
  </div>
</div> -->
          <!-- END eTurist Requirement -->
          <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3">
            <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''">
              <label for="phoneNumber">{{ "office-branch.phoneNumber" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_phoneNumber' | translate }}" class="form-input" id="phoneNumber" formControlName="phoneNumber" />
              <ng-container *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_phoneNumber" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''">
              <label for="email">{{ "office-branch.email" | translate }}</label>
              <input type="text" placeholder="{{ 'office-branch.enter_email' | translate }}" class="form-input" id="email" formControlName="email" />
              <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_email" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['siteUrl'].touched ? (form.controls['siteUrl'].errors ? 'has-error' : 'has-success') : ''">
              <label for="siteUrl">{{ "office-branch.siteUrl" | translate }}</label>
              <div class="flex">
                <div
                  class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
                >
                  https://www.
                </div>
                <input type="text" placeholder="{{ 'office-branch.enter_siteUrl' | translate }}" class="form-input ltr:rounded-l-none rtl:rounded-r-none" id="siteUrl" formControlName="siteUrl" />
              </div>
              <ng-container *ngIf="form.controls['siteUrl'].touched && form.controls['siteUrl'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_siteUrl" | translate }}</small>
                </p>
              </ng-container>
            </div>
          </div>
          <div class="relative flex items-center pt-5">
            <span class="mr-4 flex-shrink font-bold text-gray-400">{{ "office-branch.offical_agent_info" | translate }}</span>

            <div class="flex-grow border-t border-gray-400"></div>
          </div>
          <div class="pb-5">
            <small>{{ "office-branch.offical_agent_info_desc" | translate }}</small>
            <br />
            <small>
              {{ "office-branch.password_instructions" | translate }}
              <a href="https://knowledge.workspace.google.com/kb/how-to-create-app-passwords-000009237" target="_blank" class="icon-link">
                <icon-new-tab />
              </a>
            </small>
          </div>

          <div class="d-block">
            <button type="button" class="btn btn-primary" (click)="openEmailModal()">{{ "office-branch.email_settings" | translate }}</button>
            <ng-container
              *ngIf="
                (form.controls['password'].touched && form.controls['password'].errors) ||
                (form.controls['senderEmail'].touched && form.controls['senderEmail'].errors) ||
                (form.controls['senderName'].touched && form.controls['senderName'].errors) ||
                (form.controls['adminEmail'].touched && form.controls['adminEmail'].errors)
              "
            >
              <p class="mt-1 text-danger">
                <small>{{ "office-branch.email_settings_validation" | translate }}</small>
              </p>
            </ng-container>
          </div>

          <modal #emailConfigModal class="modal-center auth-modal" [closeOnOutsideClick]="false">
            <ng-template #modalHeader>
              <div class="!py-5">
                {{ "office-branch.email_settings" | translate }}
              </div>
            </ng-template>
            <ng-template #modalBody>
              <div class="my-auto">
                <app-email-configuration-modal [form]="form" (close)="closeEmailModal()"></app-email-configuration-modal>
              </div>
            </ng-template>
            <ng-template #modalFooter></ng-template>
          </modal>

          <button type="submit" class="btn btn-primary mt-3 w-full" [disabled]="isLoading">
            <ng-container *ngIf="isLoading; else alternateTranslation">
              <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
              {{ "loading" | translate }}
            </ng-container>
            <ng-template #alternateTranslation>
              {{ "office-branch.save" | translate }}
            </ng-template>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
