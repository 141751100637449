import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private router: Router) {}

  navigateToDetails(value: EntityDetails): void {
    switch (value.entityName) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([`dashboard/group-travels/${value.additionalEntityId}/plan/details`]);
        break;
      case "Transfer":
        this.router.navigate([`dashboard/transfers/${value.additionalEntityId}/plan/details`]);
        break;
      case "PlanService":
        this.router.navigate([`dashboard/other-services/${value.entityId}`]);
        break;
      default:
        break;
    }
  }
  navigateToEdit(value: EntityEdit) {
    switch (value.entityName) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/edit/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/draft/edit-travel//${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([`dashboard/group-travels/${value.additionalEntityId}/plan/edit`]);
        break;
      case "Transfer":
        this.router.navigate([`dashboard/transfers/${value.additionalEntityId}/plan/edit`]);
        break;
      case "PlanService":
        this.router.navigate([`dashboard/other-services/edit/${value.entityId}`]);
        break;
      default:
        break;
    }
  }
}
export interface EntityDetails {
  entityName: "Ticket" | "Travel" | "GroupTravel" | "Transfer" | "PlanService";
  entityId: string | number;
  type?: string;
  additionalEntityId?: string | number;
}
export interface EntityEdit {
  entityName: "Ticket" | "Travel" | "GroupTravel" | "Transfer" | "PlanService";
  entityId: string | number;
  type?: string;
  additionalEntityId?: string | number;
}
