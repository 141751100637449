import { PrintPreviewService } from "./../../../shared/services/print-preview.service";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { ModalComponent } from "angular-custom-modal";
import { Announcement } from "../models/announcement";
import { TranslateService } from "@ngx-translate/core";
import { TravelsService } from "../services/travels.service";
import { Organizer } from "../../organizers/models/organizers";
import { AnnouncementService } from "../services/announcement.service";
import { VoucherService } from "../../documents/voucher/services/voucher.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-announcement",
  templateUrl: "./announcement.component.html",
})
export class AnnouncementComponent implements OnInit {
  @Input() travelId!: number;
  @Input() organizer!: Organizer;
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("modal") modal!: ModalComponent;
  isLoading = false;
  announcement!: Announcement;
  form!: FormGroup;

  constructor(
    public travelService: TravelsService,
    public router: Router,
    public voucherService: VoucherService,
    public announcementService: AnnouncementService,
    public translate: TranslateService,
    public printPreviewService: PrintPreviewService
  ) {}

  ngOnInit(): void {
    const subscription = this.announcementService.getAnnouncementsByTravel(this.travelId).subscribe((response) => {
      this.announcement = response;
      subscription.unsubscribe();
    });
  }

  finishSending() {
    this.modal.close();
    this.notifyWhenEditAndSend.emit();
  }

  navigateToEditVoucher() {
    this.router.navigate([`/dashboard/travels/${this.travelId}/announcement/${this.announcement.id}/edit`]);
  }

  printVoucher() {
    this.isLoading = true;
    const subscription = this.announcementService.generateAnnouncementHtml(this.announcement.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }
  sendVoucher() {
    this.modal.open();
  }
}
