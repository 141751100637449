<div class="space-y-8">
  <div class="panel p-0 py-5">
    <div>
      <div class="m-2 flex justify-end" *ngIf="hasTicketData">
        <button type="button" class="btn btn-primary" (click)="clearTicketData()">
          <icon-discard></icon-discard>
          {{ "customer_checkout.discard_sales" | translate }}
        </button>
      </div>
      <div class="relative z-[1]">
        <div
          class="absolute top-[30px] -z-[1] m-auto h-1 w-[15%] bg-primary transition-[width] ltr:left-0 rtl:right-0"
          [ngClass]="{ 'w-[15%]': activeTab === 1, 'w-[48%]': activeTab === 2, 'w-[81%]': activeTab === 3 }"
        ></div>
        <ul class="mb-5 grid grid-cols-3">
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
              (click)="proceedToNextStage(1)"
            >
              <icon-person></icon-person>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "ticket_checkout.passangers" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
              (click)="proceedToNextStage(2)"
            >
              <icon-home></icon-home>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "ticket_checkout.ticket" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 3 }"
              (click)="proceedToNextStage(3)"
            >
              <icon-thumbs-up></icon-thumbs-up>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 3 }">{{ "ticket_checkout.reciept" | translate }}</span>
          </li>
        </ul>
      </div>
      <div>
        <div [ngStyle]="isVisibleTab(1)">
          <!-- <div class="mx-5">
            <h3 class="font-bold">{{ "ticket_checkout.customer" | translate }}</h3>
            <hr />
          </div> -->

					<app-passanger-list #passangerList [withReservation]="true" (passangersSelected)="toggleButton($event)" (submitCustomer)="handleCustomerSubmit()"></app-passanger-list>
        </div>
        <div [ngStyle]="isVisibleTab(2)">
          <div class="mx-5">
            <h3 class="font-bold">{{ "ticket_checkout.ticket" | translate }}</h3>
            <hr />
          </div>
          <app-ticket-create #ticket></app-ticket-create>
        </div>
        <div [ngStyle]="isVisibleTab(3)">
          <app-sale-create #sale></app-sale-create>
        </div>
      </div>
      <div class="flex justify-between px-5">
        <ng-container *ngIf="activeTab > 1; else placeholder">
          <button type="button" class="btn btn-primary"  (click)="activeTab = activeTab - 1">
            {{ "ticket_checkout.back" | translate }}
          </button>
        </ng-container>
        <ng-template #placeholder>
          <div class="invisible"></div>
        </ng-template>

        <button #nextStepButton type="button" class="btn btn-primary" *ngIf="activeTab !== 3 && !isEditingPassanger" (click)="proceedToNextStage()">
          {{ "ticket_checkout.next" | translate }}
        </button>

        <button type="button" class="btn btn-primary" *ngIf="activeTab === 3" (click)="submitTicket()" [disabled]="isLoading">
          <ng-container *ngIf="isLoading; else alternateTranslation">
            <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
            {{ "loading" | translate }}
          </ng-container>
          <ng-template #alternateTranslation>
            {{ "ticket_checkout.finish_sale" | translate }}
          </ng-template>
        </button>
      </div>
      <div class="p-5">
        <hr class="my-2" />
        <div>
          <label for="notes">{{ "ticket_checkout.remarks" | translate }}</label>
          <textarea id="notes" rows="3" class="w-100 form-textarea mt-2" placeholder="{{ 'ticket_checkout.remarks' | translate }}" [(ngModel)]="note"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
