import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LayoutState } from './layout.reducer';

export const selectLayoutState = createFeatureSelector<LayoutState>('layout');

export const selectIsDarkMode = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isDarkMode
);

export const selectMainLayout = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.mainLayout
);

export const selectTheme = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.theme
);

export const selectMenu = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.menu
);

export const selectLayout = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.layout
);

export const selectRtlClass = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.rtlClass
);

export const selectAnimation = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.animation
);

export const selectNavbar = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.navbar
);

export const selectSidebar = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.sidebar
);

export const selectIsShowMainLoader = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isShowMainLoader
);

export const selectSemidark = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.semidark
);
export const selectIsButtonVisible = createSelector(  
  selectLayoutState,  
  (state: LayoutState) => state.isVisible  
);