<div class="mx-5 grid grid-cols-1">
  <div class="flex gap-3">
    <h3 class="font-bold">
      {{ "group_travel_create.destination" | translate }}
    </h3>
    <button (click)="addDestination()" [ngxTippy]="'group_travel_create.add_more_destination' | translate">
      <icon-plus></icon-plus>
    </button>
  </div>

  <div class="mt-3 grid">
    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="travelDestinations.length > 0">
      <ng-container *ngFor="let item of travelDestinations; index as i">
        <ng-container>
          <div
            [ngClass]="{ 'bg-green-100': editDestination && editDestination.guid === item.guid }"
            [id]="i"
            class="flex min-w-[625px] items-center justify-between rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b]"
          >
            <div class="user-profile">
              <icon-location></icon-location>
            </div>
            <div *ngIf="item.destination">{{ item.destination.description }}</div>
            <div *ngIf="item.hotel">{{ "group_travel_create.hotel" | translate }}: {{ item.hotel.name }}</div>
            <div *ngIf="item.service">{{ "group_travel_create.service" | translate }}: {{ item.service.title }}</div>
            <div *ngIf="!item.destination">{{ "group_travel_create.destination" | translate }} {{ i + 2 }}</div>
            <div class="px-2">{{ item.start | date : "dd.MM.yyyy" }} - {{ item.end | date : "dd.MM.yyyy" }}</div>
            <ng-template #backUp></ng-template>
            <div>
              <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('sale_details.edit')" (click)="toEditDestination(item)">
                <icon-pencil />
              </a>
            </div>
            <div>
              <a type="button" [ngxTippy]="translate.instant('sale_details.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removeDestination(item.guid)">
                <icon-trash-lines />
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <hr />
    <div class="relative" *ngIf="editDestination">
      <hr />
      <app-travel-destination-create [defaultDestination]="editDestination" (submitDestination)="handleDestination($event)" [isMainDestination]="false"> </app-travel-destination-create>
    </div>
  </div>
</div>
