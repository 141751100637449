import { Observable, Subscription } from "rxjs";
import * as moment from "moment";
import { Contract } from "../models/contract";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ContractService } from "../services/contract.service";
import { Customer } from "src/app/domain/customers/models/customer";
import { SalesService } from "src/app/domain/sales/services/sales.service";

@Component({
  selector: "app-contract-edit",
  templateUrl: "./contract.edit.component.html",
})
export class ContractEditComponent implements OnInit, OnDestroy {
  @Input() contract!: Contract;
  @Input() cancelEditUrl!: string;
  @Input() passangers!: Customer[];
  @Input() updateContractFn!: (data: any) => Observable<Contract>;
  contractId!: number;
  travelId!: number;
  form!: FormGroup;
  isLoading = false;
  private subscriptions: Array<Subscription | undefined> = [];
  exchangeRate!: number;
  constructor(public fb: FormBuilder, public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService, public sales: SalesService) {}

  initForm() {
    this.form = this.fb.group({
      totalInEuro: [this.contract.totalInEuroPrice || ""],
      totalInDinars: [this.contract.priceTotal || ""],
      organizerName: [this.contract?.organizer?.name || ""],
      organizerPhone: [this.contract?.organizer?.phone || ""],
      organizerLicence: [this.contract?.organizer?.licence || ""],
      responsiblePerson: [`${this.contract?.organizer?.firstName} ${this.contract?.organizer?.lastName}` || ""],
      responsiblePersonEmail: [this.contract?.organizer?.email || ""],
      officeBranch: [this.contract?.officeBranch?.name || ""],
      officeAddress: [`${this.contract?.officeBranch?.fullAddress} ${this.contract?.officeBranch?.city}` || ""],
      officeLicence: [this.contract?.officeBranch?.licence || ""],
      startDate: [moment(this.contract.startDate).format("DD/MM/YYYY").toString() || ""],
      hotel: [this.contract.hotel || ""],
      destination: [this.contract.destination || ""],
      checkIn: [moment(this.contract.checkIn).format("DD/MM/YYYY").toString() || ""],
      checkOut: [moment(this.contract.checkOut).format("DD/MM/YYYY").toString() || ""],
      endDate: [moment(this.contract.endDate).format("DD/MM/YYYY").toString() || ""],
      service: [this.contract.service || ""],
      transportation: [this.contract.transportation || ""],
      days: [this.contract.days || ""],
      nights: [this.contract.nights || ""],
      rooms: [this.contract.rooms || ""],
    });
  }
  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb?.unsubscribe());
  }

  saveContract() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    const contract = this.extractContractFromForm();
    this.isLoading = true;
    const updateSub = this.updateContractFn(contract).subscribe({
      next: () => {
        this.isLoading = false;
        updateSub.unsubscribe();
        this.router.navigate([this.cancelEditUrl]);
        showMessage(PopupType.Success, this.translate.instant("popup.contract_updated_successfully"));
      },
      error: (error) => {
        this.isLoading = false;
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }
  extractContractFromForm() {
    this.passangers = this.passangers.map((passenger: any) => {
      passenger.name = passenger.fullName.split(" ")[0];
      passenger.surname = passenger.fullName.split(" ")[1];
      passenger.email = passenger.email || "";
      passenger.dateOfBirth = undefined;
      return passenger;
    });
    const {
      totalInEuro,
      totalInDinars,
      organizerName,
      organizerPhone,
      organizerLicence,
      responsiblePerson,
      responsiblePersonEmail,
      officeBranch,
      officeAddress,
      officeLicence,
      startDate,
      hotel,
      destination,
      checkIn,
      checkOut,
      endDate,
      service,
      transportation,
      days,
      nights,
      rooms,
    } = this.form.value;

    const contract = {
      totalInEuroPrice: parseInt(totalInEuro, 10),
      priceTotal: totalInDinars,
      organizer: {
        name: organizerName,
        phone: organizerPhone,
        licence: organizerLicence,
        responsiblePerson: {
          name: responsiblePerson,
          email: responsiblePersonEmail,
        },
      },
      officeBranch: {
        name: officeBranch,
        address: officeAddress,
        licence: officeLicence,
      },
      startDate: moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      checkIn: moment(checkIn, "DD/MM/YYYY").format("YYYY-MM-DD"),
      checkOut: moment(checkOut, "DD/MM/YYYY").format("YYYY-MM-DD"),
      endDate: moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      hotel,
      destination,
      service,
      transportation,
      days: parseInt(days, 10),
      nights: parseInt(nights, 10),
      rooms: rooms,
      passengers: this.passangers,
    };

    return contract;
  }

  cancelEdit() {
    this.router.navigate([this.cancelEditUrl]);
  }
}
