import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/shared/services/util.service";
import { Transfer } from "src/app/domain/transfers/models/transfer";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransfersService } from "../services/transfers.service.service";
import { dateTimeFormatValidator } from "src/app/shared/validators/date.validator";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-transfer-edit",
  templateUrl: "./transfer.edit.component.html",
})
export class TransferEditComponent implements OnInit, AfterViewInit, OnDestroy {
  form!: FormGroup;
  transferId!: any;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  isLoading = false;
  @ViewChild("nameInput") nameInput!: ElementRef;
  private oneWaySubscription: Subscription | undefined;
  private routeSub: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private transferService: TransfersService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public utilService: UtilService,
    private router: Router
  ) {}

  initForm(transfers: Transfer | undefined) {
    const { name, departureDate, arrivalDate, departure, destination, vehicleOption, capacity, twoWay } = transfers || { Transfer: {} };
    const country = departure?.country || destination?.country || null;
    this.form = this.fb.group({
      name: [name, Validators.required],
      departureDate: [this.formattedDate(departureDate), { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      arrivalDate: [this.formattedDate(arrivalDate)],
      departure: [departure, Validators.required],
      destination: [destination, Validators.required],
      vehicle: [vehicleOption, Validators.required],
      capacity: [capacity, Validators.required],
      twoWay: [twoWay || false],
      country: [country],
    });
  }

  ngOnInit(): void {
    this.routeSub = this.route.paramMap.subscribe((params) => {
      this.transferId = params.get("id");
      this.initForm(undefined);
      this.getTransfer(this.transferId);
    });

    setTimeout(() => {
      this.focusOnInput();
    }, 0);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.focusOnInput();
    }, 0);
  }
  ngOnDestroy() {
    this.oneWaySubscription?.unsubscribe();
    this.routeSub?.unsubscribe();
  }

  focusOnInput(): void {
    const inputElement = this.nameInput.nativeElement;
    inputElement.focus();
    const valueLength = inputElement.value.length;
    inputElement.setSelectionRange(valueLength, valueLength);
  }
  get twoWay() {
    return this.form?.get("twoWay")?.value;
  }

  getTransfer(id: any) {
    const getSub = this.transferService.getTransferData(id).subscribe({
      next: (res) => {
        getSub.unsubscribe();
        this.initForm(res);
      },
      error: (error) => {
        getSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translateService.instant("transfer.fill_all_fields"));
      return;
    }
    this.updateTransfer();
  }

  fetchTransfer() {
    const { name, departureDate, arrivalDate, departure, vehicle, destination, capacity, twoWay } = this.form.getRawValue();
    const data = {
      name,
      departureDate: moment(departureDate, "DD.MM.YYYY HH:mm"),
      arrivalDate: moment(arrivalDate, "DD.MM.YYYY HH:mm"),
      departureId: departure?.id,
      destinationId: destination?.id,
      vehicleOptionId: vehicle?.id,
      capacity: capacity,
      twoWay,
    };

    return data;
  }

  updateTransfer() {
    const data = this.fetchTransfer();
    this.isLoading = true;
    const updateSub = this.transferService.updateTransfer(this.transferId, data).subscribe({
      next: () => {
        this.isLoading = false;
        updateSub.unsubscribe();
        showMessage(PopupType.Success, this.translateService.instant("transfer.updated_transfer_successfully"));
        this.router.navigate(["/dashboard/transfers"]);
      },
      error: (error) => {
        this.isLoading = false;
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  toggleTwoWay() {
    const currentValue = this.form?.get("twoWay")?.value;
    this.form?.get("twoWay")?.setValue(!currentValue);
  }

  formattedDate(date: any) {
    const parsedDateTime = moment(date, "YYYY-MM-DD HH:mm");
    const formattedDateTime = parsedDateTime.format("DD.MM.YYYY HH:mm");
    return formattedDateTime;
  }
}
