import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { GroupTravelDestination, GroupTravelPlan } from "../models/group-travel";
@Component({
  selector: "app-group-travel-plan-details",
  templateUrl: "./group-travel-plan-details.component.html",
})
export class GroupTravelPlanDetailsComponent implements OnInit {
  groupTravelData!: GroupTravelPlan;
  id!: number;
  entityDetails: string | undefined;

  constructor(private groupTravelPassengersService: GroupTravelsService, private route: ActivatedRoute, public translate: TranslateService) {}

  ngOnInit(): void {
    this.id = +this.route.snapshot.params["id"];
    this.loadGroupTravelDetails();
  }

  loadGroupTravelDetails() {
    const subscription = this.groupTravelPassengersService.getGroupTravelPlans(this.id).subscribe((r) => {
      subscription.unsubscribe();
      this.groupTravelData = r;
      const mainDestination = r.groupTravel?.groupTravelDestinations?.find((d: GroupTravelDestination) => d.isMainDestination) || null;
      if (mainDestination && mainDestination.destination && mainDestination.hotel && mainDestination.start) {
        this.entityDetails =
          this.translate.instant("paymentsEntities.groupTravel") + ": " + mainDestination.destination.description + ", " + mainDestination.hotel.name + ", " + moment(mainDestination.start).format("DD.MM.YYYY");
      }
    });
  }
  refetch() {
    this.loadGroupTravelDetails();
  }
}
