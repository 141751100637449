import { Router } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { PrintPreviewService } from "../../services/print-preview.service";
import { PaymentDetail, PaymentDocument } from "src/app/domain/sales/models/payment-document";
import { PaymentService } from "./../../../domain/sales/services/payment.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
  @Input() title = "Confirmation";
  @Input() message = "Are you sure you want to proceed?";
  @Input() confirmButtonText = "Confirm";
  @Input() cancelButtonText = "Cancel";
  @Input() plan!: SaleBase;
  @Input() isOnlyAvans = false;
  @Input() navigateToRoute!: string;
  @Input() activeTab: number | undefined;
  @Input() paymentId!: number | undefined;
  @Input() entityDetails!: string | undefined;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() confirmed = new EventEmitter<boolean>();
  public paymentDocument?: PaymentDocument;
  public isLoading = false;

  constructor(public paymentService: PaymentService, private router: Router, public printPreviewService: PrintPreviewService) {}
  ngOnInit() {
    if (this.paymentId !== undefined) {
      this.showDocument();
    }
  }
  printInvoice() {
    this.isLoading = true;
    const subscription = this.paymentService.generateInvoiceHtml(this.plan.id, this.paymentId, this.isOnlyAvans).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }

  getCurrentDate(): Date {
    return new Date();
  }

  dismiss() {
    this.confirmed.emit(false);
    if (!this.navigateToRoute) {
      return;
    }
    this.router.navigate([this.navigateToRoute], {
      queryParams: {
        ...(this.activeTab !== undefined ? { activeTab: this.activeTab } : {}),
      },
    });
  }

  finishSending() {
    this.confirmed.emit(false);
    if (this.navigateToRoute) {
      this.router.navigate([this.navigateToRoute]);
    } else {
      this.router.navigate(["dashboard/sales"]);
    }
  }
  showDocument() {
    if (this.paymentId !== undefined) {
      const getPaymentSubscription = this.paymentService.getPaymentDocument(this.paymentId).subscribe((response) => {
        getPaymentSubscription.unsubscribe();
        this.paymentDocument = response;
      });
    }
  }
  parsePaymentDetails(paymentDetailsJson: string): PaymentDetail[] {
    try {
      const paymentDetails = JSON.parse(paymentDetailsJson);
      if (!Array.isArray(paymentDetails) || paymentDetails.length === 0) {
        return [];
      }
      return paymentDetails.map((payment: PaymentDetail) => ({
        PayedAt: payment.PayedAt,
        Amount: parseFloat(payment.Amount).toFixed(2),
      }));
    } catch (error) {
      return [];
    }
  }
}
