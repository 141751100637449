import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
})
export class MergeComponent<T> {
  @Input() items: T[] = [];
  @Input() selectedItemId!: number;
  @Output() merge = new EventEmitter<number[]>();
  @Input() bindLabel!: string;

  selectedItems: T[] = [];
  isLoading = false;
  maxSelection = 20; // Maximum number of items that can be selected

  // Method to trigger merge action
  onMerge() {
    const selectedIds = this.selectedItems.map(item => (item as any).id); 
    if (selectedIds.length > 0 && this.selectedItemId) {
      this.isLoading = true;
      this.merge.emit(selectedIds); // Emit selected items for merging
      this.isLoading = false;
    } else {
      console.warn('Please select items to merge');
    }
  }

  // Track function for performance optimization in *ngFor
  trackByFn(index: number, item: any): any {
    return item ? item.id || index : index;
  }

  // Clear the selected items
  clearSelection(): void {
    this.selectedItems = [];
  }
}
