import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Sale } from "../../sales/models/sale";
import { User } from "src/app/auth/model/user";
import { colDef } from "@bhplugin/ng-datatable";
import { WorkerData } from "../models/workerdata";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { ActivatedRoute, Router } from "@angular/router";
import { toggleAnimation } from "src/app/shared/animations";
import { WorkersService } from "../services/workers.service";
import { MessagePortalComponent } from "src/app/portal/portal";
import { updateSettings } from "src/app/store/auth/auth.actions";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { UserService } from "../../user-profile/service/user.service";
import { setFromTo } from "src/app/store/datepicker/datepicker.actions";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { RouterLinkHelper } from "src/app/shared/utils/router-link-helper";
import { MonthlyPlans, StatisticsCount, UserWorker } from "../models/worker";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { distinctUntilChanged, Observable, Subject, Subscription, switchMap, take, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-workers",
  templateUrl: "./worker.component.html",
  animations: [toggleAnimation],
})
export class WorkersComponent implements OnInit, OnDestroy {
  constructor(
    public workersService: WorkersService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private positioningService: PositioningService,
    private router: Router,
    public store: Store<AppState>,
    public navigationService: NavigationService,
    public userService: UserService
  ) {}

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  search = "";
  public sales!: Sale[];
  public worker: UserWorker = {} as UserWorker;
  public pageSize = 10;
  public pageNumber = 1;
  public totalRows = 1;
  public analytics!: string;
  public entityCounter!: StatisticsCount;
  public id!: number;
  public fullnameWorker!: string;
  public workerStats!: WorkerData;
  public monthlyChartData: MonthlyPlans[] = [];
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;
  private subscriptions = new Subscription();
  public from!: Date;
  public to!: Date | undefined;
  private langChangeSubscription!: Subscription;
  public cols: DatatableColumn[] = [];
  public user$!: Observable<User | undefined>;
  public user!: User;
  private destroy$ = new Subject<void>();
  public settings: any = {};

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.getColumns();
      this.initializeSelectors();
      this.getWorkerWithStatistics();
      this.getWorkerById();
      this.loadWorkerStats();
      this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
        this.preserveColumnSettings();
      });
    });
  }
  protected preserveColumnSettings(): void {
    this.cols = this.cols.map((col) => ({
      ...col,
      title: col.title === this.translate.instant(col.title) ? col.title : this.translate.instant(col.title),
    }));
  }

  protected initializeSelectors() {
    this.user$ = this.store.select(selectFeatureUser);
    this.datepick$ = this.store.select(selectFromTo);

    this.user$
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        switchMap((user) => {
          if (user) {
            this.user = user;
            this.settings = user.settings ? JSON.parse(user.settings) : {};
            this.restoreState(this.settings);
          }
          return this.datepick$;
        }),
        distinctUntilChanged((prev, curr) => moment(prev.from).isSame(moment(curr.from), "day") && (prev.to ? moment(prev.to).isSame(moment(curr.to), "day") : prev.to === curr.to)),
        takeUntil(this.destroy$)
      )
      .subscribe((datepick) => {
        if (datepick) {
          this.from = datepick.from;
          this.to = datepick.to;
          if (this.from) {
            this.loadWorkerStats();
            this.getWorkerWithStatistics();
            this.saveWorkerStatisticsSettings();
          }
        }
      });
  }

  private restoreState(settings: any) {
    if (settings?.workerStatisticsSettings) {
      const { from, to } = settings.workerStatisticsSettings;
      this.from = from ? new Date(from) : this.from;
      this.to = to ? new Date(to) : undefined;
      this.store.dispatch(setFromTo({ from: this.from, to: this.to }));
    }
  }

  private saveWorkerStatisticsSettings() {
    const workerStatisticsSettings = {
      from: this.from?.toISOString() || null,
      to: this.to?.toISOString() || null,
    };
    const updatedSettings = { ...this.settings, workerStatisticsSettings };

    if (this.user) {
      const userUpdates = { ...this.user, settings: JSON.stringify(updatedSettings) };
      this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
      this.userService.updateUser(this.user.id, userUpdates);
    }
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "entityName", title: this.translate.instant("entity"), hide: false } as DatatableColumn,
      { field: "customerFullName", title: this.translate.instant("customer"), hide: false } as DatatableColumn,
      { field: "destinations", title: this.translate.instant("destination.name"), hide: false } as DatatableColumn,
      { field: "numberOfPassangers", title: this.translate.instant("num_of_passangers"), hide: true } as DatatableColumn,
      { field: "getStatus", title: this.translate.instant("status"), hide: true } as DatatableColumn,
      { field: "bruto", title: "Bruto", hide: false } as DatatableColumn,
      { field: "depth", title: "Dept", hide: false } as DatatableColumn,
      { field: "createdAtFormated", title: this.translate.instant("sold"), hide: true } as DatatableColumn,
      { field: "actions", title: this.translate.instant("worker_info.actions"), hide: false } as DatatableColumn,
    ];
  }

  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }
  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.getWorkerWithStatistics();
  }

  getWorkerWithStatistics() {
    const today = new Date();
    const fromDate = new Date(this.from);
    const toDate = this.to ? new Date(this.to) : today;
    toDate.setMinutes(toDate.getMinutes() - toDate.getTimezoneOffset());
    fromDate.setMinutes(fromDate.getMinutes() - fromDate.getTimezoneOffset());
    const subscription = this.workersService.getWorkerSalesWithHeaders(this.id, this.pageNumber, this.pageSize, fromDate, toDate).subscribe((response) => {
      this.sales = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
      this.analytics = pagination.analytics;
      this.entityCounter = pagination.entityCounter;
      subscription.unsubscribe();
    });
  }

  getWorkerById() {
    this.workersService.getWorkerById(this.id).subscribe((response) => {
      this.worker = response;
      this.fullnameWorker = `${this.worker.name} ${this.worker.surname}`;
    });
  }
  loadWorkerStats() {
    const today = new Date();
    if (this.from) {
      const fromDate = this.from.toISOString();
      const toDate = this.to ? this.to.toISOString() : today.toISOString();
      const subscription = this.workersService.getWorkerStats(this.id, fromDate, toDate).subscribe({
        next: (response) => {
          this.workerStats = response;
          this.prepareMonthlyChartData();
          subscription.unsubscribe();
        },
        error: () => {
          subscription.unsubscribe();
        },
      });
    }
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  getRouterLink(value: any): string | undefined {
    return RouterLinkHelper.getRouterLink(value);
  }
  getIconAndTooltip(entityName: string, entityType?: string) {
    return RouterLinkHelper.getIconAndTooltip(entityName, entityType);
  }
  prepareMonthlyChartData() {
    const currentYear = this.from.getFullYear();

    if (!this.workerStats || !Array.isArray(this.workerStats.webChartData)) {
      console.error("Invalid workerStats or webChartData");
      return;
    }

    this.monthlyChartData = Array.from({ length: 12 }, (_, index) => ({
      year: currentYear,
      month: index + 1,
      numberOfPlans: 0,
    }));

    this.workerStats.webChartData.forEach((data: MonthlyPlans) => {
      if (data.year === currentYear) {
        const monthIndex = data.month - 1;
        this.monthlyChartData[monthIndex].numberOfPlans += data.numberOfPlans;
      }
    });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
