<div class="space-y-8">
  <div class="panel p-0 py-5">
    <div>
      <div class="relative z-[1]">
        <div class="absolute top-[30px] -z-[1] m-auto h-1 w-[15%] bg-primary transition-[width] ltr:left-0 rtl:right-0" [ngClass]="{ 'w-[25%]': activeTab === 1, 'w-[75%]': activeTab === 2 }"></div>
        <ul class="mb-5 grid grid-cols-2">
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
              (click)="proceedToNextStage(1)"
            >
              <icon-person></icon-person>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "transfer.passangers" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
              (click)="proceedToNextStage(2)"
            >
              <icon-thumbs-up></icon-thumbs-up>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "transfer.receipt" | translate }}</span>
          </li>
        </ul>
      </div>
      <div>
        <div [ngStyle]="isVisibleTab(1)">
          <div class="mx-5">
            <hr />
          </div>
          <app-passanger-list #passangerList (passangersSelected)="toggleButton($event)" (submitCustomer)="handleCustomerSubmit()"></app-passanger-list>
        </div>
        <div [ngStyle]="isVisibleTab(2)">
          <app-sale-create #sale></app-sale-create>
        </div>
      </div>
      <div class="flex justify-between px-5">
        <ng-container *ngIf="activeTab > 1; else placeholder">
          <button type="button" class="btn btn-primary" (click)="activeTab = activeTab - 1">
            {{ "transfer.back" | translate }}
          </button>
        </ng-container>
        <ng-template #placeholder>
          <div class="invisible"></div>
        </ng-template>
        <button #nextStepButton type="button" class="btn btn-primary" *ngIf="activeTab !== 2 && !isEditingPassanger" (click)="proceedToNextStage()">
          {{ "transfer.next" | translate }}
        </button>

        <button type="button" class="btn btn-primary" *ngIf="activeTab === 2" (click)="updateGroupTravelPassangers()">
          {{ "transfer.finish_sale" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
