<div>
  <div class="panel">
    <div class="mb-5">
      <div class="mb-4 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
        <div class="mb-4 sm:mb-0">
          <div class="text-center text-lg font-semibold ltr:sm:text-left rtl:sm:text-right">{{"calendar" | translate}}</div>
          <div class="mt-2 flex flex-wrap items-center justify-center sm:justify-start">
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-primary ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "travel-label" | translate }}</div>
            </div>
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-info ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "ticket-label" | translate }}</div>
            </div>
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-success ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "grouptravel-label" | translate }}</div>
            </div>
            <div class="flex items-center">
              <div class="h-2.5 w-2.5 rounded-sm bg-danger ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "transfer-label" | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-wrapper">
        <full-calendar #calendar [options]="calendarOptions" [deepChangeDetection]="true" *ngIf="calendarOptions">
          <ng-template #eventContent let-arg>
            <div class="fc-event-main-frame flex cursor-pointer items-center px-1 py-0.5 text-white">
              <div class="fc-event-time px-0.5 font-semibold">{{ arg.timeText }}</div>
              <div class="fc-event-title-container flex items-center">
                <ng-container *ngIf="arg.event.extendedProps?.eventType === 'start'">
                  <icon-top-right></icon-top-right>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps?.eventType === 'end'">
                  <icon-bottom-right></icon-bottom-right>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps?.type?.toLowerCase() === 'travel'">
                  <icon-suitcase></icon-suitcase>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps?.type?.toLowerCase() === 'ticket'">
                  <icon-airplane></icon-airplane>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps?.type?.toLowerCase() === 'grouptravel'">
                  <icon-group-travel></icon-group-travel>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps?.type?.toLowerCase() === 'transfer'">
                  <icon-transfer></icon-transfer>
                </ng-container>
                <div class="fc-event-title fc-sticky px-0.5 !font-medium">{{ arg.event?.title }}</div>
              </div>
            </div>
          </ng-template>
        </full-calendar>
      </div>
    </div>
    <div *ngIf="loading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
      <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
    </div>
  </div>
  <modal #isAddEventModal class="no-footer">
    <ng-template #modalBody>
      <container-element [ngSwitch]="selectedEvent.entityName">
        <app-transfer-quick-view [id]="selectedEvent.entityId" *ngSwitchCase="'Transfer'"> </app-transfer-quick-view>
        <app-group-travel-plan-quickview [id]="selectedEvent.additionalEntityId" *ngSwitchCase="'GroupTravel'"></app-group-travel-plan-quickview>
        <app-travel-quick-view [id]="selectedEvent.entityId" *ngSwitchCase="'Travel'"></app-travel-quick-view>
        <app-other-services-quick-view [id]="selectedEvent.entityId" *ngSwitchCase="'PlanService'"></app-other-services-quick-view>
        <app-ticket-quick-view [id]="selectedEvent.entityId" *ngSwitchCase="'Ticket'"></app-ticket-quick-view>

        <div class="mt-8 flex items-center justify-end gap-2">
          <button type="button" class="btn btn-danger gap-2" (click)="calendarService.navigateToDetails(selectedEvent.entityId, selectedEvent.entityName)">{{ "eventdetails.details" | translate }}</button>
          <button type="button" class="btn btn-outline-danger" (click)="isAddEventModal.close()">{{"eventdetails.close" |translate}}</button>
        </div>
      </container-element>
    </ng-template>
  </modal>
</div>
