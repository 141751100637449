import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { Travel } from "../../travels/models/travel";
import { TaskService } from "../services/task.service";
import { TranslateService } from "@ngx-translate/core";
import { SalesService } from "../services/sales.service";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  selector: "app-draft-table",
  templateUrl: "./draft.table.component.html",
  animations: [toggleAnimation],
})
export class DraftTableComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public taskService: TaskService,
    private modalService: ModalService,
    private salesService: SalesService,
    public translate: TranslateService,
    private positioningService: PositioningService
  ) {}

  cols!: DatatableColumn[];

  loading = false;

  public allTasks: Travel[] = [];
  private langChangeSubscription!: Subscription;

  pager = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    startIndex: 0,
    endIndex: 0,
  };

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  ngOnInit() {
    this.getColumns();
    this.fetchDrafts();
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.preserveColumnSettings();
    });
  }
  protected preserveColumnSettings(): void {
    this.cols = this.cols.map((col) => ({
      ...col,
      title: col.title === this.translate.instant(col.title) ? col.title : this.translate.instant(col.title),
    }));
  }

  private fetchDrafts() {
    this.loading = true;
    this.salesService.getDrafts().subscribe((response) => {
      this.allTasks = response;
      this.taskService.updateTasksLength(this.allTasks.length);
      this.loading = false;
    });
  }

  prepareAnouncement(id: number) {
    this.router.navigate(["/dashboard/travels/draft/accept/" + id], {
      queryParams: {
        activeTab: 4,
      },
    });
  }

  editDraftAndPrepare(id: number) {
    this.router.navigate(["/dashboard/travels/draft/edit/" + id], {
      queryParams: {
        activeTab: 3,
      },
    });
  }

  getStatusLabel(status: number) {
    switch (status) {
      case 0:
        return this.translate.instant("draft_table.pending");
        break;
      case 1:
        return this.translate.instant("draft_table.document_prepared");
        break;
      case 2:
        return this.translate.instant("draft_table.sent");
        break;
      case 3:
        return this.translate.instant("draft_table.approved");
        break;
      case 4:
        return this.translate.instant("draft_table.rejected");
        break;
    }
  }

  async deleteDraftTravel(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.salesService.deleteDraftTravel(id).subscribe({
        next: () => {
          showMessage(PopupType.Success, this.translate.instant("popup.successfully_deleted_started_sale"));
          this.fetchDrafts();
          deleteSubscription.unsubscribe();
        },
      });
    }
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("draft_table.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "customer", title: this.translate.instant("draft_table.customer"), hide: false } as DatatableColumn,
      { field: "destinations", title: this.translate.instant("draft_table.destinations"), hide: false } as DatatableColumn,
      { field: "days_nights", title: this.translate.instant("draft_table.days_nights"), hide: false } as DatatableColumn,
      { field: "date", title: this.translate.instant("draft_table.dates"), hide: false } as DatatableColumn,
      { field: "hotels", title: this.translate.instant("draft_table.hotels"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("draft_table.status"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("draft_table.actions"), hide: false } as DatatableColumn,
    ];
  }
}
