<div class="panel ng-tns-c45-3" style="background: linear-gradient(to right, #46b196, #6ec5af)">
  <div class="ng-tns-c45-3 flex justify-between">
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'purchases_made'">{{ 'worker.purchase_made' | translate}}</div>
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'tickets_sold'">{{ 'worker.tickets_sold' | translate}}</div>
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'travels_sold'">{{ 'worker.travels_sold' | translate}}</div>
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'transfers_sold'">{{ 'worker.transfers_sold' | translate}}</div>
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'group_travels_sold'">{{ 'worker.group_travels_sold' | translate}}</div>
    <div class="text-md ng-tns-c45-3 font-semibold ltr:mr-1 rtl:ml-1" *ngIf="name === 'other_services_sold'">{{ 'worker.other_services_sold' | translate}}</div>
  </div>
  <div class="ng-tns-c45-3 mt-5 flex items-center">
    <div class="ng-tns-c45-3 text-3xl font-bold ltr:mr-3 rtl:ml-3">{{value}}</div>
  </div>
</div>
