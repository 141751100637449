import { Store } from "@ngrx/store";
import { FormGroup } from "@angular/forms";
import { CalendarEvent } from "./models/event";
import { Observable, Subscription } from "rxjs";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import enLocale from "@fullcalendar/core/locales/en-gb";
import srLocale from "@fullcalendar/core/locales/sr-cyrl";
import { ActivatedRoute, Router } from "@angular/router";
import interactionPlugin from "@fullcalendar/interaction";
import { toggleAnimation } from "src/app/shared/animations";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarService } from "./services/calendar.service";
import { selectLocale } from "src/app/store/language/language.selector";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CalendarOptions, DateSelectArg, DatesSetArg, EventClickArg } from "@fullcalendar/core";

// TODO: fix remove all snake-case occurences to camelCase

@Component({
  moduleId: module.id,
  templateUrl: "./calendar.html",
  animations: [toggleAnimation],
})
export class CalendarComponent implements OnInit, OnDestroy {
  @ViewChild("calendar") calendar!: FullCalendarComponent;
  @ViewChild("isAddEventModal") isAddEventModal!: ModalComponent;

  params!: FormGroup;
  selectedEvent!: CalendarEvent;
  events: CalendarEvent[] = [];
  locale$!: Observable<string>;
  calendarOptions!: CalendarOptions;
  public loading!: boolean;

  routeSubscription!: Subscription;

  constructor(public storeData: Store<AppState>, public calendarService: CalendarService, public translate: TranslateService, public router: Router, private activatedRoute: ActivatedRoute) {}

  async ngOnInit() {
    this.loading = true;
    this.locale$ = this.storeData.select(selectLocale);
    await this.initStore();
    this.locale$.subscribe((locale) => {
      if (this.calendar) {
        const calendarApi = this.calendar.getApi();
        calendarApi.setOption("locale", locale == "en-US" ? "en-gb" : "sr-latn");
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  async initStore() {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      let currentMonth = parseInt(params["month"], 10);
      let currentYear = parseInt(params["year"], 10);

      if (isNaN(currentMonth) || currentMonth < 0 || currentMonth > 11) {
        currentMonth = new Date().getMonth();
      }
      if (isNaN(currentYear) || currentYear < 0) {
        currentYear = new Date().getFullYear();
      }

      if (this.calendar) {
        const calendarApi = this.calendar.getApi();
        calendarApi.gotoDate(new Date(currentYear, currentMonth, 1));
      }
      this.initCalendar(currentMonth, currentYear);
      this.calendarOptions.events = [];
      this.getEvents(currentYear, currentMonth);
    });
  }

  initCalendar(currentMonth: number, currentYear: number) {
    const initialDate = new Date(currentYear, currentMonth, 1);
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      locales: [enLocale, srLocale],
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "prev,today",
        center: "title",
        right: "today,next",
      },
      buttonText: {
        today: this.translate.instant("today"),
        month: this.translate.instant("month"),
        week: this.translate.instant("week"),
        day: this.translate.instant("day"),
        list: this.translate.instant("list"),
      },
      moreLinkContent: (args) => {
        return `${this.translate.instant("moreLink", { count: args.num })}`;
      },
      displayEventTime: false,
      initialDate: initialDate,
      editable: false,
      dayMaxEvents: true,
      defaultAllDay: true,
      selectable: false,
      droppable: false,
      eventClick: (event: EventClickArg) => {
        this.openEventModal(event);
      },
      select: (event: DateSelectArg) => {
        this.openDetailsOfEvent(event);
      },
      datesSet: (info: DatesSetArg) => {
        const newDate = info.view.currentStart;
        this.loading = true;
        this.setCurrentDateAsQueryParam(newDate.getMonth(), newDate.getFullYear());
      },
    };
  }

  setCurrentDateAsQueryParam(currentMonth: number, currentYear: number) {
    const queryParams = {
      month: currentMonth,
      year: currentYear,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
      skipLocationChange: false,
    });
  }

  getEvents(year: number, month: number) {
    const now = new Date();
    const currentYear = year || now.getFullYear();
    const currentMonth = month || now.getMonth();

    const calendarSubscription = this.calendarService.getEvents(currentYear, currentMonth).subscribe((events) => {
      calendarSubscription.unsubscribe();
      this.events = this.processEvents(events);

      this.calendarOptions.events = this.events;
      this.loading = false;
    });
  }

  processEvents(events: CalendarEvent[]): CalendarEvent[] {
    return events.reduce((acc: CalendarEvent[], e) => {
      const isGroupTravel = e.entityName === "GroupTravel";
      const isTransfer = e.entityName === "Transfer";

      acc.push({
        ...e,
        id: e.id,
        title: `${this.getLabel(e.entityName)}  ${e.destination.description}`,
        start: e.eventStarts,
        end: isGroupTravel || isTransfer ? e.eventEnds : e.eventStarts,
        className: this.calendarService.getClass(e.entityName),
        description: "Start of event",
        allDay: isGroupTravel || isTransfer,
        eventType: isGroupTravel || isTransfer ? "duration" : "start",
      });

      if (!isGroupTravel && !isTransfer && new Date(e.eventEnds).getTime() !== new Date(e.eventStarts).getTime()) {
        acc.push({
          ...e,
          id: e.id,
          title: `${this.getLabel(e.entityName)} ${e.destination.description}`,
          start: e.eventEnds,
          end: e.eventEnds,
          className: this.calendarService.getClass(e.entityName),
          description: "End of event",
          eventType: "end",
        });
      }

      return acc;
    }, []);
  }

  getLabel(entity: string) {
    return this.translate.instant(`${entity.split(" ").join("-").toLowerCase()}-label`);
  }

  getMonth(dt: Date, add = 0) {
    const month = dt.getMonth() + 1 + add;
    const str = (month < 10 ? "0" + month : month).toString();
    return str;
  }

  openDetailsOfEvent(data: any) {
    const obj = {
      event: {
        start: data.start,
        end: data.end,
      } as CalendarEvent,
    };
    this.openEventModal(obj);
  }

  openEventModal(data: any = null) {
    this.isAddEventModal.open();
    if (data) {
      const obj = JSON.parse(JSON.stringify(data.event));
      this.selectedEvent = obj.extendedProps;
    }
  }
}
