import * as moment from "moment";
import { Subscription } from "rxjs";
import { Travel } from "../models/travel";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { TravelsService } from "../services/travels.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelDestination } from "../models/travel-destination";
import { Contract } from "../../documents/contract/models/contract";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { ETouristStateService } from "../../sales/services/etourist-state.service";
import { ContractService } from "../../documents/contract/services/contract.service";

@Component({
  selector: "app-travel-details",
  templateUrl: "./travel.details.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class TravelDetailsComponent implements OnInit, OnDestroy {
  isShowingSidebar = false;
  pickerType = false;
  notFoundText = "";
  public id!: number;
  public planId!: number;
  protected travel!: Travel;
  public entityname = "travels";
  protected passangers!: Customer[];
  entityDetails: string | undefined;
  public fileUrl!: string | undefined;
  additionalDestinations: TravelDestination[] = [];
  protected mainDestination!: TravelDestination | undefined;
  isLoading = false;
  public emails: string[] = [];
  public checked = false;
  contract!: Contract;
  public subscription!: Subscription;

  @ViewChild("eturistModal") modal!: ModalComponent;
  @ViewChild("eturistPdfModal") modalPdf!: ModalComponent;
  @ViewChild("eturistUpdateModal") modalUpdate!: ModalComponent;

  constructor(
    private travelService: TravelsService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public etouristStateService: ETouristStateService,
    public contractService: ContractService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchTravelData();
        this.fetchPassangers();
      }
    });
    this.translate.stream("travel_details.additional_destNotText").subscribe((translation: string) => {
      this.notFoundText = translation;
    });
    this.etouristStateService.checked$.subscribe((status) => {
      this.checked = status;
    });
  }

  ngOnDestroy() {
    if (this.fileUrl) {
      URL.revokeObjectURL(this.fileUrl);
    }
    this.fileUrl = undefined;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fetchTravelData() {
    const subscription = this.travelService.getTravelData(this.id).subscribe((response) => {
      subscription.unsubscribe();
      this.travel = response;
      this.emails = this.travel.passangers.map((passanger) => passanger.email);
      this.mainDestination = this.travel.travelDestinations.find((dest) => dest.isMainDestination);
      this.additionalDestinations = this.travel.travelDestinations.filter((dest) => !dest.isMainDestination);
      this.planId = this.travel.planId;
      if (this.mainDestination && this.mainDestination.destination && this.mainDestination.hotel) {
        this.entityDetails =
          this.translate.instant("paymentsEntities.travel") +
          ": " +
          this.mainDestination.destination.description +
          ", " +
          this.mainDestination.hotel.name +
          ", " +
          moment(this.mainDestination.start).format("DD.MM.YYYY");
      }
    });
  }

  refetch() {
    this.fetchTravelData();
  }

  fetchPassangers() {
    const travelSubscription = this.travelService.getPassangersData(this.id).subscribe((response) => {
      travelSubscription.unsubscribe();
      this.passangers = response;
    });
  }

  addedRecordToEturist() {
    this.refetch();
    this.modal.close();
    this.modalPdf.close();
    this.modalUpdate.close();
  }

  addRecordsToETurist() {
    if (!this.travel.contractId) {
      this.subscription = this.contractService.getTravelContract(this.id).subscribe((response) => {
        this.contract = response;
        this.travel.contractId = response.id;
      });
    }

    if (!this.travel.start || moment(this.travel.start).isBefore(moment(), "day")) {
      showMessage(PopupType.Danger, "This can't be added since the travel is in past.");
      return;
    }

    this.modal.open();
  }

}
