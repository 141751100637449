import { Subscription } from "rxjs";
import { Picklist } from "../models/picklist";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { slideDownUp } from "src/app/shared/animations";
import { FormBuilder, Validators } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { SettingsService } from "../services/settings.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-settings-create",
  templateUrl: "./settings.create.component.html",
  animations: [slideDownUp],
})
export class SettingsCreateComponent implements OnInit, OnDestroy {
  getPicklistTypeSub!: Subscription;
  picklistType: Picklist[] = [];
  id: number | null = null;

  @Output() updateActiveAccordion = new EventEmitter<number | undefined>();
  @Input() type = "room-type";
  @Input() title = "Room Type";
  @Input() accordion!: number | undefined;
  @Input() orderNumber!: number;
  @ViewChild("modal") modal!: ModalComponent;
  isLoading = false;
  modalTitle!: string;
  modalButton!: string;
  @ViewChild("nameInput") nameInput!: ElementRef;

  form = this.fb.group({
    title: ["", Validators.required],
    description: ["", Validators.required],
  });

  constructor(private settingsService: SettingsService, private fb: FormBuilder, private translate: TranslateService, private modalService: ModalService) {}

  ngOnInit() {
    this.getPicklistType();
  }

  ngOnDestroy() {
    if (this.getPicklistTypeSub) {
      this.getPicklistTypeSub.unsubscribe();
    }
  }

  getPicklistType() {
    this.getPicklistTypeSub = this.settingsService.getPicklistType(this.type).subscribe({
      next: (res) => {
        this.picklistType = res;
        this.getPicklistTypeSub.unsubscribe();
      },
      error: (error) => {
        this.getPicklistTypeSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
      complete: () => {
        this.getPicklistTypeSub.unsubscribe();
      },
    });
  }

  openModal() {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }, 0);
    this.resetModal();
    this.modalTitle = this.translate.instant("settings.add_new_item");
    this.modalButton = this.translate.instant("settings.add");
    this.modal.open();
  }

  openModalForEditing(id: number) {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }, 0);
    this.id = id;
    const item = this.picklistType.find((option) => option.id === id);
    if (item) {
      this.form.patchValue({
        title: item.title,
        description: item.description,
      });
    }
    this.modalTitle = this.translate.instant("settings.edit_item");
    this.modalButton = this.translate.instant("settings.update");
    this.modal.open();
  }

  resetModal() {
    this.id = null;
    this.form.reset();
  }

  fetchItem() {
    const { title, description } = this.form.value;
    return {
      title,
      description,
      delimeter: this.type,
      value: this.type.replace("-", " "),
    };
  }

  addItem() {
    if (this.form.invalid) {
      showMessage(PopupType.Danger, this.translate.instant("popup.please_fill_required_fields"));
      return;
    }

    if (this.id === null) {
      const createSub = this.settingsService.addPicklistItem(this.fetchItem()).subscribe({
        next: () => {
          createSub.unsubscribe();
          this.getPicklistType();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("settings.added_successfully"));
        },
        error: (error) => {
          createSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    } else {
      const updateSub = this.settingsService.updatePicklistItem(this.fetchItem(), this.id).subscribe({
        next: () => {
          updateSub.unsubscribe();
          this.getPicklistType();
          this.modal.close();
          this.id = null;
          showMessage(PopupType.Success, this.translate.instant("settings.updated_successfully"));
        },
        error: (error) => {
          updateSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
  }

  setVisibleAccordion() {
    this.updateActiveAccordion.emit(this.accordion === this.orderNumber ? undefined : this.orderNumber);
  }
  async deletePicklistOption(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSub = this.settingsService.deletePicklistItem(id).subscribe({
        next: () => {
          deleteSub.unsubscribe();
          this.getPicklistType();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("popup.deleted_successfully"));
        },
        error: (error) => {
          deleteSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
  }
}
