import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class ExchangeRateService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  getExchangeRate(currency: string) {
    const apiUrl = `${this.apiUrl}/api/currency/exchangerate?currency=${currency}`;
    return this.http.get<number>(apiUrl);
  }
}
