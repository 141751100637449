import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MonthlyEarningsStatistics } from "./../../../domain/statisticsmoney/models/monthlyearnings";

@Component({
  selector: "app-montly-earnings-chart",
  templateUrl: "./montly-earnings-chart.component.html",
})
export class MontlyEarningsChartComponent implements OnInit, OnChanges {
  @Input() montlyearningEarnings: MonthlyEarningsStatistics | undefined;
  simpleColumnStackedSeries: any;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["montlyearningEarnings"] && changes["montlyearningEarnings"].currentValue) {
      this.updateChart();
    }
  }
  getMonthName(monthNumber: number): string {
    return this.translate.instant(`months.${monthNumber}`);
  }

  updateChart() {
    if (this.montlyearningEarnings && this.montlyearningEarnings.monthlyServiceEarningsStats) {
      const monthlyStats = this.montlyearningEarnings.monthlyServiceEarningsStats;
      const months = monthlyStats.map((item) => this.getMonthName(item.month));
      const ticketEarnings = monthlyStats.map((item) => item.ticketEarnings);
      const travelEarnings = monthlyStats.map((item) => item.travelEarnings);
      const groupTravelEarnings = monthlyStats.map((item) => item.groupTravelEarnings);
      const transferEarnings = monthlyStats.map((item) => item.transferEarnings);
      const planServicesEarnings = monthlyStats.map((item) => item.planServicesEarnings);

      const isDark = true;
      const isRtl = false;

      this.simpleColumnStackedSeries = {
        series: [
          { name: this.translate.instant("earnings.ticket_earnings"), data: ticketEarnings },
          { name: this.translate.instant("earnings.travel_earnings"), data: travelEarnings },
          { name: this.translate.instant("earnings.group_travel_earnings"), data: groupTravelEarnings },
          { name: this.translate.instant("earnings.transfer_earnings"), data: transferEarnings },
          { name: this.translate.instant("earnings.plan_services_earnings"), data: planServicesEarnings },
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#46b196", "#af4133", "#d4483b", "#fbcb3e", "#6a5acd"],
        xaxis: {
          categories: months,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" },
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 },
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val },
        },
      };
    }
  }
}
