import { ActivatedRoute, Router } from "@angular/router";
import { Sale } from "src/app/domain/sales/models/sale";
import { TranslateService } from "@ngx-translate/core";
import { OtherServices } from "../models/otherservices";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Component, OnInit, ViewChild } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Picklist } from "src/app/domain/settings/models/picklist";
import { UtilService } from "src/app/shared/services/util.service";
import { Customer } from "src/app/domain/customers/models/customer";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { OtherServicesService } from "../services/other-services.service";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { CheckoutService } from "src/app/checkouts/services/checkout.service";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";

@Component({
  selector: "app-other-services.edit",
  templateUrl: "./other-services.edit.component.html",
})
export class OtherServicesEditComponent implements OnInit {
  @ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  public note!: string | undefined;
  form!: FormGroup;
  otherServiceId!: number;
  PicklistType = PicklistType;
  public activeTab = 1;
  public customer!: Customer;
  public sale!: Sale;

  constructor(
    private fb: FormBuilder,
    private otherServicesService: OtherServicesService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public utilService: UtilService,
    public checkoutService: CheckoutService,
    public router: Router
  ) {
    this.form?.get("twoWay")?.valueChanges.subscribe((value) => {
      this.form?.get("twoWay")?.setValue(!value);
    });
    this.initForm({} as Picklist);
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id) {
        this.otherServiceId = parseInt(id);
        this.fetchEntities();
      }
    });
  }
  initForm(service: Picklist | undefined) {
    this.form = this.fb.group({
      service: [service, Validators.required],
    });
  }


  fetchEntities() {
    const getOtherServicesSubscription = this.otherServicesService.getOtherServiceData(this.otherServiceId).subscribe((response) => {
      const { customer, plan } = response;
      this.customer = customer;
      this.initForm(response.service);
      this.sales.initForm(plan);
      this.note = plan.notes;
      getOtherServicesSubscription.unsubscribe();
    });
  }

  async updateOtherServices() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("transfer.fill_all_fields"));
      return;
    }
    const updateSub = this.otherServicesService.updateOtherService(this.otherServiceId, this.createUpdateObject()).subscribe({
      next: () => {
        this.router.navigate(["dashboard/sales"]);
        updateSub.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("otherservices.updated_otherservices"));
      },
      error: (error) => {
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }
  createUpdateObject(): OtherServices | undefined {
    const { id } = this.customer;
    if (!id) {
      showMessage(PopupType.Danger, this.translate.instant("popup.customer_id_missing"));
      return;
    }
    this.sale = this.sales.getSale();
    this.sale.dueDate = DateTimeFormatter.formatDateToISO(this.sale.dueDate);

    const { service } = this.form.getRawValue();
    return {
      serviceId: service?.id,
      customerId: id,
      plan: { ...this.sale, customerId: id, notes: this.note },
      planId: this.sale.id,
    } as OtherServices;
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  async proceedToNextStage(nextStep: number | undefined = undefined) {
    this.customer = await this.customerComponent.getCustomer();
    this.activeTab = nextStep ?? this.activeTab + 1;
  }
}
