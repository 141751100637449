import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ETouristStateService {
  private checkedSubject = new BehaviorSubject<boolean>(false);

  checked$ = this.checkedSubject.asObservable();

  setChecked(status: boolean): void {
    this.checkedSubject.next(status);
  }

  getChecked(): boolean {
    return this.checkedSubject.value;
  }
}
