import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseHttpService } from "src/app/service/base.http.service";
import { CalendarEvent } from "../models/event";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CalendarService extends BaseHttpService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  getEvents(year: number, month: number) {
    const apiUrl = `${this.apiUrl}/api/plans/${year}/${month}`;
    return this.http.get<CalendarEvent[]>(apiUrl);
  }

  navigateToDetails(entityId: number, entityName: string) {
    let baseUrl: string;
    switch (entityName) {
      case "Travel":
        baseUrl = "/dashboard/travels";
        break;
      case "Ticket":
        baseUrl = "/dashboard/tickets/flight";
        break;
      case "GroupTravel":
        baseUrl = "/dashboard/group-travels";
        break;
      case "Transfer":
        baseUrl = "/dashboard/transfers";
        break;
      default:
        baseUrl = "/dashboard";
    }

    const url = `${baseUrl}/${entityId}`;
    this.router.navigateByUrl(url);
  }

  getClass(entity: string) {
    switch (entity) {
      case "Travel":
        return "bg-primary";
      case "Ticket":
        return "bg-info";
      case "GroupTravel":
        return "bg-success";
      case "Transfer":
        return "bg-danger";
      default:
        return "";
    }
  }
}
