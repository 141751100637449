import { createAction, props } from '@ngrx/store';

export const toggleDirection = createAction(
  '[Layout] Toggle Direction',
  props<{ direction: string }>()
);

export const toggleMainLoader = createAction(
  '[Layout] Toggle Main Loader',
  props<{ isShow: boolean }>()
);

export const setMainLayout = createAction(
  '[Layout] Set Main Layout',
  props<{ layout: string }>()
);

export const toggleTheme = createAction(
  '[Layout] Toggle Theme',
  props<{ theme: string }>()
);

export const toggleMenu = createAction(
  '[Layout] Toggle Menu',
  props<{ menu: string }>()
);

export const toggleLayout = createAction(
  '[Layout] Toggle Layout',
  props<{ layout: string }>()
);

export const toggleRTL = createAction(
  '[Layout] Toggle RTL',
  props<{ rtlClass: string }>()
);

export const toggleAnimation = createAction(
  '[Layout] Toggle Animation',
  props<{ animation: string }>()
);

export const toggleNavbar = createAction(
  '[Layout] Toggle Navbar',
  props<{ navbar: string }>()
);

export const toggleSemidark = createAction(
  '[Layout] Toggle Semidark',
  props<{ semidark: boolean }>()
);

export const toggleSidebar = createAction(
  '[Layout] Toggle Sidebar'
);
export const toggleButtonVisibility = createAction(
  '[Layout] Toggle Button Visibility',
  props<{ isVisible: boolean }>()
);
