<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <!-- <div hlMenu class="dropdown relative right-0 pt-1">
    <a hlMenuButton href="javascript:;" class="inline-block transform rounded-xl border-2 border-white-dark/100 bg-slate-100 p-2 transition-transform hover:scale-110 hover:shadow-lg">
      <icon-horizontal-dots class="rotate-90 opacity-70" />
    </a>
    <div #dropdownPlaceholder *hlMenuItems>
      <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
        <li>
          <a href="javascript:;" *hlMenuItem="let menuItem">
            <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
            {{ "edit_quick_label" | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:;" *hlMenuItem="let menuItem">
            <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
            {{ "delete_quick_label" | translate }}
          </a>
        </li>
      </app-message-portal>
    </div>
  </div> -->
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  <button type="button" class="btn btn-primary gap-2" *ngIf="customer && customer.id" (click)="openEditCustomerModal(customer)">
    <a class="flex items-center justify-center" href="javascript:;">
      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0"></icon-pencil-paper>
    </a>
  </button>
</div>
<div class="space-y-8" *ngIf="customer">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-1 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-1 flex grid grid-cols-1 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.basic_information" | translate }}
          </div>
        </div>

        <div class="col-span-1 flex items-center sm:col-span-2">
          <img [src]="customer.imageUrl ? customer.imageUrl : '/assets/images/auth/user-icon.png'" alt="" class="mr-4 h-8 w-8 rounded-md object-cover" />
          <div class="flex flex-col">
            <div class="text-white-dark">{{ "customer_details.name" | translate }} {{ "customer_details.surname" | translate }}</div>
            <div class="text-xl font-bold">{{ customer.name }} {{ customer.surname }}</div>
            <div class="text-[15px]" style="font-style: italic">
              {{ customer.phoneNumber }}
            </div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="flex flex-col p-2">
            <div class="text-[15px]">{{ "customer_details.phoneNumber" | translate }}:</div>
            <div class="pt-1 text-[18px]">{{ customer.phoneNumber }}</div>
          </div>
          <div class="flex flex-col p-2">
            <div class="text-[15px]>">{{ "customer_details.email" | translate }}:</div>
            <div class="pt-1 text-[18px]">{{ customer.email }}</div>
          </div>
          <div class="flex flex-col p-2 text-[15px]">
            <div lass="text-[15px]>">{{ "customer_details.address" | translate }}:</div>
            <div class="pt-1 text-[18px]">{{ customer.address }}</div>
          </div>
          <div class="flex flex-col p-2 text-[15px]">
            <div lass="text-[15px]>">{{ "customer_details.passport" | translate }}:</div>
            <div class="pt-1 text-[18px]">{{ customer.passport }}</div>
          </div>
        </div>
      </div>

      <div class="gric-cols-2 col-span-2 grid pl-2 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pl-2 sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.createdAt" | translate }} : {{ customer.createdAt | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.updatedAt" | translate }} : {{ customer.updatedAt | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.payments" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>
            <div>{{ "customer_stats.customer_money_stats.total_dept" | translate }}:</div>
            <div>{{ customer.customerMoneyStats.TotalDept | currency : "EUR" }}</div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>
            <div>{{ "customer_stats.customer_money_stats.total_revenue" | translate }}:</div>
            <div>{{ customer.customerMoneyStats.TotalRevenue | currency : "EUR" }}</div>
          </div>
        </div>
      </div>

      <div class="col-span-2 grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.services" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "customer_stats.customer_money_stats.number_of_not_paid_plans" | translate }}:{{ customer.customerMoneyStats.NumberOfNotPaidPlans }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "customer_stats.customer_money_stats.number_of_all_plans" | translate }}:{{ customer.customerMoneyStats.NumberOfAllPlans }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>
            {{ "customer_stats.customer_money_stats.number_of_different_destinations_visited" | translate }}:
            {{ customer.customerMoneyStats.NumberOfDifferentDestinationsVidited }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <h5 class="text-lg font-semibold dark:text-white-light">{{ "show_hide_columns" | translate }}</h5>

      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
										<input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <input [(ngModel)]="search" type="text" class="form-input" placeholder="{{ 'search' | translate }}..." />
        </div>
        <app-export-table
          [exportApi]="exportApiUrl"
          [filterType]="filterType"
          [sortColumn]="'none'"
          [sortDirection]="'asc'"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Hotel Table Export'"
          [additionalInfo]="additionalInfo"
        ></app-export-table>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [page]="pageNumber"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >


			<ng-template slot="entityId" let-value="data">
				<div class="dropdown inline-flex">
					<button
						*ngIf="getIconAndTooltip(value.entityName, value.type)"
						type="button"
						[ngxTippy]="tooltipTemplate"
						[tippyName]="value.id"
						[tippyContext]="{ data: value }"
						[tippyProps]="{ placement: 'right', interactive: true, maxWidth: 400, theme: 'light' }"
						class="btn btn-outline-secondary rounded-circle"
						[routerLink]="getRouterLink(value)"
						(mouseenter)="onMouseEnter(value.id)"
					>
						<div class="flex h-6 w-6 items-center gap-2">
							<app-dynamic-entity-icon [entityName]="value.entityName.toLowerCase()" [type]="value.type?.toLowerCase()"></app-dynamic-entity-icon>
						</div>
					</button>
				</div>
			</ng-template>
			
        <ng-template slot="createdAtFormated" let-value="data"> {{ value.createdAt | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="destinations" let-value="data">
          <span>{{ value.departure.description }}, {{ value.destination.description }}</span>
        </ng-template>
        <ng-template slot="status" let-value="data">
          <span *ngIf="value.status; else elseBlock" class="badge bg-success">{{ "paid" | translate }}</span>
          <ng-template #elseBlock>
            <span class="badge bg-danger">{{ "not-paid" | translate }}</span>
          </ng-template>
        </ng-template>
        <ng-template slot="bruto" let-value="data">{{ value.bruto | currency : "EUR" }}</ng-template>
        <ng-template slot="neto" let-value="data">{{ value.neto | currency : "EUR" }}</ng-template>

        <ng-template slot="depth" let-value="data">
          <span class="text-danger">{{ value.depth | currency : "EUR" }}</span>
        </ng-template>
      </ng-datatable>
    </div>
  </div>


	<ng-template #tooltipTemplate let-name let-data="data" class="bg-white">
    <container-element [ngSwitch]="data.entityName">
      <ng-container *ngIf="currentId === data.id">
        <app-transfer-plan-quickview [id]="data.additionalEntityId" *ngSwitchCase="'Transfer'"> </app-transfer-plan-quickview>
        <app-group-travel-plan-quickview [id]="data.additionalEntityId" *ngSwitchCase="'GroupTravel'"></app-group-travel-plan-quickview>
        <app-travel-quick-view [id]="data.entityId" *ngSwitchCase="'Travel'"></app-travel-quick-view>
        <app-other-services-quick-view [id]="data.entityId" *ngSwitchCase="'PlanService'"></app-other-services-quick-view>
        <app-ticket-quick-view [id]="data.entityId" *ngSwitchCase="'Ticket'"></app-ticket-quick-view>
      </ng-container>
    </container-element>
  </ng-template>

</div>
