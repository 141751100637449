import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { StatisticsDaily } from "src/app/domain/statisticsmoney/models/statisticmoneydaily";

@Component({
  selector: "app-daily-money-chart",
  templateUrl: "./daily-money-chart.component.html",
})
export class DailyMoneyChartComponent implements OnInit, OnChanges {
  @Input() dailymoneystatistic: StatisticsDaily | undefined;
  simpleColumnStackedSeries: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dailymoneystatistic"] && changes["dailymoneystatistic"].currentValue) {
      this.updateChart();
    }
  }

  getDayName(dayNumber: number): string {
    return `Day ${dayNumber}`;
  }

  updateChart() {
    if (this.dailymoneystatistic && this.dailymoneystatistic.monthlyServiceStats) {
      const sortedStats = this.dailymoneystatistic.monthlyServiceStats.sort((a, b) => {
        return a.day - b.day;
      });

      const days = sortedStats.map((item) => this.getDayName(item.day));
      const ticketCount = sortedStats.map((item) => item.ticketCount);
      const travelCount = sortedStats.map((item) => item.transferCount);
      const groupTravelCount = sortedStats.map((item) => item.groupTravelCount);
      const transferCount = sortedStats.map((item) => item.transferCount);
      const planServicesCount = sortedStats.map((item) => item.planServicesCount);

      const isDark = true;
      const isRtl = false;
      this.simpleColumnStackedSeries = {
        series: [
          { name: this.translate.instant("statisticsChart.ticketCount"), data: ticketCount },
          { name: this.translate.instant("statisticsChart.travelCount"), data: travelCount },
          { name: this.translate.instant("statisticsChart.groupTravelCount"), data: groupTravelCount },
          { name: this.translate.instant("statisticsChart.transferCount"), data: transferCount },
          { name: this.translate.instant("statisticsChart.planServicesCount"), data: planServicesCount },
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#46b196", "#af4133", "#d4483b", "#fbcb3e", "#6a5acd"],
        xaxis: {
          categories: days,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" },
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 },
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val },
        },
      };
    }
  }
}
