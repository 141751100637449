import { Store } from "@ngrx/store";
import { filter, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Sale } from "src/app/domain/sales/models/sale";
import { NavigationEnd, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { OtherServicesCreate } from "../models/otherservices";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Picklist } from "src/app/domain/settings/models/picklist";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { OtherServicesService } from "../services/other-services.service";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { SelectRemoteComponent } from "src/app/shared/components/select-remote/select-remote.component";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";
import { LocalStorageService } from "src/app/service/local-storage.service";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";

@Component({
  templateUrl: "./other-services-create.component.html",
  animations: [toggleAnimation],
})
export class OtherServicesCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
  @ViewChild("remoteSelect") remoteSelect!: SelectRemoteComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  @ViewChildren("submitBtn") submitBtn!: ElementRef;
  @ViewChild("nameInput") nameInput!: ElementRef;

  private routerSubscription!: Subscription;
  PicklistType = PicklistType;
  public sale!: Sale;
  note!: string;
  public form!: FormGroup;
  public customer!: Customer;
  public activeTab = 1;
  hasOtherServiceData = false;
  isLoading = false;

  constructor(
    public store: Store<AppState>,
    public translate: TranslateService,
    private otherServicesService: OtherServicesService,
    private router: Router,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {
    this.initForm({} as Picklist);
  }

  ngOnInit(): void {
    // Focus on form input if the component is loaded or reloaded
    setTimeout(() => {
      this.customerComponent.focusOnInput();
    }, 0);

    // Subscribe to router events to detect navigation changes
    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.customerComponent.focusOnInput(); // Focus on form input if navigated to this component
    });
    this.hasOtherServiceData = !!this.localStorageService.loadDataFromLocalStorage<string>("otherService");
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe(); // Clean up subscription
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.customerComponent.focusOnInput();
    }, 0);
    this.loadOtherServiceFromLocalStorage();
    this.cdr.detectChanges();
  }
  saveOtherServiceToLocalStorage() {
    const otherService = {
      customer: this.customerComponent.getCustomer(),
      note: this.note,
    };
    this.localStorageService.updateKey("otherService", JSON.stringify(otherService));
  }
  loadOtherServiceFromLocalStorage() {
    const otherService = this.localStorageService.loadDataFromLocalStorage<string>("otherService");
    if (otherService) {
      const { customer, note } = JSON.parse(otherService);
      this.customerComponent.initForm(customer);
      this.note = note;
    }
  }

  initForm(service: Picklist | undefined) {
    this.form = this.fb.group({
      service: [service, Validators.required],
    });
  }
  addService() {
    this.form.updateValueAndValidity();
    if (!(this.customerComponent.checkIfValid() && this.saleComponent.checkIfValid() && this.form.valid)) {
      showMessage(PopupType.Danger, this.translate.instant("popup.please_make_sure_all_forms_are_valid"));
      return;
    }
    this.isLoading = true;
    const createSubscriber = this.otherServicesService.createOtherService(this.createReservationObject()).subscribe(() => {
      this.isLoading = false;
      showMessage(PopupType.Success, this.translate.instant("popup.created_successfully"));
      createSubscriber.unsubscribe();
      this.router.navigate(["dashboard/sales"]);
      this.localStorageService.removeSpecificItem("otherService");
    });
  }

  createReservationObject(): OtherServicesCreate | undefined {
    this.customer = this.customerComponent.getCustomer();
    this.sale = this.saleComponent.getSale();
    this.sale.dueDate = DateTimeFormatter.formatDateToISO(this.sale.dueDate);
    const { service } = this.form.getRawValue();
    return {
      serviceId: service?.id,
      customerId: this.customer.id,
      plan: { ...this.sale, customerId: this.customer.id, notes: this.note },
      planId: this.sale.id,
    };
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  async proceedToNextStage(nextStep: number | undefined = undefined) {
    this.customer = this.customerComponent.getCustomer();

    this.activeTab = nextStep ?? this.activeTab + 1;
    this.setFocusField(this.activeTab);
    this.saveOtherServiceToLocalStorage();
  }

  setFocusField(nextStep: number) {
    switch (nextStep) {
      case 1:
        setTimeout(() => {
          this.customerComponent.focusOnInput();
        });
        break;
      case 2:
        setTimeout(() => {
          this.remoteSelect.focusField();
        });
        break;
      default:
        break;
    }
  }
  clearOtherServiceData(): void {
    this.localStorageService.removeSpecificItem("otherService");
    this.router.navigate(["dashboard/sales"]);
  }
}
