<div class="relative">
  <div class="absolute inset-0">
    <img src="/assets/images/auth/lock-screen-background.jpg" alt="image" class="fixed inset-0 h-full w-full object-cover" />
    <div class="fixed inset-0 bg-black opacity-0 dark:opacity-50"></div>
  </div>

  <div class="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 sm:px-16">
    <div class="absolute end-4 top-4">
      <div class="dropdown">
        <div hlMenu>
          <button type="button" hlMenuButton class="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black">
            <div>
              <img [src]="'/assets/images/flags/'+ langData.locale.toUpperCase() + '.svg'" alt="image" class="h-5 w-5 rounded-full object-cover" />
            </div>
            <div class="text-base font-bold uppercase">{{ langData.locale }}</div>
            <span class="shrink-0">
              <icon-caret-down />
            </span>
          </button>
          <ul
            *hlMenuItems
            @toggleAnimation
            class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark dark:text-white-dark dark:text-white-light/90 ltr:-right-14 sm:ltr:-right-2 rtl:-left-14 sm:rtl:-left-2"
            style="right: 0; padding-right: 1rem; @media (min-width: 640px) {right: auto; padding-right: 0}"
          >
            <li *ngFor="let item of langData.languageList;">
              <button *hlMenuItem="let menuItem" type="button" class="w-full hover:text-primary" [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }" (click)="changeLanguage(item)">
                <img class="h-5 w-5 rounded-full object-cover" [src]="'/assets/images/flags/'+ item.code.toUpperCase() + '.svg'" alt="" />
                <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="relative flex w-full max-w-[870px] justify-center rounded-[4rem] bg-white/60 p-2 backdrop-blur-lg dark:bg-black sm:bg-white">
      <div class="relative flex h-full w-full flex-col justify-center rounded-[4rem] px-6 py-5 dark:bg-black dark:bg-black/50 sm:w-[70%] sm:bg-white/60 lg:min-h-[600px]">
        <div class="flex h-full flex-col justify-between">
          <div class="flex flex-col items-center">
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center">
                <div>
                  <h2 class="text-2xl font-bold dark:text-white">{{'reset_password.send_request' | translate}}</h2>
                </div>
              </div>
              <icon-logo class="hidden md:inline-block"></icon-logo>
            </div>
          </div>
          <form class="flex flex-grow flex-col justify-center dark:text-white" [formGroup]="resetForm" (ngSubmit)="submitForm()" novalidate>
            <div class="space-y-5">
              <div [ngClass]="resetForm.controls['email'].touched ? (resetForm.controls['email'].errors ? 'has-error' : 'has-success') : ''">
                <label for="Email" class="dark:text-white">{{'reset_password.email' | translate}}</label>
                <div class="relative text-white-dark">
                  <input
                    formControlName="email"
                    id="Email"
                    type="text"
                    [placeholder]="'reset_password.enter_email' | translate"
                    class="form-input rounded-[12px] border border-black ps-5 placeholder:text-white-dark"
                  />
                </div>
                <small class="text-danger" *ngIf="resetForm.controls['email'].touched && resetForm.controls['email'].errors">{{ "contractsendemail.email_to_send" | translate }}</small>
              </div>
              <button type="submit" class="btn btn-gradient w-full rounded-[1rem] border-0 bg-black px-6 py-4 text-lg uppercase text-white shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] dark:bg-danger">
                <ng-container *ngIf="!loading; else loadingTemplate">{{'reset_password.send' | translate}}</ng-container>
                <ng-template #loadingTemplate>
                  <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{'loading' | translate}}
                </ng-template>
              </button>
            </div>
          </form>
          <div class="mt-10 flex justify-center">
            <img src="/assets/images/logo-text.svg" alt="logo" class="w-[300px] flex-none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
