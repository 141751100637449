import { Store } from "@ngrx/store";
import { Transfer } from "../models/transfer";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { TransfersService } from "../../transfers/services/transfers.service.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { UserService } from "../../user-profile/service/user.service";

@Component({
  moduleId: module.id,
  templateUrl: "./transfers.table.component.html",
  animations: [toggleAnimation],
})
export class TransfersTableComponent extends BaseTableComponent<Transfer> implements OnInit {
  public type = "flight";
  public transfers!: Transfer[];
  public filteredItems: any[] = [];
  transferToClone: any;
  public currentId!: number;

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  @ViewChild("cloneConfirmationModal")
  cloneConfirmationModal!: ModalComponent;
  constructor(
    private modalService: ModalService,
    public override store: Store<AppState>,
    public override translate: TranslateService,
    private transferService: TransfersService,
    public override tableService: TableService,
    private positioningService: PositioningService,
    public override userService: UserService
  ) {
    super(store, tableService, `transfers/paginated`, userService, translate);
  }
  override ngOnInit() {
    super.ngOnInit();
  }

  override getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("transfer.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("travels.id"), hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("transfer.name"), hide: false, sort: false } as DatatableColumn,
      { field: "capacity", title: this.translate.instant("transfer.capacity"), hide: false } as DatatableColumn,
      { field: "vehicleOptionId", title: this.translate.instant("transfer.vehicle"), hide: false } as DatatableColumn,
      { field: "departureDate", title: this.translate.instant("transfer.departure_date"), hide: false } as DatatableColumn,
      { field: "arrivalDate", title: this.translate.instant("transfer.return_date"), hide: false } as DatatableColumn,
      { field: "departureId", title: this.translate.instant("transfer.departure"), hide: false } as DatatableColumn,
      { field: "destinationId", title: this.translate.instant("transfer.destination"), hide: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("transfer.details"), hide: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "id", title: this.translate.instant("transfers-export.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "numberOfPassengers", title: this.translate.instant("transfers-export.numberOfPassengers"), hide: false, sort: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("transfers-export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("transfers-export.destination"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("transfers-export.returnDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("transfers-export.bruto"), hide: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("transfers-export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("transfers-export.status"), hide: true, sort: false } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("transfers-export.saleDate"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("transfers-export.user"), hide: true, sort: false } as DatatableColumn,
  ];

  getTransfers() {
    const transfersSubscription = this.transferService.getTransfers().subscribe({
      next: (res) => {
        this.transfers = res;
        transfersSubscription.unsubscribe();
      },
      error: (err) => {
        showMessage(PopupType.Danger, err.toString());
        transfersSubscription.unsubscribe();
      },
    });
  }
  async createDuplicate(value: Transfer) {
    const title = this.translate.instant("clone.title");
    const message = this.translate.instant("clone.message");

    const confirmed = await this.modalService.confirm({
      title: title,
      message: message,
      confirmButtonText: this.translate.instant("common.confirm"),
      cancelButtonText: this.translate.instant("common.cancel"),
    });

    if (confirmed) {
      this.transferToClone = value.id;
      this.cloneTransfer();
    }
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  cloneTransfer() {
    const cloneSubscription = this.transferService.createTransferClone(this.transferToClone).subscribe({
      next: () => {
        cloneSubscription.unsubscribe();
        this.updatePaginationProps.next(this.getPaginationParams());
        showMessage(PopupType.Success, this.translate.instant("popup.object_cloned_successfully"));
        this.cloneConfirmationModal.close();
      },
      error: (err) => {
        cloneSubscription.unsubscribe();
        showMessage(PopupType.Danger, err.toString());
      },
    });
  }

  async deleteTransfer(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.transferService.deleteTransfer(id).subscribe({
        next: () => {
          this.fetchItems(this.getPaginationParams());
          deleteSubscription.unsubscribe();
        },
        error: (err) => {
          showMessage(PopupType.Danger, err.toString());
          this.fetchItems(this.getPaginationParams());
          deleteSubscription.unsubscribe();
        },
      });
    }
  }
  onMouseEnter(id: number): void {
    this.currentId = id;
  }
}
