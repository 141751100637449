import { Observable } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/service/auth.service";
import { UserRole } from "src/app/shared/enums/user-roles";

@Component({
  selector: "app-settings-list",
  templateUrl: "./settings-list.component.html",
})
export class SettingsListComponent implements OnInit {
  activeTab = "office-branches";
  accordion: number | undefined = 1;
  $userRole!: Observable<string>;
  officeBranchManager = UserRole.OfficeBranchManager;
  constructor(public translate: TranslateService, private route: ActivatedRoute, private router: Router, private authService: AuthService) {}
  ngOnInit(): void {
    this.route.url.subscribe((url) => {
      const currentTab = this.route.snapshot.firstChild?.routeConfig?.path;
      if (currentTab) {
        this.activeTab = currentTab;
      }
    });
    this.$userRole = this.authService.getUserRole();
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.router.navigate([`dashboard/settings/${tab}`]);
  }
  updateAccordion(newRow: number | undefined) {
    this.accordion = newRow;
  }

  typeList = ["room-type", "room-content", "arrangement", "service", "other-services", "payment-type"];
  titleList = ["settings.room_type", "settings.room_content", "settings.arrangements", "settings.service", "settings.other_service", "settings.payment_type"];
}
