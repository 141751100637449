import { Store } from "@ngrx/store";
import { from, map, Observable, tap } from "rxjs";
import { Company } from "../models/company";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { CompaniesService } from "../services/companies.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { TableService } from "src/app/shared/services/table.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";
import { UserRole } from "src/app/shared/enums/user-roles";

@Component({
  templateUrl: "./companies.table.component.html",
  animations: [toggleAnimation],
})
export class CompaniesTableComponent extends BaseTableComponent<Company> implements OnInit {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  @ViewChild("modal") modal!: ModalComponent;

  public companies: Company[] = [];
  editMode = false;
  companyId = 0;
  foundCompany: Company[] = [];
  filteredCompanies: Company[] = [];
  selectedCompanyIds: number[] = [];
  company!: Observable<Company[]>;
  mergeMode = false;
  name = "";
  term!: string;
  selectedCompany: Company[] = [];
  companyName!: string;
  isLoading = false;
  companyData: any = {};
  adminRole = UserRole.Admin;
  officeBranchManagerRole = UserRole.OfficeBranchManager;

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
  constructor(
    private modalService: ModalService,
    public companiesService: CompaniesService,
    public override translate: TranslateService,
    public override tableService: TableService,
    public override store: Store<AppState>,
    private positioningService: PositioningService,
    public override userService: UserService
  ) {
    super(store, tableService, `companies/paginated`, userService, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.loadCompanies();
  }

  loadCompanies() {
    this.filteredCompanies = [];
    this.foundCompany = [];
    this.selectedCompanyIds = [];
    this.company = this.companiesService.getCompany().pipe(map((company: Company[]) => company.filter((organizer) => organizer.id !== this.companyId)));
  }
  addMerge(id: number, name: any) {
    this.companyId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open();
    this.foundCompany = [];
    this.filteredCompanies = [];
    this.term = "";
  }
  addCompany(): void {
    from(this.modalService.openCompanyModal())
      .pipe(
        tap((result) => {
          if (result) {
            this.fetchItems(this.getPaginationParams());
            this.loadCompanies();
            showMessage(PopupType.Success, this.translate.instant("company_data.added_company_successfully"));
          }
        })
      )
      .subscribe();
  }

  updateCompany(company: Company): void {
    from(this.modalService.openCompanyModal(company.id))
      .pipe(
        tap((result) => {
          if (result) {
            this.fetchItems(this.getPaginationParams());
            this.loadCompanies();
            showMessage(PopupType.Success, this.translate.instant("company_data.updated_company_successfully"));
          }
        })
      )
      .subscribe();
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("company_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("company_info.name"), hide: false } as DatatableColumn,
      { field: "ticketsCount", title: this.translate.instant("company_info.num_of_tickets"), hide: false, sort: false } as DatatableColumn,
      { field: "numberOfPeople", title: this.translate.instant("company_info.numberOfPeople"), hide: false, sort: false } as DatatableColumn,
      { field: "earnings", title: this.translate.instant("company_info.earnings"), hide: false, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("company_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("company_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("company_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numOfTickets", title: this.translate.instant("company_export.numOfTickets"), hide: false } as DatatableColumn,
    { field: "createdAt", title: this.translate.instant("company_export.createdAt"), hide: false } as DatatableColumn,
    { field: "numberOfPeople", title: this.translate.instant("company_export.numberOfPeople"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("company_export.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("company_export.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("company_export.profit"), hide: false } as DatatableColumn,
    { field: "purchaseDate", title: this.translate.instant("company_export.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("company_export.user"), hide: false } as DatatableColumn,
  ];

  mergeSelectedCompany(selectedCompanyIds: number[]) {
    if (selectedCompanyIds.length > 0 && this.companyId) {
      this.isLoading = true;
      const mergeSubscription = this.companiesService.mergeCompany(this.companyId, selectedCompanyIds).subscribe({
        next: () => {
          this.fetchItems(this.getPaginationParams());
          this.loadCompanies();
          showMessage(PopupType.Success, this.translate.instant("company_data.companies_merged_successfully"));
          this.isLoading = false;
          this.modal.close();
          mergeSubscription.unsubscribe();
        },
        error: () => {
          showMessage(PopupType.Warning, this.translate.instant("company_data.error_merging_companies"));
          this.isLoading = false;
          mergeSubscription.unsubscribe();
        },
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("company_data.select_companies_to_merge"));
    }
  }
  async deleteCompany(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.companiesService.deleteCompany(id).subscribe({
        next: () => {
          this.fetchItems(this.getPaginationParams());
          this.loadCompanies();
          this.isLoading = false;
          deleteSubscription.unsubscribe();
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
}
