import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { ModalService } from "src/app/shared/services/modal.service";
import { GroupTravelsService } from "../services/group-travels.service";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { GroupTravel, GroupTravelPassanger, GroupTravelPlanAccumulator, GroupTravelPreview } from "../models/group-travel";

@Component({
  selector: "app-group-travel-passangers-table",
  templateUrl: "./group-travel-passangers.table.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class GroupTravelPassangersTableComponent implements OnInit {
  @Input() groupTravelId: number | undefined;
  public exportApiUrl!: string;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  public pageSize = 10;
  public totalRows = 1;
  groupTravel!: GroupTravelPreview;
  tooltipMessage!: string;

  groupTravelPassangers!: any;
  cols = [
    { field: "serialNumber", title: this.translate.instant("group_travel_table.serial_number"), hide: false, sort: false } as DatatableColumn,
    { field: "id", title: this.translate.instant("group_travel_table.id"), hide: true, sort: false } as DatatableColumn,
    { field: "numberPassangers", title: this.translate.instant("group_travel_table.companions"), hide: false, sort: false } as DatatableColumn,
    { field: "name", title: this.translate.instant("group_travel_table.firstname"), hide: false, sort: false } as DatatableColumn,
    { field: "surname", title: this.translate.instant("group_travel_table.lastname"), hide: false, sort: false } as DatatableColumn,
    { field: "dateOfBirth", title: this.translate.instant("group_travel_table.date_of_birth"), hide: true, sort: false } as DatatableColumn,
    { field: "address", title: this.translate.instant("group_travel_table.address"), hide: true, sort: false } as DatatableColumn,
    { field: "phoneNumber", title: this.translate.instant("group_travel_table.phone_number"), hide: false, sort: false } as DatatableColumn,
    { field: "totalPrice", title: this.translate.instant("group_travel_table.total_price"), hide: false, sort: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("group_travel_table.status"), hide: false, sort: false } as DatatableColumn,
    { field: "avans", title: this.translate.instant("group_travel_table.avans"), hide: false, sort: false } as DatatableColumn,
    { field: "paymentType", title: this.translate.instant("group_travel_table.payment_type"), hide: false, sort: false } as DatatableColumn,
    { field: "actions", title: this.translate.instant("group_travel_table.actions"), hide: false, sort: false } as DatatableColumn,
  ];
  public exportCols = [
    { field: "id", title: this.translate.instant("grouptravel_export.planId"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("grouptravel_export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("grouptravel_export.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("grouptravel_export.destination"), hide: false } as DatatableColumn,
    { field: "roomType", title: this.translate.instant("grouptravel_export.roomType"), hide: false } as DatatableColumn,
    { field: "serviceType", title: this.translate.instant("grouptravel_export.serviceType"), hide: true, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("grouptravel_export.bruto"), hide: true, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("grouptravel_export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "eTuristStatus", title: this.translate.instant("grouptravel_export.eTuristStatus"), hide: true, sort: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("grouptravel_export.status"), hide: true, sort: false } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("grouptravel_export.saleDate"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("grouptravel_export.user"), hide: true } as DatatableColumn,
  ];
  treeview1: any = [];

  rows: any[] = [];
  jsonData = this.rows;

  constructor(
    private groupTravelService: GroupTravelsService,
    private router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private positioningService: PositioningService,
    private modalService: ModalService
  ) {}
  ngOnInit(): void {
    this.fetchTravelData();
    this.tooltipMessage = "descriptions.noPassengers";
  }
  updateColumn(col: DatatableColumn) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }

  toggleTreeview(id: string) {
    const index = this.treeview1.indexOf(id);
    if (index > -1) {
      this.treeview1.splice(index, 1);
    } else {
      this.treeview1.push(id);
    }
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  fetchTravelData() {
    if (this.groupTravelId !== undefined) {
      const subscription = this.groupTravelService.getGroupTravelData(this.groupTravelId).subscribe((response) => {
        this.groupTravel = response;
        subscription.unsubscribe();
        this.groupTravelPassangers = this.transformGroupTravelData(this.groupTravel);
        this.exportApiUrl = `api/GroupTravels/group-travels/${this.groupTravelId}`;

      });
    }
  }

  transformGroupTravelData(groupTravel: GroupTravelPreview) {
    let serialCounter = 1;
    const groupedData = groupTravel.groupTravelPlans?.reduce((acc: GroupTravelPlanAccumulator, groupTravelPlan) => {
      const { customer, groupTravelPassangers } = groupTravelPlan;
      let passengers: GroupTravelPassanger[] = groupTravelPassangers || [];
      passengers = passengers.filter((passenger) => passenger?.customer?.id !== customer?.id);

      if (!acc[groupTravelPlan.id]) {
        acc[groupTravelPlan.id] = {
          planStatus: groupTravelPlan.plan.status,
          planId: groupTravelPlan.planId,
          groupTravelPlanId: groupTravelPlan.id,
          totalPrice: groupTravelPlan.plan.bruto - groupTravelPlan.plan.neto,
          mainCustomer: customer,
          passengers: [],
          paymentType: groupTravelPlan.plan.paymentType?.title,
          avans: groupTravelPlan.plan.avans ?? 0,
          serialNumber: serialCounter,
        };
        serialCounter += 1 + passengers.length;
      }
      acc[groupTravelPlan.id].passengers.push(...passengers);
      return acc;
    }, {} as GroupTravelPlanAccumulator);

    return Object.values(groupedData);
  }

  excelColumns() {
    return {
      Id: "id",
      FirstName: "firstName",
      LastName: "lastName",
      DateOfBirth: "dateOfBirth",
      Address: "address",
      Contact: "contaxt",
      LifeStage: "lifeStage",
      PassangerState: "status",
      TotalPrice: "totalPrice",
      GroupNumber: "groupNumber",
    };
  }

  excelItems() {
    return this.rows;
  }

  async deletePassanger(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.groupTravelService.deleteGroupTravelPassanger(id).subscribe({
        next: () => {
          this.fetchTravelData();
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("popup.deleted_passenger_successfully"));
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
}
