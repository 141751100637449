<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
  <div class="relative col-span-1 pb-5 text-center sm:col-span-2 lg:col-span-3">
    <h4 class="mb-1 dark:text-white">{{ "layout.templateCustomizer" | translate }}</h4>
    <p class="text-white-dark">{{ "layout.setPreferences" | translate }}</p>
  </div>

  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.colorScheme" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.overallLightOrDarkPresentation" | translate }}</p>
    <div class="mt-3 grid grid-cols-3 gap-2">
      <button type="button" class="btn" [ngClass]="[(theme$ | async) === 'light' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleTheme('light')">
        <icon-sun class="h-5 w-5 shrink-0 ltr:mr-2 rtl:ml-2" />
        {{ "layout.light" | translate }}
      </button>
      <button type="button" class="btn" [ngClass]="[(theme$ | async) === 'dark' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleTheme('dark')">
        <icon-moon class="h-5 w-5 shrink-0 ltr:mr-2 rtl:ml-2" />
        {{ "layout.dark" | translate }}
      </button>
      <button type="button" class="btn" [ngClass]="[(theme$ | async) === 'system' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleTheme('system')">
        <icon-laptop class="h-5 w-5 shrink-0 ltr:mr-2 rtl:ml-2" />
        {{ "layout.system" | translate }}
      </button>
    </div>
  </div>
  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.navigationPosition" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.selectPrimaryNavigationParadigm" | translate }}</p>
    <div class="mt-3 grid grid-cols-3 gap-2">
      <button type="button" class="btn" [ngClass]="[(menu$ | async) === 'horizontal' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleMenu('horizontal')">
        {{ "layout.horizontal" | translate }}
      </button>
      <button type="button" class="btn" [ngClass]="[(menu$ | async) === 'vertical' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleMenu('vertical')">
        {{ "layout.vertical" | translate }}
      </button>
      <button type="button" class="btn" [ngClass]="[(menu$ | async) === 'collapsible-vertical' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleMenu('collapsible-vertical')">
        {{ "layout.collapsible" | translate }}
      </button>
    </div>
    <div class="mt-5 text-primary">
      <label class="mb-0 inline-flex">
        <input [ngModel]="semidark$ | async" type="checkbox" class="form-checkbox" (ngModelChange)="toggleSemidark($event)" />
        <span>{{ "layout.semiDark" | translate }}</span>
      </label>
    </div>
  </div>
  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.showNetPriceTitle" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.selectLayoutStyle" | translate }}</p>
    <div class="mt-3 flex gap-2">
      <label class="mb-0 inline-flex">
        <input [ngModel]="isVisible$ | async" type="checkbox" class="form-checkbox" (ngModelChange)="toggleVisibility($event)" />
        <span>{{ "layout.showNetPrice" | translate }}</span>
      </label>
    </div>
  </div>
  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.direction" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.selectDirection" | translate }}</p>
    <div class="mt-3 flex gap-2">
      <button type="button" class="btn flex-auto" [ngClass]="[(rtlClass$ | async) === 'ltr' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleRtl('ltr')">
        {{ "layout.ltr" | translate }}
      </button>
      <button type="button" class="btn flex-auto" [ngClass]="[(rtlClass$ | async) === 'rtl' ? 'btn-primary' : 'btn-outline-primary']" (click)="toggleRtl('rtl')">
        {{ "layout.rtl" | translate }}
      </button>
    </div>
  </div>
  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.navbarType" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.stickyOrFloating" | translate }}</p>
    <div class="mt-3 flex items-center gap-3 text-primary">
      <label class="mb-0 inline-flex">
        <input [checked]="(navbar$ | async) === 'navbar-sticky'" type="radio" value="navbar-sticky" class="form-radio" (change)="toggleNavbar('navbar-sticky')" />
        <span>{{ "layout.sticky" | translate }}</span>
      </label>
      <label class="mb-0 inline-flex">
        <input [checked]="(navbar$ | async) === 'navbar-floating'" type="radio" value="navbar-floating" class="form-radio" (change)="toggleNavbar('navbar-floating')" />
        <span>{{ "layout.floating" | translate }}</span>
      </label>
      <label class="mb-0 inline-flex">
        <input [checked]="(navbar$ | async) === 'navbar-static'" type="radio" value="navbar-static" class="form-radio" (change)="toggleNavbar('navbar-static')" />
        <span>{{ "layout.static" | translate }}</span>
      </label>
    </div>
  </div>

  <div class="mb-3 rounded-md border border-dashed border-[#e0e6ed] p-3 dark:border-[#1b2e4b]">
    <h5 class="mb-1 text-base leading-none dark:text-white">{{ "layout.routerTransition" | translate }}</h5>
    <p class="text-xs text-white-dark">{{ "layout.animationOfMainContent" | translate }}</p>
    <div class="mt-3">
      <select [ngModel]="animation$ | async" class="form-select border-primary text-primary" (ngModelChange)="toggleAnimation($event)">
        <option value="">{{ "layout.none" | translate }}</option>
        <option value="animate__fadeIn">{{ "layout.fade" | translate }}</option>
        <option value="animate__fadeInDown">{{ "layout.fadeInDown" | translate }}</option>
        <option value="animate__fadeInUp">{{ "layout.fadeInUp" | translate }}</option>
        <option value="animate__fadeInLeft">{{ "layout.fadeInLeft" | translate }}</option>
        <option value="animate__fadeInRight">{{ "layout.fadeInRight" | translate }}</option>
        <option value="animate__slideInDown">{{ "layout.slideInDown" | translate }}</option>
        <option value="animate__slideInLeft">{{ "layout.slideInLeft" | translate }}</option>
        <option value="animate__slideInRight">{{ "layout.slideInRight" | translate }}</option>
        <option value="animate__zoomIn">{{ "layout.zoomIn" | translate }}</option>
      </select>
    </div>
  </div>
</div>
