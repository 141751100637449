<div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>

<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <button class="btn btn-danger gap-2" href="/apps/invoice/edit">
    <icon-trash></icon-trash>
    {{ "daily_sale_file.delete" | translate }}
  </button>
  <button class="btn btn-success gap-2" (click)="saveDocument()">
    <icon-change></icon-change>
    {{ "daily_sale_file.save" | translate }}
  </button>
</div>
<div class="panel">
  <div class="mt-4">
    <p class="text-center text-2xl font-semibold">{{ "daily_sale_file.name" | translate }} {{ dailySaleDocument.createdAt | date : "dd.MM.yyyy" }}</p>
  </div>
  <hr class="my-6 border-[#e0e6ed] dark:border-[#1b2e4b]" />

  <div class="grid grid-cols-1 sm:grid-cols-1">
    <table class="grid-cols table-responsive col-span-1 grid grid w-full border-2 border-gray-400 sm:col-span-12">
      <thead>
        <tr class="grid grid-cols-2 sm:grid-cols-12">
          <th class="w-1/12 sm:col-span-1">{{ "daily_sale_file.serial_number" | translate }}</th>
          <th class="w-3/12 sm:col-span-3">{{ "daily_sale_file.ledger_entry" | translate }}</th>
          <th class="w-3/12 sm:col-span-3">{{ "daily_sale_file.description" | translate }}</th>
          <th class="w-2/12 sm:col-span-2">{{ "daily_sale_file.entry" | translate }}</th>
          <th class="w-2/12 sm:col-span-2">{{ "daily_sale_file.exit" | translate }}</th>
          <th class="w-1/12 sm:col-span-1">{{ "daily_sale_file.account_number" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="dailySaleDocument?.items && dailySaleDocument.items.length > 0">
        <ng-container *ngFor="let item of dailySaleDocument.items; let i = index">
          <tr class="ng-star-inserted grid grid-cols-2 sm:grid-cols-12">
            <td class="sm:col-span-1">{{ i + 1 }}</td>
            <td class="sm:col-span-3">
              <input type="text" class="form-input border-0" [(ngModel)]="item.voucherName" name="item.voucherName{{ i }}" />
            </td>
            <td class="sm:col-span-3">
              <input type="text" class="form-input border-0" [(ngModel)]="item.description" name="item.description{{ i }}" />
            </td>
            <td class="sm:col-span-2">
              <input type="text" class="form-input border-0" [(ngModel)]="item.price" name="item.price{{ i }}" />
            </td>
            <td class="sm:col-span-2">-</td>
            <td class="sm:col-span-1">
              <input type="text" class="form-input border-0" [(ngModel)]="item.invoiceNumber" name="item.invoiceNumber{ i }}" />
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let extraItem of extraRows; let j = index">
          <tr class="ng-star-inserted grid grid-cols-2 sm:grid-cols-12">
            <td class="sm:col-span-1">{{ dailySaleDocument.items.length + j + 1 }}</td>
            <td class="sm:col-span-3">
              <input type="text" class="form-input border-0" [(ngModel)]="extraItem.voucherName" name="extraItem.voucherName{{ j }}" [placeholder]="'daily_sale_file.enter_name' | translate" />
            </td>
            <td class="sm:col-span-3">
              <input type="text" class="form-input border-0" [(ngModel)]="extraItem.description" name="extraItem.description{{ j }}" [placeholder]="'daily_sale_file.enter_description' | translate" />
            </td>
            <td class="sm:col-span-2">
              <input type="text" class="form-input border-0" [(ngModel)]="extraItem.price" name="extraItem.price{{ j }}" [placeholder]="'daily_sale_file.enter_price' | translate" />
            </td>
            <td class="sm:col-span-2">-</td>
            <td class="sm:col-span-1">
              <input type="text" class="form-input border-0" [(ngModel)]="extraItem.invoiceNumber" name="extraItem.invoiceNumber{{ j }}" [placeholder]="'daily_sale_file.enter_invoiceNumber' | translate" />
            </td>
          </tr>
        </ng-container>

        <!-- Total red -->
        <tr class="ng-star-inserted grid grid-cols-2 sm:grid-cols-12">
          <td class="sm:col-span-1">{{ dailySaleDocument.items.length + extraRows.length + 1 }}</td>
          <td class="sm:col-span-3">{{ "daily_sale_file.payment_slip" | translate }}</td>
          <td class="sm:col-span-3">{{ "daily_sale_file.daily_sale_col" | translate }}</td>
          <td class="sm:col-span-2">-</td>
          <td class="sm:col-span-2">{{ totalPrice }} din</td>
          <td class="sm:col-span-1">-</td>
        </tr>
      </tbody>
    </table>

    <div class="grid-cols-1 grid-cols-3 items-start sm:grid">
      <table class="table-responsive mt-3 w-full border-2 border-gray-400">
        <tbody class="border-top: 2px">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
            <td class="sm:col-span-1">{{ "daily_sale_file.denomination" | translate }}</td>
            <td class="sm:col-span-1">{{ "daily_sale_file.command" | translate }}</td>
            <td class="sm:col-span-1">{{ "daily_sale_file.total" | translate }}</td>
          </tr>
          <ng-container *ngFor="let value of denomination; let i = index">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
              <td class="border border-gray-300 sm:col-span-1">{{ value }}</td>
              <td class="border border-gray-300 sm:col-span-1">
                <input type="text" class="form-input border-0" [(ngModel)]="dailySaleDocument.denomination[i]" [name]="'denomination' + i" (change)="calculate()" />
              </td>
              <td class="border border-gray-300 sm:col-span-1">
                {{ dailySaleDocument.denomination[i] * value || 0 }}
              </td>
            </tr>
          </ng-container>
          <tr class="ng-star-inserted bg-blue-100">
            <td colspan="2" class="border border-slate-400">{{ "daily_sale_file.all" | translate }}</td>
            <td class="border border-slate-400">{{ totalPrice }}</td>
          </tr>
          <tr class="ng-star-inserted bg-blue-100">
            <td colspan="2" class="border border-slate-400">{{ "daily_sale_file.total" | translate }} din</td>
            <td class="border border-slate-400">{{ totalPrice }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table-responsive border-b-2 border-l-2 border-r-2 border-gray-400 sm:ml-1">
        <tbody>
          <tr class="ng-star-inserted col-span-1 sm:col-span-3">
            <td class="border-b border-l border-r border-slate-400 sm:col-span-1">{{ "daily_sale_file.cash_register_turnover" | translate }}</td>
            <td class="border-b border-l border-r border-slate-400 text-center sm:col-span-1">{{ this.totalPrice }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.balance_due" | translate }}</td>
            <td class="border border-slate-400 text-center sm:col-span-1">0</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.total_cash" | translate }}</td>
            <td class="border border-slate-400 text-center sm:col-span-1">{{ this.totalPrice }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.total_check" | translate }}</td>
            <td class="border border-slate-400 text-center sm:col-span-1">{{ this.totalPrice }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.balance_from" | translate }} 03/09/2023</td>
            <td class="border border-slate-400 text-center sm:col-span-1">0</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="ml-5 mt-3 flex justify-end gap-5">
    <div class="border-black-500 flex w-1/3 border-2 p-3">
      {{ "daily_sale_file.verified" | translate }}:
      <div class="flex-1 text-slate-800"></div>
    </div>
    <div class="border-black-500 flex w-1/3 border-2 p-3">
      {{ "daily_sale_file.cashier" | translate }}:
      <div class="flex-1 text-slate-800"></div>
    </div>
  </div>
</div>
