import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { PasswordResetRequest } from "../../model/user";
import { AppState } from "src/app/store/index.selector";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/service/app.service";
import { toggleAnimation } from "src/app/shared/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/domain/user-profile/service/user.service";
import { Language, LanguageState } from "src/app/store/language/language.reducer";
import { PasswordCustomValidators } from "src/app/shared/validators/password.validator";

@Component({
  moduleId: module.id,
  templateUrl: "./reset-password.html",
  animations: [toggleAnimation],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token = "";
  userData: any;
  loading = false;
  passwordVisible = false;
  langData!: LanguageState;
  protected resetForm: FormGroup;
  confirmPasswordVisible = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public userService: UserService,
    public router: Router,
    private fb: FormBuilder,
    public state: Store<AppState>,
    private appSetting: AppService,
    public translate: TranslateService
  ) {
    const passwordValidators = [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]+$/)];
    this.resetForm = fb.group(
      {
        password: ["", passwordValidators],
        confirm_password: ["", passwordValidators],
      },
      { validators: PasswordCustomValidators.checkPasswords() }
    );
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams["token"];
    const validationSubscription = this.userService.validateResetToken(this.token).subscribe({
      next: (res) => {
        validationSubscription.unsubscribe();
      },
      error: (err) => {
        console.log(err);
        validationSubscription.unsubscribe();
      },
    });

    this.state.subscribe((d: AppState) => {
      this.langData = d.lang;
    });
  }

  submitForm() {
    if (this.resetForm.valid) {
      this.sendResetPasswordRequest();
    } else {
      this.resetForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendResetPasswordRequest() {
    if (this.resetForm.valid) {
      this.loading = true;
      const data: PasswordResetRequest = {
        newPassword: this.resetForm.value.password,
        token: this.token,
      };
      const resetSubscription = this.userService.resetPasswordRequest(data).subscribe({
        next: (res) => {
          this.loading = false;
          showMessage(PopupType.Success, this.translate.instant("reset_password.successfull_reset"));
          resetSubscription.unsubscribe();
          setTimeout(() => {
            this.router.navigate([""]);
          }, 3000);
        },
        error: (error) => {
          this.loading = false;
          resetSubscription.unsubscribe();
        },
      });
    }
  }

  changeLanguage(item: Language) {
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }

  toggleConfrimPasswordVisibility() {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
