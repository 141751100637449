<div class="space-y-8">
  <div class="panel p-0 py-5">
    <div>
      <div class="m-2 flex justify-end" *ngIf="hasOtherServiceData">
        <button type="button" class="btn btn-primary" (click)="clearOtherServiceData()">
          <icon-discard></icon-discard>
          {{ "customer_checkout.discard_sales" | translate }}
        </button>
      </div>
      <div class="relative z-[1]">
        <div class="absolute top-[30px] -z-[1] m-auto h-1 w-[25%] bg-primary transition-[width] ltr:left-0 rtl:right-0" [ngClass]="{ 'w-[25%]': activeTab === 1, 'w-[72%]': activeTab === 2 }"></div>
        <ul class="mb-5 grid grid-cols-2">
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
              (click)="proceedToNextStage(1)"
            >
              <icon-person></icon-person>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "ticket_checkout.passangers" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
              (click)="proceedToNextStage(2)"
            >
              <icon-home></icon-home>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "other_services_checkout.choose_service" | translate }}</span>
          </li>
        </ul>
      </div>
      <div>
        <div [ngStyle]="isVisibleTab(1)">
          <div class="mx-5">
            <h3 class="font-bold">{{ "other_services_checkout.customer" | translate }}</h3>
            <hr />
          </div>
          <app-customer-create #mainCustomer> </app-customer-create>
        </div>
        <div [ngStyle]="isVisibleTab(2)">
          <div class="mx-5 mb-5">
            <h3 class="font-bold">{{ "other_services_checkout.choose_service" | translate }}</h3>
            <hr />
          </div>
          <form [formGroup]="form" novalidate>
            <div [ngClass]="form.controls['service'].touched ? (form.controls['service'].errors ? 'has-error' : 'has-success') : ''">
              <div class="mx-5 mb-5 grid grid-cols-1 gap-5">
                <app-select-remote
                  id="customType"
                  [type]="PicklistType.OtherServices"
                  [selectControl]="form"
                  labelForId="otherService"
                  formControlName="service"
                  #remoteSelect
                  [searchable]="true"
                ></app-select-remote>
                <ng-container *ngIf="form.controls['service'].touched && !form.controls['service'].errors">
                  <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
                </ng-container>
                <ng-container *ngIf="form.controls['service'].touched && form.controls['service'].errors">
                  <p class="mt-1 text-danger">{{ "travel_create.please_provide_type" | translate }}</p>
                </ng-container>
              </div>
            </div>
          </form>
          <app-sale-create #sale></app-sale-create>
        </div>
      </div>
      <div class="flex justify-between px-5">
        <ng-container *ngIf="activeTab > 1; else placeholder">
          <button type="button" class="btn btn-primary" (click)="proceedToNextStage(1)">
            {{ "ticket_checkout.back" | translate }}
          </button>
        </ng-container>
        <ng-template #placeholder>
          <div class="invisible"></div>
        </ng-template>

        <button type="button" class="btn btn-primary" *ngIf="activeTab !== 2" (click)="proceedToNextStage()">
          {{ "ticket_checkout.next" | translate }}
        </button>

        <button type="button" class="btn btn-primary" *ngIf="activeTab === 2" [disabled]="isLoading" (click)="addService()">
          {{ "ticket_checkout.finish_sale" | translate }}
        </button>
      </div>
      <div class="p-5">
        <hr class="my-2" />
        <div>
          <label for="notes">{{ "ticket_checkout.remarks" | translate }}</label>
          <textarea id="notes" rows="3" class="w-100 form-textarea mt-2" [placeholder]="translate.instant('ticket_checkout.remarks')" [(ngModel)]="note"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
