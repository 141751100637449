<div class="fixed inset-0 z-50 flex items-center justify-center">
  <div class="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
    <div class="relative w-full max-w-md rounded-lg bg-white">
      <button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
        <icon-x class="h-5 w-5"></icon-x>
      </button>
      <h2 class="mb-4 text-center text-lg font-semibold">
        {{ editMode ? ("organizer.update" | translate) : ("organizer.add" | translate) }}
      </h2>
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-1">
          <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="name">{{ "organizer_data.name" | translate }}</label>
            <input id="name" type="text" placeholder="{{ 'organizer_data.name' | translate }}" class="form-input" formControlName="name" [appLimitCharacterInput]="60" #nameInput />
            <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors">{{ "organizer_data.please_provide_name" | translate }}</small>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
          <div [ngClass]="form.controls['firstName'].touched ? (form.controls['firstName'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="firstName">{{ "organizer_data.fullName" | translate }}</label>
            <input id="firstName" type="text" placeholder="{{ 'organizer_data.fullName' | translate }}" class="form-input" formControlName="firstName" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['firstName'].touched && form.controls['firstName'].errors">{{ "organizer_data.please_provide_first_name" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['lastName'].touched ? (form.controls['lastName'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="lastName">{{ "organizer_data.lastName" | translate }}</label>
            <input id="lastName" type="text" placeholder="{{ 'organizer_data.lastName' | translate }}" class="form-input" formControlName="lastName" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['lastName'].touched && form.controls['lastName'].errors">{{ "organizer_data.please_provide_last_name" | translate }}</small>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
          <div [ngClass]="form.controls['phone'].touched ? (form.controls['phone'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="phone">{{ "organizer_data.phone" | translate }}</label>
            <input id="phone" type="text" placeholder="{{ 'organizer_data.phone' | translate }}" class="form-input" formControlName="phone" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors">{{ "organizer_data.please_provide_phone" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['licence'].touched ? (form.controls['licence'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="licence">{{ "organizer_data.licence" | translate }}</label>
            <input id="licence" type="text" placeholder="{{ 'organizer_data.licence' | translate }}" class="form-input" formControlName="licence" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['licence'].touched && form.controls['licence'].errors">{{ "organizer_data.please_provide_licence" | translate }}</small>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
          <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="email">{{ "organizer_data.email" | translate }}</label>
            <input id="email" type="text" placeholder="{{ 'organizer_data.email' | translate }}" class="form-input" formControlName="email" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['email'].touched && form.controls['email'].errors">{{ "organizer_data.please_provide_email" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="address">{{ "organizer_data.address" | translate }}</label>
            <input id="address" type="text" placeholder="{{ 'organizer_data.address' | translate }}" class="form-input" formControlName="address" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['address'].touched && form.controls['address'].errors">{{ "organizer_data.please_provide_address" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['accountNumber'].touched ? (form.controls['accountNumber'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="accountNumber">{{ "organizer_data.accountNumber" | translate }}</label>
            <input id="accountNumber" type="text" placeholder="{{ 'organizer_data.accountNumber' | translate }}" class="form-input" formControlName="accountNumber" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['accountNumber'].touched && form.controls['accountNumber'].errors">{{ "organizer_data.please_provide_accountNumber" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['pib'].touched ? (form.controls['pib'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="pib">{{ "organizer_data.PIB" | translate }}</label>
            <input id="pib" type="text" placeholder="{{ 'organizer_data.PIB' | translate }}" class="form-input" formControlName="pib" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['pib'].touched && form.controls['pib'].errors">{{ "organizer_data.please_provide_PIB" | translate }}</small>
          </div>
          <div [ngClass]="form.controls['registrationNumber'].touched ? (form.controls['registrationNumber'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="registrationNumber">{{ "organizer_data.registrationNumber" | translate }}</label>
            <input id="registrationNumber" type="text" placeholder="{{ 'organizer_data.registrationNumber' | translate }}" class="form-input" formControlName="registrationNumber" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['registrationNumber'].touched && form.controls['registrationNumber'].errors">{{
              "organizer_data.please_provide_registrationNumber" | translate
            }}</small>
          </div>
        </div>
        <button *ngIf="!editMode" type="submit" class="btn btn-primary w-full" [disabled]="isLoading || form.invalid">
          <ng-container *ngIf="!isLoading; else loadingTemplate">{{ "organizer_data.save" | translate }} </ng-container>
          <ng-template #loadingTemplate>
            <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
          </ng-template>
        </button>
        <button *ngIf="editMode" type="submit" class="btn btn-primary w-full" [disabled]="isLoading || form.invalid">
          <ng-container *ngIf="!isLoading; else loadingTemplate">{{ "organizer_data.update" | translate }} </ng-container>
          <ng-template #loadingTemplate>
            <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
          </ng-template>
        </button>
      </form>
    </div>
  </div>
</div>
