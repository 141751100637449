import * as moment from "moment";
import { Subscription } from "rxjs";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { CheckoutService } from "../services/checkout.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { SimpleTicket } from "./../../domain/tickets/models/ticket";
import { ModalService } from "src/app/shared/services/modal.service";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { LocalStorageService } from "src/app/service/local-storage.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import { Customer, PassangerTypes } from "../../domain/customers/models/customer";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TicketCreateComponent } from "src/app/domain/tickets/ticket.create/ticket.create.component";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
@Component({
  templateUrl: "./ticket.checkout.component.html",
  animations: [toggleAnimation],
})
export class TicketCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("ticket") ticketComponent!: TicketCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("nextStepButton") nextStepButton!: ElementRef;

  public customer!: Customer;
  public ticket!: Ticket;
  public sale!: SaleBase;
  public id!: number;
  note!: string;
  public activeTab = 1;
  public type!: string;
  public mainCustomer!: Customer;
  public simpleTicket!: SimpleTicket;
  currentPlan!: SaleBase;
  private routeSubscribtion!: Subscription;
  public customerId!: number;
  public isEditingPassanger = false;
  passengers!: Customer[];
  isLoading = false;
  hasTicketData = false;
  entityDetails: string | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public checkoutService: CheckoutService,
    private modalService: ModalService,
    public validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.type = this.router.url.includes("flight") ? "flight" : "bus";
      if (this.router.url.includes("/edit")) {
        setTimeout(() => {
          this.fetchEntities();
        }, 100);
      }
    });
    const key = this.type === "flight" ? "flightTicketData" : "busTicketData";
    this.hasTicketData = !!this.localStorageService.loadDataFromLocalStorage<string>(key);
  }

  ngOnDestroy() {
    this.routeSubscribtion.unsubscribe();
  }

  ngAfterViewInit() {
    this.loadTicketFromLocalStorage();
    this.cdr.detectChanges();
  }

  saveTicketToLocalStorage() {
    const ticketData = {
      customer: this.customer,
      ticket: this.simpleTicket,
      passangers: this.passangerList.getPassangers(),
      note: this.note,
    };
    const key = this.type === "flight" ? "flightTicketData" : "busTicketData";
    this.localStorageService.updateKey(key, JSON.stringify(ticketData));
  }

  loadTicketFromLocalStorage() {
    const key = this.type === "flight" ? "flightTicketData" : "busTicketData";
    const savedData = this.localStorageService.loadDataFromLocalStorage<string>(key);
    if (savedData) {
      const { customer, ticket, passangers, note } = JSON.parse(savedData);
      this.customer = customer;
      this.simpleTicket = ticket;

      if (this.passangerList) {
        this.passangerList.setPassangers(passangers);
      }
      if (this.customerComponent) {
        this.customerComponent.initForm(this.customer);
      }
      if (this.ticketComponent) {
        this.ticketComponent.setTicket(this.simpleTicket);
      }
      this.note = note;
    }
  }

  fetchEntities() {
    this.checkoutService.fetchTicket(this.id).subscribe({
      next: (response) => {
        const { id, passangers, customer, plan, numberOfPassangers, oneWay, departureDate, arrivalDate, reservationDate, type, company, departure, destination } = response;

        this.sale = plan;
        this.saleComponent.initForm(this.sale);
        const reservationNumber = passangers.find((p) => p.id === customer.id)?.reservationNumber;
        const customerList = passangers
          .filter((p) => p.customer && p.id !== customer.id)
          .map((p) => ({ ...p.customer, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer, reservationNumber: reservationNumber }));

        this.passangerList.push(customerList);

        this.mainCustomer = {
          ...customer,
          reservationNumber,
          lifeStage: PassangerTypes.adults,
          guid: crypto.randomUUID(),
          imageUrl: "/assets/images/man.svg",
          mainCustomer: true,
          isMainCustomer: passangers.find((p) => p.id === customer.id)?.isMainCustomer,
        } as Customer;

        if (this.passangerList) {
          this.customerId = customer.id;
          this.passangerList.setMainCustomer(this.mainCustomer);
        }
        this.ticketComponent.setTicket({
          id,
          oneWay,
          departureDate,
          arrivalDate,
          reservationNumber,
          numberOfPassangers,
          reservationDate,
          type,
          company,
          departure,
          destination,
        });
      },
      error: (error) => {
        console.error("Error fetching ticket data:", error);
      },
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
    }
    if (this.activeTab === 2 && nextStep !== 1) {
      if (!this.ticketComponent.checkIfValid()) {
        showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
        return;
      }
    }
    if (this.activeTab === 3 && nextStep !== 2) {
      if (!this.saleComponent.checkIfValid()) {
        showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
        return;
      }
    }

    this.setFocusField(this.activeTab + 1);

    this.simpleTicket = this.ticketComponent.getTicket();
    this.activeTab = nextStep ?? this.activeTab + 1;

    if (!this.router.url.includes("/edit")) {
      this.saveTicketToLocalStorage();
    }
  }
  private focusNextStepButton() {
    setTimeout(() => {
      this.nextStepButton.nativeElement.focus();
    }, 0);
  }
  handleCustomerSubmit() {
    this.focusNextStepButton();
  }

  setFocusField(nextStep: number) {
    switch (nextStep) {
      case 2:
        this.ticketComponent.focusFirstField();
        break;
      case 3:
        this.saleComponent.focusFirstField();
        break;
      default:
        break;
    }
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  toggleButton(isEditing: boolean) {
    this.isEditingPassanger = isEditing;
  }

  submitTicket() {
    this.passengers = this.passangerList.getPassangers();
    this.sale = this.saleComponent.getSale();
    this.simpleTicket = this.ticketComponent.getTicket();
    this.isLoading = true;
    if (!this.saleComponent.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
      this.isLoading = false;
      return;
    }

    if (this.id) {
      const updateSubscription = this.checkoutService.updateTicket(this.id, this.createReservationObject()).subscribe({
        next: () => {
          this.isLoading = false;
          showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
          updateSubscription.unsubscribe();
          this.router.navigate(["dashboard/sales"]);
        },
        error: (error) => {
          this.isLoading = false;
          updateSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.error);
        },
      });
    } else {
      const createSubscription = this.checkoutService.createTicket(this.createReservationObject()).subscribe({
        next: (r) => {
          this.currentPlan = r.plan;
          this.entityDetails = this.translate.instant("paymentsEntities.ticket") + ": " + r.destination?.description + " - " + (r.departureDate ? moment(r.departureDate).format("DD.MM.YYYY") : "");
          this.sendInvoice();
          this.isLoading = false;
          showMessage(PopupType.Success, this.translate.instant("ticket_created_successfully"));
          createSubscription.unsubscribe();
          const key = this.type === "flight" ? "flightTicketData" : "busTicketData";
          this.localStorageService.removeSpecificItem(key);
        },
        error: (error) => {
          this.isLoading = false;
          createSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.error);
        },
      });
    }
  }

  createReservationObject(): any {
    const { oneWay, departureDate, arrivalDate, departure, destination, company, reservationDate } = this.simpleTicket;
    const { id, reservationNumber } = this.customer;
    const { bruto, neto, avans, dueDate, paymentType, paymentTypeId } = this.sale;
    const destinationId: number | undefined = destination ? destination.id : undefined;
    const departureId: number = departure.id;
    const companyId: number = company.id;
    const passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, reservationNumber: reservationNumber, lifeStage: e.lifeStage, mainCustomer: e.mainCustomer, isMainCustomer: e.isMainCustomer };
    });
    const isCustomerInList = passangers.some((p) => p.customerId === id);
    if (!isCustomerInList) {
      passangers.push({ customerId: id, reservationNumber: this.mainCustomer.reservationNumber, lifeStage: PassangerTypes.adults, mainCustomer: true, isMainCustomer: true });
    }
    const avansValue = this.sale.withAvans === 0 ? undefined : avans;
    const newReservation = {
      oneWay,
      departureDate: DateTimeFormatter.formatCustomOrISOToISO(departureDate),
      arrivalDate: oneWay ? null : arrivalDate ? DateTimeFormatter.formatCustomOrISOToISO(arrivalDate) : null,
      reservationNumber: this.simpleTicket.reservationNumber,
      reservationDate: DateTimeFormatter.formatDateToISO(reservationDate),
      type: this.type,
      customerId: id,
      plan: {
        bruto,
        neto,
        avans: avansValue,
        dueDate: dueDate ? moment.parseZone(dueDate, "DD.MM.YYYY hh:mm").format("YYYY-MM-DDTHH:mm:ss") : "",
        customerId: this.customer.id,
        notes: this.note,
        paymentType,
        paymentTypeId,
      },
      planId: this.sale.id,
      passangers,
      departureId,
      destinationId,
      companyId,
    };
    return newReservation;
  }
  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send.avans"),
      message: this.translate.instant("confirm.send_avans"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.currentPlan,
      navigateToRoute: `dashboard/sales`,
      isOnlyAvans: true,
      activeTab: undefined,
      paymentId: undefined,
      entityDetails: this.entityDetails,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
  clearTicketData(): void {
    const key = this.type === "flight" ? "flightTicketData" : "busTicketData";
    this.localStorageService.removeSpecificItem(key);
    this.router.navigate(["dashboard/sales"]);
  }
}
