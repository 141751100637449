import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.html",
})
export class StatisticsComponent {
  @Input() value!: number | undefined;
  @Input() name!: string;
  // Add your component logic here
  constructor(public translate: TranslateService) {}
}
