import { Component, OnInit } from "@angular/core";
import { DailySaleDocument } from "../../models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesService } from "../../services/sales.service";
import { PrintPreviewService } from "src/app/shared/services/print-preview.service";

@Component({
  selector: "app-daily-sale.document",
  templateUrl: "./daily-sale.document.component.html",
})
export class DailySaleDocumentComponent implements OnInit {
  constructor(private salesService: SalesService, private route: ActivatedRoute, private router: Router, public printPreviewService: PrintPreviewService) {}
  dailySaleDocument!: DailySaleDocument;
  cashRegisterId!: number;
  totalPrice = 0;
  isLoading = false;
  denomination = [5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      const idParam = params.get("id");
      this.cashRegisterId = idParam ? parseInt(idParam) : 0;
      this.getDailySaleDocument(this.cashRegisterId);
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  getDailySaleDocument(id: number) {
    const subscription = this.salesService.getDailySaleDocument(id).subscribe((response) => {
      this.dailySaleDocument = response;
      this.dailySaleDocument.items.forEach((item) => {
        this.totalPrice += item.price;
        subscription.unsubscribe();
      });
      this.totalPrice = parseFloat(this.totalPrice.toFixed(4));
      this.dailySaleDocument.denomination = this.distributeAmountByDenomination(this.totalPrice);
    });
  }
  distributeAmountByDenomination(amount: number): number[] {
    const result: number[] = [];
    this.denomination.forEach((denom) => {
      const count = Math.floor(amount / denom);
      result.push(count);
      amount -= count * denom;
    });
    return result;
  }
  editDocument() {
    this.router.navigate([`/dashboard/daily-sale/document/edit/${this.cashRegisterId}`]);
  }
  printDailySale() {
    this.isLoading = true;
    const subscription = this.salesService.generateDailySaleHtml(this.dailySaleDocument.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }
}
